import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slider,
  LinearProgress,
  Typography,
  Box,
} from '@mui/material';
import { api } from '../../state/api';
import { useDispatch } from 'react-redux';
import { handleExcelDownload } from 'utils/utils';
import { LazyQueryTrigger } from 'models/index.model';
import { GridColumnVisibilityModel } from '@mui/x-data-grid';

const MAX_PARALLEL_REQUESTS = 5;

interface DownloadPopupProps {
  open: boolean;
  onClose: () => void;
  columnVisibility: GridColumnVisibilityModel;
  dataFormatterfn: (allData: any, columnVisibility?: GridColumnVisibilityModel) => any;
  totalRecords: number;
  itemsPerPage: number;
  excelFileName: string;
  lazyQuery: LazyQueryTrigger;
  dataKey: string;
  filters: Record<string, any>;
}

const DownloadPopup: React.FC<DownloadPopupProps> = ({
  open,
  onClose,
  columnVisibility,
  dataFormatterfn,
  totalRecords,
  itemsPerPage,
  excelFileName,
  lazyQuery,
  dataKey,
  filters,
}) => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedPages, setSelectedPages] = useState(Math.ceil(totalRecords / itemsPerPage));
  const [downloadData, setDownloadData] = useState<any[]>([]);
  const [trigger] = lazyQuery;
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setSelectedPages(Math.ceil(totalRecords / itemsPerPage));
  }, [totalRecords, itemsPerPage]);

  // Calculate max pages and current selection in records
  const maxPages = useMemo(() => Math.ceil(totalRecords / itemsPerPage), [totalRecords, itemsPerPage]);

  const calculateChunks = useCallback(() => {
    // If we have fewer pages than max parallel requests, just use one page per chunk
    if (selectedPages <= MAX_PARALLEL_REQUESTS) {
      return Array.from({ length: selectedPages }, (_, index) => ({
        startPage: index + 1,
        endPage: index + 1,
      }));
    }

    // Otherwise, distribute pages evenly across chunks
    const pagesPerChunk = Math.ceil(selectedPages / MAX_PARALLEL_REQUESTS);
    const chunks = [];

    for (let i = 0; i < selectedPages; i += pagesPerChunk) {
      chunks.push({
        startPage: i + 1,
        endPage: Math.min(i + pagesPerChunk, selectedPages),
      });
    }

    return chunks;
  }, [selectedPages, maxPages]);

  const makeRequest = async (params: any) => {
    try {
      const result = await trigger(params);
      if ('error' in result) {
        throw result.error;
      }
      return result;
    } catch (error) {
      console.error('Request failed:', error);
      throw error;
    }
  };
  useEffect(() => {
    if (isDownloading && downloadData.length > 0) {
      try {
        const formattedData = dataFormatterfn(downloadData, columnVisibility);
        handleExcelDownload(formattedData, excelFileName);
        setDownloadData([]);
      } finally {
        timeoutRef.current = setTimeout(() => {
          setIsDownloading(false);
        }, 1000);
      }
    }
  }, [isDownloading, downloadData, dataFormatterfn, columnVisibility, excelFileName]);

  // Clear the timeout when the component unmounts
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleDownload = async () => {
    setIsDownloading(true);
    setProgress(0);
    setDownloadData([]);

    const chunks = calculateChunks();
    const totalRequests = chunks.reduce((acc, chunk) => acc + (chunk.endPage - chunk.startPage + 1), 0);
    let completedRequests = 0;
    const tempData: any[] = [];

    try {
      for (const chunk of chunks) {
        const requests =
          chunk.startPage <= chunk.endPage
            ? Array.from({ length: chunk.endPage - chunk.startPage + 1 }, (_, i) => chunk.startPage + i)
            : [];

        for (const page of requests) {
          try {
            let queryParams;
            if (Array.isArray(filters)) {
              queryParams = [filters[0], { ...filters[1], page: page.toString(), limit: itemsPerPage.toString() }];
            } else {
              queryParams = { ...filters, page: page.toString(), limit: itemsPerPage.toString() };
            }
            const result = await makeRequest(queryParams);

            if (result.data) {
              tempData.push(...(result.data[dataKey] || []));
            }
            completedRequests++;

            setProgress((completedRequests / totalRequests) * 100);
          } catch (error) {
            console.error(`Error downloading page ${page}:`, error);
          }
        }
      }

      setDownloadData(tempData);
    } catch (error) {
      console.error('Download failed:', error);
      setIsDownloading(false);
    }
  };

  const handleClose = () => {
    if (isDownloading) {
      dispatch(api.util.resetApiState());
    }
    setProgress(0);
    setIsDownloading(false);
    setDownloadData([]);
    onClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle>Download {excelFileName}</DialogTitle>
      <DialogContent>
        <Box className='space-y-4'>
          <Typography>Select number of pages to download ({itemsPerPage} records per page)</Typography>

          <Slider
            value={selectedPages}
            onChange={(_, value) => setSelectedPages(value as number)}
            min={1}
            max={maxPages}
            marks={[
              { value: 1, label: '1' },
              { value: maxPages, label: maxPages.toString() },
            ]}
            disabled={isDownloading}
          />

          <Typography>
            Selected: {selectedPages} of {maxPages} page(s)
          </Typography>

          {isDownloading && (
            <Box className='space-y-2'>
              <Typography>Downloading... {Math.round(progress)}%</Typography>
              <LinearProgress variant='determinate' value={progress} />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='error'>
          {isDownloading ? 'Cancel' : 'Close'}
        </Button>
        <Button onClick={handleDownload} disabled={isDownloading} variant='contained'>
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadPopup;
