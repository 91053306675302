import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuditLog, Booking, DeadlineType, Invoice, TeamType } from 'models/booking.model';
import { Hbl } from 'models/hbl.model';
import {
  Contact,
  Customer,
  IGetAllAdvancesResponse,
  IGetAllBookingLoadingsResponse,
  IGetAllBookingsResponse,
  IGetAllBookingsWithDeadlinesResponse,
  IGetAllEnquiriesResponse,
  IGetAllAccountingsResponse,
  IGetAllReceiptsAndPaymentsResponse,
  IGetMasterDataResponse,
  UserPreference,
  IGetUserPreferenceResponse,
} from 'models/index.model';
import { sec } from '../auth/accessToken';
import {
  MonthlyPortVolumes,
  MonthlyPortCombinationVolumes,
  MonthlyCarrierVolumes,
  MonthlyVolumesForKpi,
  CummulativeVolumesForKpi,
  MonthlyProfitsForKpi,
  CummulativeProfitsForKpi,
  OldestBookingYear,
  MonthlyCommodityVolumeAndProfit,
  MonthlyHaulerStats,
  VolumnMonthly,
  CustomerVolumeProfitByMonth,
  PortVolume,
  GrossProfitMonthly,
  CommodityVolumeProfit,
  MerchantHaulerStats,
  PortCombinationStats,
  ContainerTypeData,
  AdditionalChargesProfit,
} from 'models/analytics';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: async headers => {
      const access_token = await sec.getAccessTokenSilently()();
      if (access_token) {
        headers.set('Authorization', `Bearer ${access_token}`);
      }
      return headers;
    },
  }),
  reducerPath: 'adminApi',
  tagTypes: [
    'User',
    'Bookings',
    'Booking',
    'Enquiries',
    'Customers',
    'Vendors',
    'Ports',
    'Deadlines',
    'Loadings',
    'Sites',
    'CustomerDetails',
    'Documents',
    'Files',
    'Invoices',
    'Accounting',
    'ExchangeRates',
    'Profit',
    'Outstanding',
    'Audits',
    'UserPreferenceDetail',
    'HblDetails',
    'Contacts',
    'Oauth',
    'MonthlyVolumes',
    'TotalCarrierVolumes',
    'PortVolumes',
    'MonthlyGrossProfits',
    'CommmodityVolumesProfits',
    'TransportTypeVolumes',
    'MerchantHaulerStats',
    'PortCombinationVolumes',
    'ContainerTypeData',
    'MonthlyHaulerStats',
    'MonthlyCommodityVolumeAndProfit',
    'MonthlyPortVolumes',
    'MonthlyPortCombinationVolumes',
    'MonthlyCarrierVolumes',
    'MonthlyVolumesForKpi',
    'CummulativeVolumesForKpi',
    'MonthlyProfitsForKpi',
    'CummulativeProfitsForKpi',
    'ReceiptsAndPayments',
    'AdvancePayments',
    'BankAccounts',
    'AdditionalChargesProfit',
  ],
  endpoints: build => ({
    getUser: build.query<any, string>({
      query: id => `api/v1/users/${id}`,
      providesTags: ['User'],
    }),
    getBookings: build.query<
      IGetAllBookingsResponse,
      {
        hasExportPermission?: string;
        hasImportPermission?: string;
        dateRange?: [string, string];
        freightMode?: string;
        bookingStatus?: string;
        bookingTeam?: TeamType;
        consignor?: string;
        consignee?: string;
        carrier?: string;
        vesselName?: string;
        voyageNumber?: string;
        portOfLoading?: string;
        portOfDestination?: string;
        bookingEtdDateRangeAfter?: string;
        bookingEtaDateRangeBefore?: string;
        placeOfDelivery?: string;
        containerType?: string;
        salesInvoiceCreated?: string;
        purchaseInvoiceCreated?: string;
        bookingConfirmationSent?: string;
        allStepsCompleted?: string;
        profitsMatching?: string;
        blStatus?: string;
        outstandingAdditionalCharges?: string;
        pic?: string;
        page?: string;
        limit?: string;
        searchText?: string;
        selectedField?: string;
      }
    >({
      query: ({
        hasExportPermission,
        hasImportPermission,
        dateRange,
        freightMode,
        bookingStatus,
        bookingTeam,
        consignor,
        consignee,
        carrier,
        vesselName,
        voyageNumber,
        portOfLoading,
        portOfDestination,
        placeOfDelivery,
        bookingEtdDateRangeAfter,
        bookingEtaDateRangeBefore,
        containerType,
        salesInvoiceCreated,
        purchaseInvoiceCreated,
        bookingConfirmationSent,
        allStepsCompleted,
        profitsMatching,
        blStatus,
        outstandingAdditionalCharges,
        pic,
        page,
        limit,
        searchText,
        selectedField,
      }) => {
        const params: { [key: string]: string | undefined } = {
          hasExportPermission,
          hasImportPermission,
          dateRange: dateRange?.join(','),
          bookingStatus,
          freightMode,
          bookingTeam: bookingTeam !== 'All' ? bookingTeam : undefined,
          consignor,
          consignee,
          carrier,
          vesselName,
          voyageNumber,
          portOfLoading,
          portOfDestination,
          bookingEtdDateRangeAfter,
          bookingEtaDateRangeBefore,
          placeOfDelivery,
          containerType,
          salesInvoiceCreated,
          purchaseInvoiceCreated,
          bookingConfirmationSent,
          allStepsCompleted,
          profitsMatching,
          blStatus,
          outstandingAdditionalCharges,
          pic,
          page: page?.toString(),
          limit: limit?.toString(),
          searchText,
          selectedField,
        };

        const queryParams = Object.entries(params).reduce(
          (acc, [key, value]) => {
            if (value !== undefined) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, string>,
        );

        const searchParams = new URLSearchParams(queryParams);
        return `api/v1/bookings/?${searchParams.toString()}`;
      },
      providesTags: ['Bookings'],
    }),
    getBooking: build.query<Booking, string>({
      query: id => `api/v1/bookings/${id}`,
      providesTags: ['Booking'],
    }),
    getEnquiries: build.query<
      IGetAllEnquiriesResponse,
      {
        hasExportPermission?: string;
        hasImportPermission?: string;
        dateRange?: [string, string];
        freightMode?: string;
        customer: string;
        containerType: string;
        portOfLoading: string;
        portOfDestination: string;
        fpod: string;
        carrier: string;
        enquiryStatus: string;
        pic: string;
        bookingTeam: string;
        incoterm: string;
        page?: string;
        limit?: string;
        searchText?: string;
        selectedField?: string;
      }
    >({
      query: ({
        hasExportPermission,
        hasImportPermission,
        dateRange,
        freightMode,
        customer,
        containerType,
        portOfLoading,
        portOfDestination,
        fpod,
        carrier,
        enquiryStatus,
        pic,
        bookingTeam,
        incoterm,
        page,
        limit,
        searchText,
        selectedField,
      }) => {
        const params: { [key: string]: string | undefined } = {
          hasExportPermission,
          hasImportPermission,
          dateRange: dateRange?.join(','),
          freightMode,
          carrier,
          enquiryStatus,
          pic,
          customer,
          portOfLoading,
          portOfDestination,
          fpod,
          containerType,
          incoterm,
          bookingTeam: bookingTeam !== 'All' ? bookingTeam : undefined,
          page: page?.toString(),
          limit: limit?.toString(),
          searchText,
          selectedField,
        };

        const queryParams = Object.entries(params).reduce(
          (acc, [key, value]) => {
            if (value !== undefined) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, string>,
        );

        const searchParams = new URLSearchParams(queryParams);
        return `api/v1/enquiries/?${searchParams.toString()}`;
      },
      providesTags: ['Enquiries'],
    }),
    getHblDetails: build.query<Hbl, string>({
      query: id => `api/v1/hbl/${id}`,
      providesTags: ['HblDetails'],
    }),
    getUserPreferenceDetails: build.query<UserPreference, string>({
      query: email => `api/v1/userPreferences/${email}`,
      providesTags: ['UserPreferenceDetail'],
    }),
    getUserPreference: build.query<IGetUserPreferenceResponse, void>({
      query: () => `api/v1/userPreferences/`,
      providesTags: ['UserPreferenceDetail'],
    }),
    getCustomers: build.query<
      IGetMasterDataResponse,
      { page?: string; limit?: string; searchText?: string; selectedField?: string }
    >({
      query: ({ page, limit, searchText, selectedField }) => {
        const params: { [key: string]: string | undefined } = {
          page: page?.toString(),
          limit: limit?.toString(),
          searchText,
          selectedField,
        };

        const queryParams = Object.entries(params).reduce(
          (acc, [key, value]) => {
            if (value !== undefined) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, string>,
        );

        const searchParams = new URLSearchParams(queryParams);
        return `api/v1/customers/?${searchParams.toString()}`;
      },
      providesTags: ['Customers'],
    }),
    getContacts: build.query<
      IGetMasterDataResponse,
      { page?: string; limit?: string; searchText?: string; selectedField?: string }
    >({
      query: ({ page, limit, searchText, selectedField }) => {
        const params: { [key: string]: string | undefined } = {
          page: page?.toString(),
          limit: limit?.toString(),
          searchText,
          selectedField,
        };

        const queryParams = Object.entries(params).reduce(
          (acc, [key, value]) => {
            if (value !== undefined) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, string>,
        );

        const searchParams = new URLSearchParams(queryParams);
        return `api/v1/contacts/?${searchParams.toString()}`;
      },
      providesTags: ['Contacts'],
    }),
    getVendors: build.query<
      IGetMasterDataResponse,
      { page?: string; limit?: string; searchText?: string; selectedField?: string }
    >({
      query: ({ page, limit, searchText, selectedField }) => {
        const params: { [key: string]: string | undefined } = {
          page: page?.toString(),
          limit: limit?.toString(),
          searchText,
          selectedField,
        };

        const queryParams = Object.entries(params).reduce(
          (acc, [key, value]) => {
            if (value !== undefined) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, string>,
        );

        const searchParams = new URLSearchParams(queryParams);
        return `api/v1/vendors/?${searchParams.toString()}`;
      },
      providesTags: ['Vendors'],
    }),
    getPorts: build.query<
      IGetMasterDataResponse,
      { page?: string; limit?: string; searchText?: string; selectedField?: string }
    >({
      query: ({ page, limit, searchText, selectedField }) => {
        const params: { [key: string]: string | undefined } = {
          page: page?.toString(),
          limit: limit?.toString(),
          searchText,
          selectedField,
        };

        const queryParams = Object.entries(params).reduce(
          (acc, [key, value]) => {
            if (value !== undefined) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, string>,
        );

        const searchParams = new URLSearchParams(queryParams);
        return `api/v1/ports/?${searchParams.toString()}`;
      },
      providesTags: ['Ports'],
    }),
    getDeadlines: build.query<
      IGetAllBookingsWithDeadlinesResponse,
      [DeadlineType, [string, string], boolean, boolean, TeamType, string?, string?]
    >({
      query: ([deadlineType, dateRange, hasExportPermission, hasImportPermission, bookingTeam, page, limit]) =>
        `api/v1/bookings/deadlines/today/${deadlineType}/?dateRange=${dateRange}&hasExportPermission=${hasExportPermission}&hasImportPermission=${hasImportPermission}&bookingTeam=${bookingTeam}&page=${page}&limit=${limit}`,
      providesTags: ['Deadlines'],
    }),
    getLoadings: build.query<
      IGetAllBookingLoadingsResponse,
      [[string, string], boolean, boolean, TeamType, string?, string?]
    >({
      query: ([dateRange, hasExportPermission, hasImportPermission, bookingTeam, page, limit]) =>
        `api/v1/bookings/loadings/today/?dateRange=${dateRange}&hasExportPermission=${hasExportPermission}&hasImportPermission=${hasImportPermission}&bookingTeam=${bookingTeam}&page=${page}&limit=${limit}`,
      providesTags: ['Loadings'],
    }),
    getSites: build.query<
      IGetMasterDataResponse,
      { page?: string; limit?: string; searchText?: string; selectedField?: string }
    >({
      query: ({ page, limit, searchText, selectedField }) => {
        const params: { [key: string]: string | undefined } = {
          page: page?.toString(),
          limit: limit?.toString(),
          searchText,
          selectedField,
        };

        const queryParams = Object.entries(params).reduce(
          (acc, [key, value]) => {
            if (value !== undefined) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, string>,
        );

        const searchParams = new URLSearchParams(queryParams);
        return `api/v1/sites/?${searchParams.toString()}`;
      },
      providesTags: ['Sites'],
    }),
    getOauth: build.query<
      IGetMasterDataResponse,
      { page?: string; limit?: string; searchText?: string; selectedField?: string }
    >({
      query: ({ page, limit, searchText, selectedField }) => {
        const params: { [key: string]: string | undefined } = {
          page: page?.toString(),
          limit: limit?.toString(),
          searchText,
          selectedField,
        };

        const queryParams = Object.entries(params).reduce(
          (acc, [key, value]) => {
            if (value !== undefined) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, string>,
        );

        const searchParams = new URLSearchParams(queryParams);
        return `api/v1/oauth/?${searchParams.toString()}`;
      },
      providesTags: ['Oauth'],
    }),
    getCustomerDetails: build.query<Customer[], string>({
      query: name => `api/v1/customers/${name}`,
      providesTags: ['CustomerDetails'],
    }),
    getContactDetails: build.query<Contact, string>({
      query: name => `api/v1/contacts/${name}`,
      providesTags: ['Contacts'],
    }),
    getDocuments: build.query<any, any>({
      query: key => `api/v1/documents/?key=${key}`,
      providesTags: ['Documents'],
    }),
    getFiles: build.query<any, string | undefined>({
      query: bookingNumber => {
        return `api/v1/documents/${bookingNumber}`;
      },
      providesTags: ['Files'],
    }),
    getInvoices: build.query<
      Invoice[],
      [
        string,
        {
          approvalPending?: boolean;
          invoiceOutstanding?: boolean;
          underDispute?: boolean;
          dateRange?: [string, string];
          dateType?: string;
          bookingTeam?: TeamType;
        }?,
      ]
    >({
      query: ([invoiceType, args]) => {
        const params = new URLSearchParams();
        Object.entries(args ?? {}).forEach(([key, val]) => {
          params.append(key, val + '');
        });
        return `api/v1/${invoiceType}/?${params.toString()}`;
      },
      providesTags: ['Invoices'],
    }),
    getAccountings: build.query<
      IGetAllAccountingsResponse,
      [
        string,
        {
          parties: string;
          approvalPending?: boolean;
          invoiceOutstanding?: boolean;
          underDispute?: boolean;
          dateRange?: [string, string];
          dateType?: string;
          bookingTeam?: TeamType;
          page?: string;
          limit?: string;
          searchText?: string;
          selectedField?: string;
        }?,
      ]
    >({
      query: ([type, args]) => {
        const params = new URLSearchParams();
        Object.entries(args ?? {}).forEach(([key, val]) => {
          params.append(key, val + '');
        });
        return `api/v1/${type}/?${params.toString()}`;
      },
      providesTags: ['Accounting'],
    }),
    getExchangeRate: build.query<any, [string, string]>({
      query: ([base, target]) => `api/v1/exchangeRates/?base=${base}&target=${target}`,
      providesTags: ['ExchangeRates'],
    }),
    getCustomerOutstandingAmount: build.query<number, string | undefined>({
      query: customerId => `api/v1/customers/outstanding/${customerId}`,
      providesTags: ['Outstanding'],
    }),
    getAudits: build.query<AuditLog[], [string, string]>({
      query: ([resource, resourceId]) => `api/v1/audits/?resource=${resource}&resourceId=${resourceId}`,
      providesTags: ['Audits'],
    }),
    getBookingProfit: build.query<any, string>({
      query: bookingId => `api/v1/bookings/analytics/profit/${bookingId}`,
      providesTags: ['Profit'],
    }),
    getExpectedProfit: build.query<any, string>({
      query: bookingId => `api/v1/bookings/analytics/expectedProfit/${bookingId}`,
      providesTags: ['Profit'],
    }),
    getActualProfit: build.query<any, string>({
      query: bookingId => `api/v1/bookings/analytics/actualProfit/${bookingId}`,
      providesTags: ['Profit'],
    }),
    getMonthlyVolumes: build.query<VolumnMonthly[], [string, string]>({
      query: ([selectedYear, selectedMonth]) =>
        `api/v1/analytics/monthlyVolumes/?selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['MonthlyVolumes'],
    }),
    getCustomerVolumesProfitsByMonth: build.query<CustomerVolumeProfitByMonth[], [string, string, string, string]>({
      query: ([bookingType, profitType, selectedYear, selectedMonth]) =>
        `api/v1/analytics/customerVolumesProfits/?bookingType=${bookingType}&profitType=${profitType}&selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['MonthlyVolumes'],
    }),
    getTotalCarrierVolumes: build.query<any, [string, string]>({
      query: ([selectedYear, selectedMonth]) =>
        `api/v1/analytics/totalCarrierVolumes/?selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['TotalCarrierVolumes'],
    }),
    getPortVolumes: build.query<PortVolume[], [string, string]>({
      query: ([selectedYear, selectedMonth]) =>
        `api/v1/analytics/portVolumes/?selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['PortVolumes'],
    }),
    getMonthlyGrossProfits: build.query<GrossProfitMonthly[], [string, string, string]>({
      query: ([selectedYear, selectedMonth, profitType]) =>
        `api/v1/analytics/monthlyGrossProfits/?selectedYear=${selectedYear}&selectedMonth=${selectedMonth}&profitType=${profitType}`,
      providesTags: ['MonthlyGrossProfits'],
    }),
    getCommodityVolumesProfts: build.query<CommodityVolumeProfit[], [string, string, string, string]>({
      query: ([bookingType, profitType, selectedYear, selectedMonth]) =>
        `api/v1/analytics/commodityVolumesProfits/?bookingType=${bookingType}&profitType=${profitType}&selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['CommmodityVolumesProfits'],
    }),
    getTransportTypeVolumes: build.query<any, [string, string, string]>({
      query: ([bookingType, selectedYear, selectedMonth]) =>
        `api/v1/analytics/transportTypeVolumes/?bookingType=${bookingType}&selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['TransportTypeVolumes'],
    }),
    getMerchantHauleStats: build.query<MerchantHaulerStats[], [string, string, string]>({
      query: ([bookingType, selectedYear, selectedMonth]) =>
        `api/v1/analytics/merchantHaulersStats/?bookingType=${bookingType}&selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['MerchantHaulerStats'],
    }),
    getPortCombinationVolumes: build.query<PortCombinationStats[], [string, string, string]>({
      query: ([bookingType, selectedYear, selectedMonth]) =>
        `api/v1/analytics/commonPortCombinationVolumes/?bookingType=${bookingType}&selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['PortCombinationVolumes'],
    }),
    getContainerTypeData: build.query<ContainerTypeData[], [string, string, string]>({
      query: ([bookingType, selectedYear, selectedMonth]) =>
        `api/v1/analytics/containerTypeData/?bookingType=${bookingType}&selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['ContainerTypeData'],
    }),
    getMonthlyHaulerStats: build.query<MonthlyHaulerStats[], [string, string, string]>({
      query: ([bookingType, selectedYear, selectedMonth]) =>
        `api/v1/analytics/monthlyMerchantHaulersStats/?bookingType=${bookingType}&selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['MonthlyHaulerStats'],
    }),
    getMonthlyCommodityVolumeAndProfit: build.query<
      MonthlyCommodityVolumeAndProfit[],
      [string, string, string, string]
    >({
      query: ([bookingType, profitType, selectedYear, selectedMonth]) =>
        `api/v1/analytics/monthlyCommoodityVolumesProfits/?bookingType=${bookingType}&profitType=${profitType}&selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['MonthlyCommodityVolumeAndProfit'],
    }),
    getMonthlyPortVolumes: build.query<MonthlyPortVolumes[], [string, string]>({
      query: ([selectedYear, selectedMonth]) =>
        `api/v1/analytics/monthlyPortVolumes/?selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['MonthlyPortVolumes'],
    }),
    getMonthlyPortCombinationVolumes: build.query<MonthlyPortCombinationVolumes[], [string, string, string]>({
      query: ([bookingType, selectedYear, selectedMonth]) =>
        `api/v1/analytics/commonPortCombinationMonthlyVolumes/?bookingType=${bookingType}&selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['MonthlyPortCombinationVolumes'],
    }),
    getMonthlyCarrierVolumes: build.query<MonthlyCarrierVolumes[], [string, string]>({
      query: ([selectedYear, selectedMonth]) =>
        `api/v1/analytics/monthlyTotalCarrierVolumes/?selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['MonthlyCarrierVolumes'],
    }),
    getMonthlyVolumesForKpi: build.query<MonthlyVolumesForKpi, [string, string]>({
      query: ([selectedYear, selectedMonth]) =>
        `api/v1/analytics/monthlyVolumesForKpi/?selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['MonthlyVolumesForKpi'],
    }),
    getCummulativeVolumesForKpi: build.query<CummulativeVolumesForKpi, [string, string]>({
      query: ([selectedYear, selectedMonth]) =>
        `api/v1/analytics/cummulativeVolumesForKpi/?selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['CummulativeVolumesForKpi'],
    }),
    getMonthlyProfitsForKpi: build.query<MonthlyProfitsForKpi, [string, string, string]>({
      query: ([selectedYear, profitType, selectedMonth]) =>
        `api/v1/analytics/monthlyProfitsForKpi/?selectedYear=${selectedYear}&profitType=${profitType}&selectedMonth=${selectedMonth}`,
      providesTags: ['MonthlyProfitsForKpi'],
    }),
    getCummulativeProfitsForKpi: build.query<CummulativeProfitsForKpi, [string, string, string]>({
      query: ([selectedYear, profitType, selectedMonth]) =>
        `api/v1/analytics/cummulativeProfitsForKpi/?selectedYear=${selectedYear}&profitType=${profitType}&selectedMonth=${selectedMonth}`,
      providesTags: ['CummulativeProfitsForKpi'],
    }),
    getOldestBookingYear: build.query<OldestBookingYear, void>({
      query: () => `api/v1/analytics/getOldestBookingYear`,
    }),
    getAdditionalChargesProfitData: build.query<AdditionalChargesProfit, [string, string, string]>({
      query: ([selectedYear, bookingType, selectedMonth]) =>
        `api/v1/analytics/getAdditionalChargesProfitData/?bookingType=${bookingType}&selectedYear=${selectedYear}&selectedMonth=${selectedMonth}`,
      providesTags: ['AdditionalChargesProfit'],
    }),
    getReceiptsAndPayments: build.query<
      IGetAllReceiptsAndPaymentsResponse,
      [
        string,
        {
          parties: string;
          dateRange?: [string, string];
          bankAccounts: string;
          page: string;
          limit: string;
          searchText?: string;
          selectedField?: string;
        }?,
      ]
    >({
      query: ([type, args]) => {
        const params = new URLSearchParams();
        params.append('type', type);
        Object.entries(args ?? {}).forEach(([key, val]) => {
          params.append(key, val + '');
        });
        return `api/v1/receiptsAndPayments/?${params.toString()}`;
      },
      providesTags: ['ReceiptsAndPayments'],
    }),
    getAdvancePayments: build.query<
      IGetAllAdvancesResponse,
      {
        parties?: string;
        dateRange?: [string, string];
        bankAccounts?: string;
        settlementOutstanding?: boolean;
        page?: string;
        limit?: string;
        searchText?: string;
        selectedField?: string;
      }
    >({
      query: args => {
        const params = new URLSearchParams();
        Object.entries(args ?? {}).forEach(([key, val]) => {
          params.append(key, val + '');
        });
        return `api/v1/advancepayments/?${params.toString()}`;
      },
      providesTags: ['AdvancePayments'],
    }),
    getBankAccounts: build.query<
      IGetMasterDataResponse,
      { page?: string; limit?: string; searchText?: string; selectedField?: string }
    >({
      query: ({ page, limit, searchText, selectedField }) => {
        const params: { [key: string]: string | undefined } = {
          page: page?.toString(),
          limit: limit?.toString(),
          searchText,
          selectedField,
        };

        const queryParams = Object.entries(params).reduce(
          (acc, [key, value]) => {
            if (value !== undefined) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Record<string, string>,
        );

        const searchParams = new URLSearchParams(queryParams);
        return `api/v1/bankAccounts/?${searchParams.toString()}`;
      },
      providesTags: ['BankAccounts'],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUserPreferenceQuery,
  useGetUserPreferenceDetailsQuery,
  useGetBookingsQuery,
  useGetBookingQuery,
  useGetEnquiriesQuery,
  useGetCustomersQuery,
  useGetContactsQuery,
  useGetVendorsQuery,
  useGetPortsQuery,
  useGetDeadlinesQuery,
  useGetLoadingsQuery,
  useGetSitesQuery,
  useGetOauthQuery,
  useGetCustomerDetailsQuery,
  useGetContactDetailsQuery,
  useGetDocumentsQuery,
  useGetFilesQuery,
  useGetInvoicesQuery,
  useGetAccountingsQuery,
  useGetExchangeRateQuery,
  useGetBookingProfitQuery,
  useGetExpectedProfitQuery,
  useGetCustomerOutstandingAmountQuery,
  useGetAuditsQuery,
  useGetHblDetailsQuery,
  useGetReceiptsAndPaymentsQuery,
  useGetAdvancePaymentsQuery,
  useGetBankAccountsQuery,
  useGetMonthlyVolumesQuery,
  useGetCustomerVolumesProfitsByMonthQuery,
  useGetTotalCarrierVolumesQuery,
  useGetPortVolumesQuery,
  useGetMonthlyGrossProfitsQuery,
  useGetCommodityVolumesProftsQuery,
  useGetTransportTypeVolumesQuery,
  useGetMerchantHauleStatsQuery,
  useGetPortCombinationVolumesQuery,
  useGetContainerTypeDataQuery,
  useGetMonthlyHaulerStatsQuery,
  useGetMonthlyCommodityVolumeAndProfitQuery,
  useGetMonthlyPortVolumesQuery,
  useGetMonthlyPortCombinationVolumesQuery,
  useGetMonthlyCarrierVolumesQuery,
  useGetMonthlyVolumesForKpiQuery,
  useGetCummulativeVolumesForKpiQuery,
  useGetMonthlyProfitsForKpiQuery,
  useGetCummulativeProfitsForKpiQuery,
  useGetOldestBookingYearQuery,
  useLazyGetBookingsQuery,
  useLazyGetAccountingsQuery,
  useGetActualProfitQuery,
  useGetAdditionalChargesProfitDataQuery,
} = api;

export default api.endpoints;
