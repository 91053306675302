import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Tooltip,
  DialogTitle,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  IconButton,
} from '@mui/material';
import { GridColDef, GridAlignment } from '@mui/x-data-grid';
import { MonthlyPortVolumes } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { getAllMonthNames, getCurrentMonth, handleExcelDownload, truncateName } from 'utils/utils';
import { DownloadOutlined } from '@mui/icons-material';
import MonthlyReportCustomDataGrid from './MonthlyReportCustomDataGrid';

interface MonthlyPortVolumeGridProps extends BaseDialogueProps {
  data: MonthlyPortVolumes[];
  selectedYear: number;
  selectedMonth: number | null;
}

const MonthlyPortVolumeGrid: React.FC<MonthlyPortVolumeGridProps> = ({
  data,
  handleClose,
  open,
  selectedYear,
  selectedMonth,
}) => {
  const currentMonth = getCurrentMonth(selectedYear, selectedMonth);
  const allMonths = getAllMonthNames(currentMonth);
  const [volumeType, setVolumeType] = useState<string>('Import');
  const [transformedData, setTransformedData] = useState<
    { id: number; Port: string; [key: string]: string | number | boolean }[]
  >([]);
  const [excelFileName, setExcelFileName] = useState<string>('Port-Volume-Monthly-Report');

  useEffect(() => {
    const s_excelFileName = selectedMonth
      ? `Port-Volume-Monthly-${volumeType}-Report_${allMonths[selectedMonth - 1]}-${selectedYear.toString()}`
      : `Port-Volume-Monthly-${volumeType}-Report_${selectedYear.toString()}`;

    setExcelFileName(s_excelFileName);
  }, [volumeType, selectedMonth, selectedYear]);

  const handleVolumeTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVolumeType(event.target.value);
  };

  // Calculate totals
  const [totalExportVolumes, totalImportVolumes] = allMonths
    .map(month => {
      return data.reduce(
        ([exportTotal, importTotal], port) => {
          const exportMonthData = port.exportPortVolumes.find(m => m.month === month);
          const importMonthData = port.importPortVolumes.find(m => m.month === month);

          return [exportTotal + (exportMonthData?.volume || 0), importTotal + (importMonthData?.volume || 0)];
        },
        [0, 0],
      );
    })
    .reduce(
      ([exportVolumes, importVolumes], [exportTotal, importTotal]) => {
        exportVolumes.push(exportTotal);
        importVolumes.push(importTotal);
        return [exportVolumes, importVolumes];
      },
      [[], []] as [number[], number[]],
    );

  useEffect(() => {
    const s_transformedData: { id: number; Port: string; [key: string]: string | number | boolean }[] = data.map(
      (item, index) => {
        const volumeColumns: { id: number; Port: string; [key: string]: string | number | boolean } = {
          id: index,
          Port: item.portCode,
        };

        allMonths.forEach((month, idx) => {
          if (volumeType === 'Export') {
            volumeColumns[`${month} (Volume)`] = item.exportPortVolumes[idx].volume || 0;
          } else {
            volumeColumns[`${month} (Volume)`] = item.importPortVolumes[idx].volume || 0;
          }
        });

        volumeColumns[`Total ${volumeType} Volume`] =
          volumeType === 'Export' ? item.totalExportVolumes : item.totalImportVolumes;

        return volumeColumns;
      },
    );

    // Add YTD row
    const ytdRow: { id: number; Port: string; [key: string]: string | number | boolean } = {
      id: data.length,
      Port: 'YTD',
      isYTD: true,
    };

    allMonths.forEach((month, index) => {
      ytdRow[`${month} (Volume)`] = volumeType === 'Export' ? totalExportVolumes[index] : totalImportVolumes[index];
    });

    ytdRow[`Total ${volumeType} Volume`] =
      volumeType === 'Export'
        ? data.reduce((total, port) => total + port.totalExportVolumes, 0)
        : data.reduce((total, port) => total + port.totalImportVolumes, 0);

    setTransformedData([...s_transformedData, ytdRow]);
  }, [data, volumeType]);

  const columns: GridColDef[] = [
    {
      field: 'Port',
      headerName: 'Port',
      flex: 2,
      renderCell: params => (
        <Tooltip title={params.value}>
          <span>{truncateName(params.value as string)}</span>
        </Tooltip>
      ),
    },
    ...allMonths.map(month => ({
      field: `${month} (Volume)`,
      headerName: `${month} (Volume)`,
      flex: 1,
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
    })),
    {
      field: `Total ${volumeType} Volume`,
      headerName: `Total ${volumeType} Volume`,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth fullScreen maxWidth='xl'>
        <DialogTitle>
          <Box display='flex' alignItems='center' gap={1}>
            {volumeType} Monthly Port Volume Report
            <Tooltip title='Download as Excel' placement='top'>
              <IconButton
                onClick={() =>
                  handleExcelDownload(
                    transformedData.map(row => {
                      const { id, ...filteredRow } = row; // Remove 'id' field
                      return filteredRow;
                    }),
                    excelFileName,
                  )
                }
              >
                <DownloadOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <FormControl component='fieldset'>
              <Box display='flex' alignItems='center'>
                <FormLabel component='legend' sx={{ marginRight: 2 }}>
                  Volume Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-label='volume-type'
                  name='volume-type'
                  value={volumeType}
                  onChange={handleVolumeTypeChange}
                >
                  <FormControlLabel value='Import' control={<Radio />} label='Import' />
                  <FormControlLabel value='Export' control={<Radio />} label='Export' />
                </RadioGroup>
              </Box>
            </FormControl>
          </Box>
          <MonthlyReportCustomDataGrid rows={transformedData} columns={columns} />
          <GeneralDialogueActions noSubmission handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MonthlyPortVolumeGrid;
