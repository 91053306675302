import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  useTheme,
  IconButton,
  Divider,
  Tooltip,
  CircularProgress,
  Menu,
  MenuItem,
  ListItemText,
} from '@mui/material';
import DocumentViewer from '../DocumentViewer';
import BookingConfirmation from '../BookingConfirmation';
import UpdateTermsAndConditions from '../UpdateTermsAndConditions';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  InfoOutlined,
  FileDownloadOutlined,
  DeleteOutlined,
  SpeakerNotesOutlined,
  FormatListNumberedOutlined,
  AddCircleOutline,
  RefreshOutlined,
  SendOutlined,
} from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import UpdateRemarksDialogue from 'components/UpdateRemarksDialogue';
import { Booking, Charge, StepStatus, TransportSchedule } from 'models/booking.model';
import { sec } from 'auth/accessToken';
import BookingConfirmationViewer from 'components/BookingConfirmationViewer/BookingConfirmationViewer';
import TransportScheduleViewer from 'components/TransportScheduleViewer';
import TransportScheduleDocument from 'components/TransportScheduleDocument';
import { splitTransportScheduleByHauler, useUserPermissions, setDialogueState, handleSnackbarClose } from 'utils/utils';
import { useGetAuditsQuery, useGetHblDetailsQuery } from 'state/api';
import { exportSteps } from 'dataAssets/actionSteps';
import HblDocumentDialogue from 'components/HblDocumentDialogue';
import HblDocViewer from 'components/HblDocViewer/HblDocViewer';
import HblDocument from 'components/HblDocument/HblDocument';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { AlertColor } from '@mui/material/Alert';
import AlertSnackbar from 'components/AlertSnackbar';
import { BLType, CargoDetails, Hbl, HblSellRates, PaymentType } from 'models/hbl.model';
import { useForm } from 'react-hook-form';
import { apiCall } from 'utils/serverFunctions';

export interface DocumentsTableProps {
  files: any[];
  booking: Booking;
  bookingRefetch: Function;
  filesRefetch: Function;
  id: string;
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({ files, booking, bookingRefetch, filesRefetch, id }) => {
  const theme = useTheme();
  const userPermissions = useUserPermissions();
  const { data: hbl, refetch: hblRefetch } = useGetHblDetailsQuery(booking.generatedHblId as string, {
    skip: !booking.generatedHblId,
  });
  const { refetch: auditLogsRefetch } = useGetAuditsQuery(['Booking', booking._id]);
  const [state, setState] = useState({
    openBookingConfirmation: false,
    openTransportSchedule: false,
    selectedHaulerTransportSchedule: [] as TransportSchedule[],
    openHblDocumentForm: false,
    openHblDocumentViewer: false,
    snackbarOpen: false,
    snackbarMessage: '',
    snackbarSeverity: 'success' as AlertColor,
    hblDocDetails: undefined as Hbl | undefined,
    menuAnchorBC: null as null | HTMLElement,
    menuAnchorHBL: null as null | HTMLElement,
    documentViewerIndex: -1,
    openDocument: false,
    openRemarks: false,
    termsAndConditionsOpen: false,
  });

  const updateState = (key: keyof typeof state, value: any) => {
    setState(prevState => ({ ...prevState, [key]: value }));
  };

  const handleTermsAndConditionsClickOpen = () => {
    updateState('termsAndConditionsOpen', true);
  };
  const handleTermsAndConditionsClose = () => {
    updateState('termsAndConditionsOpen', false);

    bookingRefetch();
  };

  const handleClickOpenRemarks = () => {
    updateState('openRemarks', true);
  };

  const handleCloseRemarks = () => {
    updateState('openRemarks', false);
    bookingRefetch();
  };

  const handleClickOpenDocument = () => {
    updateState('openDocument', true);
  };

  const handleCloseDocument = () => {
    updateState('openDocument', false);
    updateState('documentViewerIndex', -1);
  };

  const handleMenuBCClose = () => {
    updateState('menuAnchorBC', null);
  };

  const handleMenuHBLClose = () => {
    updateState('menuAnchorHBL', null);
  };

  const [actionToConfirm, setActionToConfirm] = useState<(() => void) | null>(null);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');

  const handleActionConfirmation = async () => {
    if (actionToConfirm) {
      actionToConfirm();
      setActionToConfirm(null);
    }
  };

  const createActionHandler = (actionFn: () => void, title: string, content: string) => {
    setActionToConfirm(() => actionFn);
    setDialogTitle(title);
    setDialogContent(content);
  };

  const haulerGroups = splitTransportScheduleByHauler(booking.transportSchedule);
  const defaultSellRates: HblSellRates[] = [];
  const defaultCargoDetails: CargoDetails[] = [];

  booking.sellRates?.map((sellRate: Charge) => {
    defaultSellRates.push({
      _id: sellRate._id,
      chargeName: sellRate.chargeName,
      rate: sellRate.rate,
      base: sellRate.base,
      exchangeRate: '',
      currency: sellRate.currency,
      paymentType: PaymentType.Prepaid,
    });
  });

  booking.transportSchedule?.map((schedule: TransportSchedule) => {
    defaultCargoDetails.push({
      marks: schedule.containerNumber,
      numbers: schedule.sealNumber,
      numberOfPackages: 1,
      kindOfPackages: booking.containerType,
      descriptionOfGoodsAndPackages: '',
      grossWeightCargo: schedule.weight,
      tareWeight: 0,
      volume: 0,
    });
  });

  const defaultHblDetails = {
    blNumber: booking.bookingNumber,
    blType: BLType.Original,
    shipper: booking.consignor,
    consignee: booking.consignee,
    notifyParty: booking.notifyParty,
    preCarriageBy: '',
    oceanVessel: booking.vesselVoyage,
    portOfLoading: booking.portOfLoading,
    portOfDischarge: booking.portOfDestination,
    placeOfDelivery: booking.placeOfDelivery,
    deliveryContactDetails: booking.agent,
    cargoDetails: defaultCargoDetails,
    sellRates: defaultSellRates,
    additionalDescription: '',
    placeOfReceipt: undefined,
    additionalClause: '',
    numberOfOriginalBls: '',
    payableAt: 'United Kingdom',
    placeAndDateOfIssue: { place: 'United Kingdom', date: '' },
    hblApproval: false,
  };

  const useFormReference = useForm<Hbl>({
    defaultValues: state.hblDocDetails,
  });

  useEffect(() => {
    const getHblDetails = async () => {
      if (booking.generatedHblId) {
        updateState('hblDocDetails', hbl);
      } else {
        updateState('hblDocDetails', defaultHblDetails);
      }
    };
    getHblDetails();
  }, [hbl]);

  useEffect(() => {
    useFormReference.reset(state.hblDocDetails);
  }, [useFormReference, state.hblDocDetails]);

  const deleteDocument = async (documentPath: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/v1/documents/?key=${documentPath}&bookingId=${booking._id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      filesRefetch();
      auditLogsRefetch();
    } catch (error) {
      console.log(error);
    }
  };

  const isCarrierBookingCompleted = booking.stepsCompleted.some(
    step => step.dbRef === exportSteps[0].dbRef && step.status === StepStatus.Completed,
  );

  const deleteHbl = async () => {
    const accessToken = await sec.getAccessTokenSilently()();

    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/hbl/${hbl?._id}`, {
        data: { bookingId: booking._id },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      updateState('hblDocDetails', defaultHblDetails);
      bookingRefetch();
      updateState('snackbarMessage', 'HBL Details Deleted Successfully !');
      updateState('snackbarSeverity', 'success');
      updateState('snackbarOpen', true);
    } catch (err) {
      console.log(err);
      updateState('snackbarMessage', 'Failed While Deleting HBL Details !');
      updateState('snackbarSeverity', 'error');
      updateState('snackbarOpen', true);
    }
  };

  const sendBookingConfirmation = async () => {
    try {
      apiCall(`/api/v1/bookings/${booking._id}`, {
        method: 'PATCH',
        data: {
          bookingConfirmationSent: true,
        },
      });
      bookingRefetch();
    } catch (err) {
      console.log(err);
      updateState('snackbarMessage', 'Failed While Sending Booking Confirmation !');
      updateState('snackbarSeverity', 'error');
      updateState('snackbarOpen', true);
    }
  };
  return (
    <Box>
      <Box mb={'1rem'}>
        <Table
          sx={{
            '& .MuiTableCell-root': {
              padding: '8px',
              borderBottom: 'none',
              color: theme.palette.secondary[100],
            },
            '& .MuiTableHead-root .MuiTableCell-root': {
              fontWeight: '600',
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Booking Confirmation</TableCell>
              <TableCell>
                <Box display={'grid'} gridTemplateColumns={'repeat(5, 1fr)'} gap={'1rem'}>
                  <Box>
                    {isCarrierBookingCompleted && !!booking.carrierBookingNumber && booking.quotationApproval && (
                      <>
                        <Tooltip title='Download Booking Confirmation'>
                          <IconButton
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              updateState('menuAnchorBC', event.currentTarget);
                            }}
                          >
                            <FileDownloadOutlined sx={{ color: 'white' }} />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          anchorEl={state.menuAnchorBC}
                          open={Boolean(state.menuAnchorBC)}
                          onClose={handleMenuBCClose}
                        >
                          <MenuItem onClick={handleMenuBCClose}>
                            <PDFDownloadLink
                              document={<BookingConfirmation booking={booking} showQuotation={true} />}
                              fileName={`${booking.bookingNumber}.pdf`}
                            >
                              {({ loading }) =>
                                loading ? (
                                  <CircularProgress size={'1rem'} />
                                ) : (
                                  <ListItemText primary='Including Quotation' />
                                )
                              }
                            </PDFDownloadLink>
                          </MenuItem>
                          <MenuItem onClick={handleMenuBCClose}>
                            <PDFDownloadLink
                              document={<BookingConfirmation booking={booking} showQuotation={false} />}
                              fileName={`${booking.bookingNumber}_WR.pdf`}
                            >
                              {({ loading }) =>
                                loading ? (
                                  <CircularProgress size={'1rem'} />
                                ) : (
                                  <ListItemText primary='Excluding Quotation' />
                                )
                              }
                            </PDFDownloadLink>
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </Box>
                  <Box>
                    <Tooltip
                      title={`${!isCarrierBookingCompleted || !booking.carrierBookingNumber || !booking.quotationApproval ? 'Carrier Booking Incomplete or Carrier Booking Number missing' : 'View Booking Confirmation'}`}
                    >
                      <span>
                        <IconButton
                          onClick={() => updateState('openBookingConfirmation', true)}
                          disabled={
                            !isCarrierBookingCompleted || !booking.carrierBookingNumber || !booking.quotationApproval
                          }
                        >
                          <InfoOutlined sx={{ color: 'white' }} />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <BookingConfirmationViewer
                      handleClose={() => updateState('openBookingConfirmation', false)}
                      open={state.openBookingConfirmation}
                      booking={booking}
                    />
                  </Box>
                  <Box>
                    <Tooltip title='Edit T&Cs'>
                      <IconButton onClick={handleTermsAndConditionsClickOpen}>
                        <FormatListNumberedOutlined sx={{ color: 'white' }} />
                      </IconButton>
                    </Tooltip>
                    <UpdateTermsAndConditions
                      handleClose={handleTermsAndConditionsClose}
                      open={state.termsAndConditionsOpen}
                      id={booking._id}
                      termsAndConditions={booking.bookingTermsAndConditions}
                      type={'booking'}
                    />
                  </Box>
                  <Box>
                    <Tooltip title='Edit Remarks'>
                      <IconButton onClick={handleClickOpenRemarks}>
                        <SpeakerNotesOutlined sx={{ color: 'white' }} />
                      </IconButton>
                    </Tooltip>
                    <UpdateRemarksDialogue
                      handleClose={handleCloseRemarks}
                      open={state.openRemarks}
                      booking={booking}
                    />
                  </Box>
                  <Box>
                    {isCarrierBookingCompleted && !!booking.carrierBookingNumber && booking.quotationApproval && (
                      <Tooltip
                        title={`${booking.bookingConfirmationSent ? 'Booking Confirmation Sent' : 'Send Booking Confirmation'}`}
                      >
                        <span>
                          <IconButton
                            onClick={() => {
                              createActionHandler(
                                sendBookingConfirmation,
                                'Send Booking Confirmation',
                                'Are you sure you want to send the booking confirmation?',
                              );
                            }}
                            disabled={booking.bookingConfirmationSent}
                          >
                            <SendOutlined sx={{ color: 'white' }} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>HBL Document</TableCell>
              <TableCell>
                <Box display={'grid'} gridTemplateColumns={'repeat(5, 1fr)'} gap={'1rem'}>
                  <Box>
                    {booking.generatedHblId && (
                      <>
                        {hbl?.hblApproval ? (
                          <Tooltip title='Download HBL Document'>
                            <span>
                              <IconButton
                                onClick={(event: React.MouseEvent<HTMLElement>) => {
                                  updateState('menuAnchorHBL', event.currentTarget);
                                }}
                              >
                                <FileDownloadOutlined sx={{ color: 'white' }} />
                              </IconButton>
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip title='Refresh HBL'>
                            <span>
                              <IconButton
                                onClick={(event: React.MouseEvent<HTMLElement>) => {
                                  hblRefetch();
                                }}
                              >
                                <RefreshOutlined sx={{ color: 'white' }} />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                        <Menu
                          anchorEl={state.menuAnchorHBL}
                          open={Boolean(state.menuAnchorHBL)}
                          onClose={handleMenuHBLClose}
                        >
                          <MenuItem onClick={handleMenuHBLClose}>
                            <PDFDownloadLink
                              document={<HblDocument hbl={hbl} copyVersion={false} />}
                              fileName={`HBL-Document_${hbl?.blNumber}_Original`}
                            >
                              {({ loading }) =>
                                loading ? (
                                  <CircularProgress size={'1rem'} />
                                ) : (
                                  <ListItemText primary='Original Version' />
                                )
                              }
                            </PDFDownloadLink>
                          </MenuItem>
                          <MenuItem onClick={handleMenuHBLClose}>
                            <PDFDownloadLink
                              document={<HblDocument hbl={hbl} copyVersion={true} />}
                              fileName={`HBL-Document_${hbl?.blNumber}_Copy`}
                            >
                              {({ loading }) =>
                                loading ? (
                                  <CircularProgress size={'1rem'} />
                                ) : (
                                  <ListItemText primary='Copy Non-negotiable Version' />
                                )
                              }
                            </PDFDownloadLink>
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </Box>
                  {booking.generatedHblId && (
                    <Box>
                      <Tooltip title='View HBL Document'>
                        <span>
                          <IconButton
                            onClick={() => updateState('openHblDocumentViewer', true)}
                            disabled={!booking.generatedHblId}
                          >
                            <InfoOutlined sx={{ color: 'white' }} />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <HblDocViewer
                        handleClose={() => updateState('openHblDocumentViewer', false)}
                        open={state.openHblDocumentViewer}
                        hbl={hbl}
                        copyVersion={false}
                      />
                    </Box>
                  )}
                  {booking.generatedHblId ? (
                    <Box>
                      <Tooltip title='Edit HBL Document Details'>
                        <span>
                          <IconButton
                            onClick={() => updateState('openHblDocumentForm', true)}
                            disabled={!booking.generatedHblId || hbl?.hblApproval}
                          >
                            <EditOutlinedIcon sx={{ color: 'white' }} />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <HblDocumentDialogue
                        handleClose={() => updateState('openHblDocumentForm', false)}
                        open={state.openHblDocumentForm}
                        bookingId={booking._id}
                        hblDetails={state.hblDocDetails}
                        defaultSellRates={defaultSellRates}
                        hblDataRefetch={hblRefetch}
                        bookingDataRefetch={bookingRefetch}
                        useFormReference={useFormReference}
                      />
                    </Box>
                  ) : (
                    <Box>
                      <Tooltip title='Add HBL Document Details'>
                        <span>
                          <IconButton
                            onClick={() => updateState('openHblDocumentForm', true)}
                            disabled={!!booking.generatedHblId}
                          >
                            <AddCircleOutline sx={{ color: 'white' }} />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <HblDocumentDialogue
                        handleClose={() => updateState('openHblDocumentForm', false)}
                        open={state.openHblDocumentForm}
                        bookingId={booking._id}
                        hblDetails={state.hblDocDetails}
                        defaultSellRates={defaultSellRates}
                        hblDataRefetch={hblRefetch}
                        bookingDataRefetch={bookingRefetch}
                        useFormReference={useFormReference}
                      />
                    </Box>
                  )}
                  {booking.generatedHblId && userPermissions.includes('delete:hbl') && (
                    <Box>
                      <Tooltip title='Delete HBL Document Details'>
                        <span>
                          <IconButton
                            onClick={() => {
                              createActionHandler(deleteHbl, 'Delete HBL', 'Are you sure you want to delete this HBL?');
                            }}
                            disabled={!booking.generatedHblId}
                          >
                            <DeleteOutlined sx={{ color: 'white' }} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </TableCell>
            </TableRow>
            {Object.keys(haulerGroups).map((hauler, index) => (
              <TableRow key={`hauler-${hauler}-${index}`}>
                <TableCell>{`${hauler} - TO`}</TableCell>
                <TableCell>
                  <Box display={'grid'} gridTemplateColumns={'repeat(5, 1fr)'} gap={'1rem'}>
                    <Box>
                      {isCarrierBookingCompleted && !!booking.carrierBookingNumber && (
                        <PDFDownloadLink
                          key={`transport-schedule-${hauler}-${index}`}
                          document={
                            <TransportScheduleDocument booking={booking} transportSchedule={haulerGroups[hauler]} />
                          }
                          fileName={`Transport-Schedule_${booking.bookingNumber}`}
                        >
                          {({ loading }) =>
                            loading ? (
                              <CircularProgress size={'1rem'} />
                            ) : (
                              <Tooltip title='Download Transport Schedule'>
                                <IconButton>
                                  <FileDownloadOutlined sx={{ color: 'white' }} />
                                </IconButton>
                              </Tooltip>
                            )
                          }
                        </PDFDownloadLink>
                      )}
                    </Box>
                    <Box>
                      <Tooltip title='View Transport Schedule'>
                        <span>
                          <IconButton
                            onClick={() => {
                              updateState('openTransportSchedule', true);
                              updateState('selectedHaulerTransportSchedule', haulerGroups[hauler]);
                            }}
                            disabled={!isCarrierBookingCompleted || !booking.carrierBookingNumber}
                          >
                            <InfoOutlined sx={{ color: 'white' }} />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <TransportScheduleViewer
                        handleClose={() => updateState('openTransportSchedule', false)}
                        open={state.openTransportSchedule}
                        booking={booking}
                        transportSchedule={state.selectedHaulerTransportSchedule}
                      />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </Box>
      <Divider />
      <Box mt={'1rem'}>
        <Table
          sx={{
            '& .MuiTableCell-root': {
              padding: '8px',
              borderBottom: 'none',
              color: theme.palette.secondary[100],
            },
            '& .MuiTableHead-root .MuiTableCell-root': {
              fontWeight: '600',
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Document</TableCell>
              <TableCell>Document Type</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files !== undefined &&
              files.map((file, index) => (
                <TableRow key={`file-${file.Key}-${index}`}>
                  <TableCell>{file.Key.split('/')[2]}</TableCell>
                  <TableCell>{file.Key.split('/')[1]}</TableCell>
                  <TableCell>
                    <Box display={'flex'} justifyContent={'space-around'}>
                      <Tooltip title='View'>
                        <IconButton
                          onClick={() => {
                            handleClickOpenDocument();
                            updateState('documentViewerIndex', index);
                          }}
                        >
                          <InfoOutlined sx={{ color: 'white' }} />
                        </IconButton>
                      </Tooltip>
                      {userPermissions.includes('delete:document') && (
                        <Tooltip title='Delete'>
                          <IconButton onClick={() => deleteDocument(file.Key)}>
                            <DeleteOutlined sx={{ color: 'white' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {state?.documentViewerIndex > -1 && state.openDocument && (
                        <DocumentViewer
                          handleClose={handleCloseDocument}
                          open={state.openDocument}
                          documentPath={files[state.documentViewerIndex].Key}
                        />
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      <ConfirmationDialog
        open={!!actionToConfirm}
        handleClose={() => setActionToConfirm(null)}
        onConfirm={handleActionConfirmation}
        title={dialogTitle}
        content={dialogContent}
      />
      <AlertSnackbar
        open={state.snackbarOpen}
        handleClose={(_: any, reason: string) => {
          if (reason === 'clickaway') {
            return;
          }
          updateState('snackbarOpen', false);
        }}
        severity={state.snackbarSeverity}
        message={state.snackbarMessage}
      />
    </Box>
  );
};

export default DocumentsTable;
