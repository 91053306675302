import React from 'react';
import { Dialog, DialogContent, Box, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { GridColDef, GridAlignment } from '@mui/x-data-grid';
import { MonthlyPortCombinationVolumes } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { getAllMonthNames, getCurrentMonth, handleExcelDownload, truncateName } from 'utils/utils';
import { DownloadOutlined } from '@mui/icons-material';
import MonthlyReportCustomDataGrid from './MonthlyReportCustomDataGrid';

interface MonthlyPortCombinationVolumesTableProps extends BaseDialogueProps {
  data: MonthlyPortCombinationVolumes[];
  bookingType: string;
  selectedYear: number;
  selectedMonth: number | null;
}

const MonthlyPortCombinationVolumesTable: React.FC<MonthlyPortCombinationVolumesTableProps> = ({
  data,
  bookingType,
  handleClose,
  open,
  selectedYear,
  selectedMonth,
}) => {
  const currentMonth = getCurrentMonth(selectedYear, selectedMonth);
  const allMonths = getAllMonthNames(currentMonth);
  const excelFileName = selectedMonth
    ? `Port-Combination-Volumes-Monthly-${bookingType}-Report_${allMonths[selectedMonth - 1]}-${selectedYear.toString()}`
    : `Port-Combination-Volumes-Monthly-${bookingType}-Report_${selectedYear.toString()}`;

  // Generate columns for the DataGrid
  const columns: GridColDef[] = [
    {
      field: 'tradeRoute',
      headerName: 'Trade Route',
      flex: 2,
      renderCell: params => (
        <Tooltip title={params.value} placement='top'>
          <span>{truncateName(params.value as string)}</span>
        </Tooltip>
      ),
    },
    ...allMonths.map(month => ({
      field: `${month}Volume`,
      headerName: `${month} (Volume)`,
      flex: 1,
      type: 'number',
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
    })),
    {
      field: 'totalVolume',
      headerName: 'Total Volume',
      flex: 1,
      type: 'number',
      align: 'center',
      headerAlign: 'center',
    },
  ];

  // Transform data for DataGrid and Excel export
  const transformDataForGrid = () => {
    type RowData = {
      id: number | string;
      tradeRoute: string;
      totalVolume: number;
      [key: string]: number | string;
    };

    const rows: RowData[] = data.map((item, index) => {
      const monthlyVolumes = allMonths.reduce((acc, month) => {
        const monthData = item.monthlyPortCombinations.find(m => m.month === month);
        return {
          ...acc,
          [`${month}Volume`]: monthData?.volume || 0,
        };
      }, {});

      return {
        id: index,
        tradeRoute: item.combination,
        ...monthlyVolumes,
        totalVolume: item.totalVolume,
      };
    });

    // Add YTD row
    const ytdRow = {
      id: 'ytd',
      tradeRoute: 'YTD',
      ...allMonths.reduce(
        (acc, month) => ({
          ...acc,
          [`${month}Volume`]: data.reduce((total, portCombination) => {
            const monthData = portCombination.monthlyPortCombinations.find(m => m.month === month);
            return total + (monthData?.volume || 0);
          }, 0),
        }),
        {},
      ),
      totalVolume: data.reduce((total, portCombination) => total + portCombination.totalVolume, 0),
      isYTD: true,
    };

    return [...rows, ytdRow];
  };

  // Transform data for Excel export
  const getExcelData = () => {
    const gridData = transformDataForGrid();
    return gridData.map((row: { [key: string]: any }) => ({
      'Trade Route': row.tradeRoute,
      ...allMonths.reduce(
        (acc, month) => ({
          ...acc,
          [`${month} (Volume)`]: row[`${month}Volume`],
        }),
        {},
      ),
      'Total Volume': row.totalVolume,
    }));
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth fullScreen maxWidth='xl'>
        <DialogTitle>
          <Box display='flex' alignItems='center' gap={1}>
            {bookingType} Monthly Port Combination Volume Report
            <Tooltip title='Download as Excel' placement='top'>
              <IconButton onClick={() => handleExcelDownload(getExcelData(), excelFileName)}>
                <DownloadOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent>
          <MonthlyReportCustomDataGrid rows={transformDataForGrid()} columns={columns} />
          <GeneralDialogueActions noSubmission handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MonthlyPortCombinationVolumesTable;
