import dayjs from 'dayjs';
import {
  Booking,
  TeamType,
  TransportSchedule,
  InvoiceCharge,
  Invoice,
  Charge,
  TransportSite,
  BLStatus,
  CargoValue,
} from 'models/booking.model';
import {
  Site,
  Customer,
  Port,
  Vendor,
  IAdvPaymentsResponse,
  IBankAccounts,
  IKeyValuePair,
  SpecialCaseMapping,
} from 'models/index.model';
import { exportBookingTeams, importBookingTeams } from 'dataAssets/constants';
import { DEFAULT_BOOKING } from 'dataAssets/constants';
import { useSelector } from 'react-redux';
import { sec } from 'auth/accessToken';
import axios from 'axios';
import { useEffect, useMemo, useRef } from 'react';
import { AlertColor } from '@mui/material/Alert';
import { Hbl } from 'models/hbl.model';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { GridColumnVisibilityModel } from '@mui/x-data-grid';

const EMPTY_VALUE_LABEL = { value: '', label: '' };

export interface SnackbarState {
  open: boolean;
  color: AlertColor;
  message: string;
}

export const defaultSnackbarState: SnackbarState = {
  open: false,
  color: 'success',
  message: '',
};

export function getValueAndLabelFromPort(port?: Port) {
  return port
    ? {
        value: `${port._id}`,
        label: `${port.portName}, ${port.country}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromBooking(booking?: Booking) {
  return booking
    ? {
        value: `${booking._id}`,
        label: `${booking.bookingNumber}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromVendor(vendor?: Vendor) {
  return vendor
    ? {
        value: `${vendor._id}`,
        label: `${vendor.name}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromSite(site?: Site) {
  return site
    ? {
        value: site._id,
        label: `${site.siteName}, ${site.address}, ${site.city}, ${site.postcode}, ${site.country}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromCustomer(customer?: Customer) {
  return customer
    ? {
        value: `${customer._id}`,
        label: `${customer.name}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromInvoices(invoice?: Invoice) {
  return invoice
    ? {
        value: `${invoice._id}`,
        label: `${invoice.approvedInvoiceNumber}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromAdvPayments(advPayment?: IAdvPaymentsResponse) {
  return advPayment
    ? {
        value: `${advPayment._id}`,
        label: `${advPayment.advPaymentNumber}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function getValueAndLabelFromBankAccounts(account?: IBankAccounts) {
  return account
    ? {
        value: `${account._id}`,
        label: `${account.bank} - ${account.currency} - ${account.accountNumber}`,
      }
    : EMPTY_VALUE_LABEL;
}

export function handleSnackbarClose(reason: string, setStateFunc: React.Dispatch<React.SetStateAction<boolean>>) {
  if (reason === 'clickaway') {
    return;
  }

  setStateFunc(false);
}

export function getLabelForBookingDetail(value: any, bookingDetail: keyof Booking) {
  switch (bookingDetail) {
    case 'consignor':
    case 'consignee':
    case 'carrier':
      return value?.name;
    case 'agent':
      return value?.name;
    case 'portOfLoading':
    case 'portOfDestination':
    case 'placeOfDelivery':
      return `${value?.portName ?? 'N/A'}, ${value?.country ?? 'N/A'}`;
    case 'cargoValue':
      return `${value?.currency} ${value?.value ?? 'N/A'}`;
    case 'vesselVoyage':
      return `${value?.vesselName}, ${value?.voyageNumber} [${value?.vesselFlag}]`;
    case 'transhipmentDetails':
      return value
        ?.map(
          (step: any) => `${step.transhipmentPortOfLoading?.portName} - ${step.transhipmentPortOfDischarge?.portName}`,
        )
        .join(' -> ');
    case 'etaPOL':
    case 'etd':
    case 'eta':
      return dayjs(value).format('DD/MM/YYYY');
    case 'customDetails':
      return value?.map((detail: any) => `${detail.key}`).join(', ');
    default:
      return value;
  }
}

export const useUserPermissions = () => {
  return useSelector((state: any) => state.global.permissions);
};

export const formatDeadline = (deadline: string) => {
  return deadline !== 'N/A' ? dayjs(deadline).format('DD/MM/YYYY') : 'TBA';
};

export const setBookingTeams = (hasExportPermission: boolean, hasImportPermission: boolean) => {
  if (hasExportPermission && hasImportPermission) {
    return Array.from(new Set(exportBookingTeams.concat(importBookingTeams))) as TeamType[];
  } else if (hasExportPermission) {
    return exportBookingTeams;
  } else if (hasImportPermission) {
    return importBookingTeams;
  }
};

export const containerNumbersList = (transportSchedule: TransportSchedule[] | undefined) => {
  return transportSchedule
    ? transportSchedule.map((schedule: TransportSchedule) => schedule.containerNumber).join('/ ')
    : '';
};

export const totalInvoiceValue = (charges: InvoiceCharge[], numberOfContainer: number) => {
  const total = charges?.reduce(
    (acc, charge) => acc + charge.rate * charge.exchangeRate * (charge.base === 'CN' ? numberOfContainer : 1),
    0,
  );
  return total?.toFixed(2);
};

export const setDefaultBookingValues = (hasExportPermission: boolean, hasImportPermission: boolean): Booking => {
  return {
    ...DEFAULT_BOOKING,
    bookingType: hasExportPermission ? 'Export' : hasImportPermission ? 'Import' : 'Export',
    bookingTeam: hasExportPermission ? TeamType.Rockers : hasImportPermission ? TeamType.Falcons : TeamType.Rockers,
  };
};

export const checkMissingKeys = (booking: Booking) => {
  const missingKeys: string[] = [];

  ['consignor', 'consignee', 'carrierBookingNumber'].forEach(key => {
    if (!booking[key as keyof Booking]) {
      missingKeys.push(key);
    }
  });

  if (!booking.transportSchedule?.length) {
    missingKeys.push('Transport Schedule');
  }

  // Check if any schedule within transportSchedule is missing containerNumber
  const isContainerMissing = booking.transportSchedule?.some(schedule => !schedule.containerNumber);
  if (isContainerMissing) {
    missingKeys.push('Container Numbers');
  }

  return missingKeys;
};

export const checkHblMissingKeys = (hblFormData: Hbl) => {
  const missingKeys: string[] = [];

  const fieldChecks = [
    { key: 'shipper', label: 'shipper' },
    { key: 'consignee', label: 'consignee' },
    { key: 'notifyParty', label: 'notifyParty' },
    { key: 'deliveryContactDetails', label: 'deliveryContactDetails' },
    { key: 'placeOfReceipt', label: 'placeOfReceipt' },
    { key: 'portOfLoading', label: 'portOfLoading' },
    { key: 'portOfDischarge', label: 'portOfDischarge' },
    { key: 'placeOfDelivery', label: 'placeOfDelivery' },
    { key: 'numberOfOriginalBls', label: 'numberOfOriginalBls' },
    { key: 'payableAt', label: 'payableAt' },
    { key: 'blType', label: 'blType' },
    { key: 'blNumber', label: 'blNumber' },
    { key: 'placeAndDateOfIssue.place', label: 'Place Of Issue' },
    { key: 'placeAndDateOfIssue.date', label: 'Date Of Issue' },
    { key: 'oceanVessel.vesselFlag', label: 'Ocean Vessel Flag' },
    { key: 'oceanVessel.vesselName', label: 'Ocean Vessel Name' },
    { key: 'oceanVessel.voyageNumber', label: 'Ocean Vessel Number' },
  ];

  fieldChecks.forEach(({ key, label }) => {
    const value = key.split('.').reduce((obj, segment) => (obj as any)?.[segment], hblFormData);
    if (!value) {
      missingKeys.push(label);
    }
  });

  if (!hblFormData.cargoDetails?.length) {
    missingKeys.push('Cargo Details');
  } else {
    const cargoChecks = [
      { key: 'marks', label: 'Cargo Marks' },
      { key: 'numbers', label: 'Cargo Numbers' },
      { key: 'numberOfPackages', label: 'Cargo Number Of Packages' },
      { key: 'kindOfPackages', label: 'Cargo Kind Of Packages' },
      { key: 'descriptionOfGoodsAndPackages', label: 'Cargo Description Of Goods And Packages' },
      { key: 'grossWeightCargo', label: 'Cargo Gross Weight' },
    ];

    cargoChecks.forEach(({ key, label }) => {
      const isMissing = hblFormData.cargoDetails?.some(detail => !detail[key as keyof typeof detail]);
      if (isMissing) {
        missingKeys.push(label);
      }
    });
  }

  return missingKeys;
};

export const splitTransportScheduleByHauler = (schedule?: TransportSchedule[]): Record<string, TransportSchedule[]> => {
  const haulerGroups: Record<string, TransportSchedule[]> = {};

  schedule?.forEach(item => {
    const hauler = item.hauler;

    if (!haulerGroups[hauler]) {
      haulerGroups[hauler] = [];
    }

    haulerGroups[hauler].push(item);
  });

  return haulerGroups;
};

export const uniqueNameRefinement = (items: any, itemToUpdate: any, key: string) => (name: any) => {
  const lowercaseName = name.trim().toLowerCase().replace(/\s+/g, ' ');
  return !items?.find(
    (item: any) =>
      item[key]?.trim().toLowerCase().replace(/\s+/g, ' ') === lowercaseName && item._id !== itemToUpdate?._id,
  );
};

export const setDialogueState = (
  open: boolean,
  setStateFunc: React.Dispatch<React.SetStateAction<boolean>>,
  refetchFunc?: () => void,
) => {
  setStateFunc(open);
  if (refetchFunc) {
    refetchFunc();
  }
};

export const extractDeadlineTime = (deadline: string | undefined) => {
  return deadline ? dayjs(deadline).format('HH:mm') : 'N/A';
};

export const get = (obj: Record<string, any>, path: string, defaultValue: unknown = undefined) => {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

export const generateToken = async (id: string, code?: string) => {
  const details: Record<string, string> = {
    state: id,
  };
  if (typeof code !== 'undefined') {
    details.code = code;
  }
  const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/token/`;
  const accessToken = await sec.getAccessTokenSilently()();
  return axios.post(apiUrl, new URLSearchParams(details), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<typeof callback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const extractInvoiceType = (invoiceNumber: string) => {
  const parts = invoiceNumber.split('/');
  return parts[1];
};

export const extractInvoiceShortForm = (invoiceType: string) => {
  switch (invoiceType) {
    case 'salesInvoices':
      return 'SI';
    case 'purchaseInvoices':
      return 'PI';
    case 'creditNotes':
      return 'CN';
    case 'debitNotes':
      return 'DN';
    default:
      return '';
  }
};

export const getCurrentMonth = (selectedYear: number, selectedMonth: number | null) => {
  let currentMonth = dayjs().month();

  if (selectedYear.toString() === dayjs().year().toString()) {
    if (selectedMonth === dayjs().month() + 1 || selectedMonth === null) {
      currentMonth = dayjs().month();
    } else if (selectedMonth) {
      currentMonth = selectedMonth - 1;
    }
  } else {
    if (!selectedMonth) {
      currentMonth = 11; // Set the month to December (11) when the selected year is not the current year to show data for the whole year
    } else if (selectedMonth) {
      currentMonth = selectedMonth - 1;
    }
  }

  return currentMonth;
};

export const getAllMonthNames = (currentMonth: number) => {
  const allMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].slice(
    0,
    currentMonth + 1,
  );

  return allMonths;
};

export const truncateName = (name: string) => {
  const MAX_NAME_LENGTH = 15;
  return name.length > MAX_NAME_LENGTH ? `${name.slice(0, MAX_NAME_LENGTH)}...` : name;
};

export function formatpercentageChange(percentageChange: string) {
  return parseFloat(Number(percentageChange).toFixed(2));
}

export const getMonthNamesFromYear = (year: number) => {
  const months = useMemo(() => {
    const currentDate = dayjs();
    const isCurrentYear = year === currentDate.year();
    const monthNames = Array.from({ length: 12 }, (_, index) => ({
      value: index + 1,
      label: dayjs().month(index).format('MMMM'),
    }));

    return isCurrentYear ? monthNames.slice(0, currentDate.month() + 1) : monthNames;
  }, [year]);

  return months;
};

export const handleExcelDownload = (data: any[], fileName: string) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(file, `${fileName}.xlsx`);
};

// Helper functions for specific transformations
const processNameField = (obj: IKeyValuePair): string | null => {
  return obj.name || obj.portName || obj.siteName || obj.vesselName || null;
};

// Helper function to process quotationApproval field
function processQuotationApproval(value: boolean | unknown): string | unknown {
  if (typeof value === 'boolean') {
    return value ? 'Approved' : 'Pending';
  }
  return value;
}

// Helper function to process bookingConfirmation field
function processBCSent(value: boolean | unknown): string | unknown {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  return value;
}

// Helper function to process sites field
function processSites(value: TransportSite[] | null | undefined): string {
  if (value) {
    return value.map(transportSite => transportSite.site?.city ?? 'N/A').join(', ');
  }
  return 'N/A';
}

// Helper function to process dates field
function processDates(value: string): string {
  return dayjs(value).format('DD/MM/YYYY');
}

// Helper function to process cargoValue field
function processCargoValue(value: CargoValue | null | undefined): string {
  return `${value?.currency}  ${value?.value}`;
}

// Helper function to process haulers field
function processHaulers(value: TransportSchedule[]): string {
  const uniqueHaulers = value.reduce((acc, transportSchedule) => {
    const hauler = transportSchedule.hauler ?? 'N/A';
    if (!acc.includes(hauler)) {
      acc.push(hauler);
    }
    return acc;
  }, [] as string[]);

  return uniqueHaulers.join(', ');
}

// Helper function to process followUpDate field
function processFollowUpDate(value: BLStatus[] | undefined): string {
  if (value?.slice(-1)?.pop()?.followUpDate) {
    const followUpDate = value?.slice(-1)?.pop()?.followUpDate;
    return followUpDate ? processDates(followUpDate) : 'N/A';
  }
  return 'N/A';
}

// Helper function to process followUp field
function processFollowUp(value: BLStatus[] | undefined): string {
  if ((value?.length ?? 0) > 0) {
    return value?.slice(-1)?.pop()?.followUp ?? 'N/A';
  }
  return 'N/A';
}

// Helper function to process blStatusDate field
function processblStatusDate(value: BLStatus[] | undefined): string {
  if (value?.slice(-1).pop()?.statusDate) {
    return processDates(value?.slice(-1)?.pop()?.statusDate ?? 'N/A');
  }
  return 'N/A';
}

// Helper function to process blStatus field
function processblStatus(value: BLStatus[] | undefined): string {
  if ((value?.length ?? 0) > 0) {
    return value?.slice(-1)?.pop()?.status ?? 'N/A';
  }
  return 'N/A';
}

function processProfitFields(value: number | undefined): string {
  return value ? `£ ${value}` : 'N/A';
}

// Filter data based on column visibility
const filterByColumnVisibility = (
  data: (string | IKeyValuePair)[],
  columnVisibility: GridColumnVisibilityModel,
): any[] => {
  return data.map(row => {
    const filteredRow: IKeyValuePair = {};

    // Always include bookingNumber first if it exists and is visible
    if (typeof row !== 'string' && row.bookingNumber && columnVisibility.bookingNumber !== false) {
      filteredRow.bookingNumber = row.bookingNumber;
    }

    // Include other visible fields
    Object.keys(row as IKeyValuePair).forEach(key => {
      if (key !== 'bookingNumber' && columnVisibility[key] === true) {
        filteredRow[key] = (row as IKeyValuePair)[key];
      }
    });

    return filteredRow;
  });
};

// Main transformation function
function transformBookingData(obj: Booking): IKeyValuePair | string {
  // Handle base cases
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  // Handle arrays
  if (Array.isArray(obj)) {
    return obj.map(transformBookingData);
  }

  // Check for name fields first
  const nameValue = processNameField(obj);
  if (nameValue) {
    return nameValue;
  }

  // Create a new object with bookingNumber first
  const result: IKeyValuePair = {};

  // Add bookingNumber first if it exists
  if ('bookingNumber' in obj) {
    result.bookingNumber = obj.bookingNumber;
  }

  // Process all other fields
  for (const [key, value] of Object.entries(obj)) {
    if (key === 'bookingNumber') continue; // Skip as we've already handled it

    if (key === 'quotationApproval') {
      result[key] = processQuotationApproval(value);
    } else if (key === 'bookingConfirmationSent') {
      result[key] = processBCSent(value);
    } else if (key === 'transportSites') {
      result['sites'] = processSites(value as TransportSite[] | null | undefined);
    } else if (
      key === 'dateCreated' ||
      key === 'actualEtd' ||
      key === 'eta' ||
      key === 'actualEtaPOD' ||
      key === 'etd' ||
      key === 'etaPOL' ||
      key === 'actualEtaPOL'
    ) {
      if (typeof value === 'string') {
        result[key] = processDates(value);
      } else {
        result[key] = 'Invalid Date';
      }
    } else if (key === 'cargoValue') {
      if (value === null || value === undefined || typeof value === 'object') {
        result[key] = processCargoValue(value as CargoValue | null | undefined);
      }
    } else if (key === 'transportSchedule') {
      if (Array.isArray(value)) {
        result['haulers'] = processHaulers(value as TransportSchedule[]);
      }
    } else if (key === 'blStatusHistory') {
      result['blStatus'] = processblStatus(value as BLStatus[] | undefined);
      result['blStatusDate'] = processblStatusDate(value as BLStatus[] | undefined);
      result['followUp'] = processFollowUp(value as BLStatus[] | undefined);
      result['followUpDate'] = processFollowUpDate(value as BLStatus[] | undefined);
    } else if (key === 'expectedProfit' || key === 'actualProfit') {
      result[key] = processProfitFields(value as number | undefined);
    } else {
      result[key] = transformBookingData(value);
    }
  }

  return result;
}

// Function to format field names for excel download
function formatFieldNames(filteredBookings: IKeyValuePair[]): Record<string, string | number | null>[] {
  // Special case mappings
  const specialCases: SpecialCaseMapping = {
    etd: 'ETD',
    actualEtd: 'Actual ETD',
    eta: 'ETA',
    actualEtaPOD: 'Actual ETA (POD)',
    etaPOL: 'ETA POL',
    actualEtaPOL: 'Actual ETA (POL)',
    HSCode: 'HS Code',
    blStatus: 'BL Status',
    blStatusDate: 'BL Status Date',
    hblNumber: 'HBL',
    blRemarks: 'BL Remarks',
    pic: 'PIC',
    mblNumber: 'MBL',
  };

  return filteredBookings.map(obj => {
    const formattedObj: Record<string, string | number | null> = {};

    for (const [key, value] of Object.entries(obj)) {
      let newKey: string;

      // Check if it's a special case
      if (key in specialCases) {
        newKey = specialCases[key];
      } else {
        // Handle regular camel case conversion
        newKey = key
          // Insert space before capital letters
          .replace(/([A-Z])/g, ' $1')
          // Handle the first character
          .replace(/^./, str => str.toUpperCase())
          // Trim any leading space and ensure first letter of each word is capital
          .trim()
          .replace(/\s+/g, ' ')
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      }

      formattedObj[newKey] = value as string | number | null;
    }

    return formattedObj;
  });
}

// Hook to transform and filter data based on column visibility
export const formatBookings = (rawData: Booking[], columnVisibility?: GridColumnVisibilityModel) => {
  // First transform the data
  const transformedData = rawData.map(item => transformBookingData(item));

  // Then filter based on column visibility
  const filteredData = filterByColumnVisibility(transformedData, columnVisibility || {});
  const filteredDataWithFormattedFieldNames = formatFieldNames(filteredData);

  return filteredDataWithFormattedFieldNames;
};

// Function to format invoices data for excel download
export const formatInvoices = (invoiceData: Invoice[]) => {
  const formattedData: any[] = [];

  invoiceData.forEach((invoice: Invoice) => {
    const invoiceRow = {
      'Invoice Number': invoice.approvedInvoiceNumber,
      'Invoice Date':
        extractInvoiceType(invoice.performaInvoiceNumber) === 'PI'
          ? dayjs(invoice.vendorInvoiceDate).format('DD/MM/YY')
          : invoice.approvalDate
            ? dayjs(invoice.approvalDate).format('DD/MM/YY')
            : 'TBD',
      'Invoice Due Date': ['DN', 'CN'].some(invoiceType => invoice.performaInvoiceNumber.includes(invoiceType))
        ? 'N/A'
        : invoice.vendorInvoiceDate
          ? dayjs(invoice.vendorInvoiceDate)
              .add(invoice.vendorSnapshot?.creditPeriod ?? 0, 'day')
              .format('DD/MM/YY')
          : invoice.approvalDate
            ? dayjs(invoice.approvalDate)
                .add(invoice.customerSnapshot?.creditPeriod ?? invoice.vendorSnapshot?.creditPeriod ?? 0, 'day')
                .format('DD/MM/YY')
            : 'TBD',
      'Invoice Party': invoice.customerSnapshot?.name ?? invoice.vendorSnapshot?.name,
      'Invoice Party Address': invoice.customerSnapshot?.address ?? invoice.vendorSnapshot?.address,
      'Voucher Type': 'Sales',
      'Cost Category': invoice.bookingNumber.includes('EX') ? 'Export' : 'Import',
      'Job No': invoice.bookingNumber,
      'Charge Name': '',
      'Charge Amount': '',
      'Invoice Currency': invoice.invoiceCurrency,
      'Carrier Booking Number': invoice.bookingSnapshot.carrierBookingNumber,
      'Vessel / Voyage': `${invoice.bookingSnapshot.vesselName} ${invoice.bookingSnapshot.voyageNumber}`,
      'Port of Loading': invoice.bookingSnapshot.portOfLoading,
      'Port of Destination': invoice.bookingSnapshot.portOfDestination,
      'Number of Containers': `${invoice.numberOfContainers} X ${invoice.bookingSnapshot.containerType}`,
      'Cargo Description': invoice.bookingSnapshot.cargoDescription,
    };

    invoice.charges.forEach((charge, index) => {
      if (index === 0) {
        formattedData.push({
          ...invoiceRow,
          'Charge Name':
            typeof charge.chargeName === 'string'
              ? charge.chargeName
              : charge.chargeName?.chargeName || 'Unknown Charge Name',
          'Charge Amount': charge.rate * charge.exchangeRate * (charge.base === 'CN' ? invoice.numberOfContainers : 1),
        });
      } else {
        formattedData.push({
          'Invoice Number': invoice.approvedInvoiceNumber,
          'Invoice Date': '',
          'Invoice Due Date': '',
          'Invoice Party': '',
          'Invoice Party Address': '',
          'Voucher Type': '',
          'Cost Category': invoice.bookingNumber.includes('EX') ? 'Export' : 'Import',
          'Job No': invoice.bookingNumber,
          'Charge Name':
            typeof charge.chargeName === 'string'
              ? charge.chargeName
              : charge.chargeName?.chargeName || 'Unknown Charge Name',
          'Charge Amount': charge.rate * charge.exchangeRate * (charge.base === 'CN' ? invoice.numberOfContainers : 1),
          'Invoice Currency': invoice.invoiceCurrency,
        });
      }
    });
  });

  return formattedData;
};
