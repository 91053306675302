import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Box, Tooltip, DialogTitle, IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { MonthlyCarrierVolumes } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { getAllMonthNames, getCurrentMonth, handleExcelDownload, truncateName } from 'utils/utils';
import { DownloadOutlined } from '@mui/icons-material';
import MonthlyReportCustomDataGrid from './MonthlyReportCustomDataGrid';

interface MonthlyCarrierVolumeGridProps extends BaseDialogueProps {
  data: MonthlyCarrierVolumes[];
  bookingType: string;
  selectedYear: number;
  selectedMonth: number | null;
}

const MonthlyCarrierVolumeGrid: React.FC<MonthlyCarrierVolumeGridProps> = ({
  data,
  handleClose,
  open,
  bookingType,
  selectedYear,
  selectedMonth,
}) => {
  const currentMonth = getCurrentMonth(selectedYear, selectedMonth);
  const allMonths = getAllMonthNames(currentMonth);
  const excelFileName = selectedMonth
    ? `Carrier-Volume-${bookingType}-Monthly-Report_${allMonths[selectedMonth - 1]}-${selectedYear.toString()}`
    : `Carrier-Volume-${bookingType}-Monthly-Report_${selectedYear.toString()}`;

  const [transformedData, setTransformedData] = useState<any[]>([]);

  useEffect(() => {
    const transformData = () => {
      const rows = data.map((item, index) => {
        const baseRow: {
          id: number | string;
          carrier: string;
          totalVolume: number;
          [key: string]: number | string | boolean;
        } = {
          id: index,
          carrier: item.carrierName,
          totalVolume: bookingType === 'Export' ? item.totalExportVolume : item.totalImportVolume,
        };

        // Add monthly volumes
        allMonths.forEach(month => {
          if (bookingType === 'Export') {
            const monthData = item.exportCarrierVolumes.find(m => m.month === month);
            baseRow[`${month}_volume`] = monthData?.volume || 0;
          } else {
            const monthData = item.importCarrierVolumes.find(m => m.month === month);
            baseRow[`${month}_volume`] = monthData?.volume || 0;
          }
        });

        return baseRow;
      });

      // Add YTD row
      const ytdRow: { id: string; carrier: string; totalVolume: number; [key: string]: number | string | boolean } = {
        id: 'ytd',
        carrier: 'YTD',
        totalVolume: data.reduce(
          (total, carrier) =>
            total + (bookingType === 'Export' ? carrier.totalExportVolume : carrier.totalImportVolume),
          0,
        ),
        isYTD: true,
      };

      allMonths.forEach(month => {
        const monthTotal = data.reduce((total, carrier) => {
          if (bookingType === 'Export') {
            const monthData = carrier.exportCarrierVolumes.find(m => m.month === month);
            return total + (monthData?.volume || 0);
          }
          const monthData = carrier.importCarrierVolumes.find(m => m.month === month);
          return total + (monthData?.volume || 0);
        }, 0);
        ytdRow[`${month}_volume`] = monthTotal;
      });

      rows.push(ytdRow);
      return rows;
    };

    setTransformedData(transformData());
  }, [data, bookingType]);

  const columns: GridColDef[] = [
    {
      field: 'carrier',
      headerName: 'Carrier',
      flex: 2,
      renderCell: params => (
        <Tooltip title={params.value} placement='top'>
          <span>{truncateName(params.value as string)}</span>
        </Tooltip>
      ),
    },
    ...allMonths.map(
      (month): GridColDef => ({
        field: `${month}_volume`,
        headerName: `${month} (Volume)`,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
      }),
    ),
    {
      field: 'totalVolume',
      headerName: `Total ${bookingType} Volume`,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  const handleExport = () => {
    const exportData = transformedData.map(row => {
      const exportRow: { [key: string]: string | number } = {
        Carrier: row.carrier,
      };

      allMonths.forEach(month => {
        exportRow[`${month} (Volume)`] = row[`${month}_volume`];
      });

      exportRow[`Total ${bookingType} Volume`] = row.totalVolume;

      return exportRow;
    });

    handleExcelDownload(exportData, excelFileName);
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth fullScreen maxWidth='xl'>
        <DialogTitle>
          <Box display='flex' alignItems='center' gap={1}>
            {bookingType} Monthly Carrier Volume Report
            <Tooltip title='Download as Excel' placement='top'>
              <IconButton onClick={handleExport}>
                <DownloadOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent>
          <MonthlyReportCustomDataGrid rows={transformedData} columns={columns} />
          <GeneralDialogueActions noSubmission handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MonthlyCarrierVolumeGrid;
