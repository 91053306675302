import { AddOutlined, FilterAltOutlined, RefreshOutlined, SaveOutlined } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  AlertColor,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { sec } from 'auth/accessToken';
import axios from 'axios';
import AlertSnackbar from 'components/AlertSnackbar';
import AuditLogsDialogue from 'components/AuditLogsDialogue';
import BookingsFilterSideBar from 'components/BookingsFilterSideBar';
import CreateEnquiryDialogue from 'components/CreateEnquiryDialogue';
import CustomDataGrid from 'components/CustomDataGrid';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import dayjs from 'dayjs';
import { TeamType, TransportSite } from 'models/booking.model';
import { Customer, Enquiry, enquiryRemarks, FilterType, Port, Vendor } from 'models/index.model';
import React, { useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setEnquiriesColumnVisibilityModel } from 'state';
import { generateActionsColumn } from 'utils/generateActionsColumn';
import { saveUserPreferences } from 'utils/serverFunctions';
import { handleSnackbarClose, setBookingTeams, useUserPermissions } from 'utils/utils';
import Header from '../../components/Header';
import { useGetAuditsQuery, useGetEnquiriesQuery } from '../../state/api';
import { initialState, reducer } from '../../utils/dialogState';
import SearchBar from 'components/SearchBar';
import { freightModes } from 'dataAssets/constants';

const Enquiries = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openConfirmDialogue, setOpenConfirmDialogue] = useState(false);
  const [openAuditDialogue, setOpenAuditDialogue] = useState(false);
  const [currentMasterDataId, setCurrentMasterDataId] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('success');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dataToDisplay, setDataToDisplay] = useState<Array<enquiryRemarks | TransportSite>>([]);
  const userEmail = useSelector((state: any) => state.global.userEmail);
  const userPermissions = useUserPermissions();
  const hasCreateExportEnquiryPermission = userPermissions.includes('create:enquiries-export');
  const hasCreateImportEnquiryPermission = userPermissions.includes('create:enquiries-import');
  const hasUpdateEnquiryPermission = userPermissions.includes('update:enquiries');
  const hasDeleteEnquiryPermission = userPermissions.includes('delete:enquiries');
  const hasExportPermission = userPermissions.includes('read:enquiries-export');
  const hasImportPermission = userPermissions.includes('read:enquiries-import');
  const teams = setBookingTeams(hasExportPermission, hasImportPermission);
  const [{ open, entityToUpdate }, dispatch] = useReducer(reducer<Enquiry>, initialState<Enquiry>());
  const dispatchAction = useDispatch();
  const enquiriesColumnVisibilityModel = useSelector((state: any) => state.global.enquiriesColumnVisibilityModel);
  const enquiriesFilters = useSelector((state: any) => state.global.enquiriesFilters);
  const { data: auditLogs } = useGetAuditsQuery(['Enquiry', currentMasterDataId]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedField, setSelectedField] = useState<string>('');

  const {
    enquiryStartDate,
    enquiryEndDate,
    enquiryFreightMode,
    customer,
    enquiryContainerType,
    enquiryPortOfLoading,
    enquiryPortOfDestination,
    fpod,
    enquiryCarrier,
    enquiryStatus,
    enquiryPic,
    enquiryBookingTeam,
    incoterm,
  } = enquiriesFilters;

  const startDate = enquiryStartDate;
  const endDate = enquiryEndDate;

  const {
    data: enquiry,
    isLoading,
    refetch,
    isFetching,
  } = useGetEnquiriesQuery({
    hasExportPermission,
    hasImportPermission,
    dateRange: [startDate, endDate],
    freightMode: enquiryFreightMode,
    bookingTeam: enquiryBookingTeam,
    enquiryStatus: enquiryStatus,
    customer: customer?.map((item: Customer) => item?._id).join(','),
    carrier: enquiryCarrier?.map((item: Vendor) => item?._id).join(','),
    portOfLoading: enquiryPortOfLoading?.map((item: Port) => item?._id).join(','),
    portOfDestination: enquiryPortOfDestination?.map((item: Port) => item?._id).join(','),
    containerType: enquiryContainerType,
    fpod: fpod?.map((item: Port) => item?._id).join(','),
    pic: enquiryPic,
    incoterm,
    page: (page + 1).toString(),
    limit: pageSize.toString(),
    searchText,
    selectedField,
  });

  React.useEffect(() => {
    setTotalRows(enquiry?.totalEnquiries ?? 0);
  }, [enquiry?.totalEnquiries]);

  const defaultEnquiryValues = {
    buyRates: [],
    sellRates: [],
    dateCreated: undefined,
    volume: undefined,
    containerType: undefined,
    cargoDescription: undefined,
    portOfDestination: undefined,
    portOfLoading: undefined,
    fpod: undefined,
    carrier: undefined,
    carrierBookingNumber: undefined,
    purchaseOrderNumber: undefined,
    agent: undefined,
    enquiryStatus: undefined,
    pic: undefined,
    incoterm: undefined,
    enquiryRemarks: [],
    sites: [],
    bookingType: hasExportPermission ? 'Export' : hasImportPermission ? 'Import' : 'Export',
    bookingTeam: hasExportPermission ? TeamType.Rockers : hasImportPermission ? TeamType.Falcons : TeamType.Rockers,
    freightMode: freightModes[0],
  };

  const currentForm = useForm<Enquiry>({
    defaultValues: defaultEnquiryValues,
  });

  const handleClickOpenCreate = () => {
    dispatch({ type: 'OPEN_DIALOG', payload: {} as Enquiry });
  };

  const handleClickOpenEdit = (enquiry: Enquiry) => {
    dispatch({ type: 'OPEN_DIALOG', payload: enquiry });
  };

  const handleClose = () => {
    dispatch({ type: 'CLOSE_DIALOG' });
    refetch();
  };

  const handleOpenDialog = (data: enquiryRemarks[] | TransportSite[], type: string) => {
    setShowDialog(true);
    setDataToDisplay(data);
    if (type === 'Remarks') {
      setDialogTitle(type);
    } else {
      setDialogTitle(type);
    }
  };

  const handleSaveUserPreferences = async (userEmail: string) => {
    const { enquiryStartDate, enquiryEndDate, ...modifiedEnquiriesFilters } = enquiriesFilters;
    const data = { enquiriesFilters: modifiedEnquiriesFilters, enquiriesColumnVisibilityModel };
    const saveUpResponse = (await saveUserPreferences(userEmail, data, 'enquiries')) ?? 'error';
    setSnackbarOpen(true);
    setSnackbarSeverity(saveUpResponse);
  };

  const deleteEnquiry = async (enquiryId: string) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/enquiries/${enquiryId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'enquiryNumber',
      headerName: 'Enquiry Number',
      flex: 0.3,
    },
    {
      field: 'dateCreated',
      headerName: 'Date of Enquiry',
      flex: 0.3,
      valueGetter: params => {
        return dayjs(params.row.dateCreated).format('DD/MM/YY');
      },
    },
    {
      field: 'bookingTeam',
      headerName: 'Team',
      flex: 0.2,
    },
    { field: 'bookingType', headerName: 'Type', flex: 0.2 },
    { field: 'freightMode', headerName: 'Freight Mode', flex: 0.2 },
    {
      field: 'customer',
      headerName: 'Customer',
      flex: 0.3,
      valueGetter(params) {
        return params.row?.customer?.name;
      },
    },
    {
      field: 'volume',
      headerName: 'Volume',
      flex: 0.3,
    },
    {
      field: 'containerType',
      headerName: 'Container Type',
      flex: 0.3,
    },
    { field: 'containerLoadType', headerName: 'Load Type', flex: 0.2 },
    {
      field: 'portOfLoading',
      headerName: 'POL',
      flex: 0.3,
      valueGetter(params) {
        return params.row?.portOfLoading?.portName;
      },
    },
    {
      field: 'portOfDestination',
      headerName: 'POD',
      flex: 0.3,
      valueGetter(params) {
        return params.row?.portOfDestination?.portName;
      },
    },
    {
      field: 'fpod',
      headerName: 'FPOD',
      flex: 0.3,
      valueGetter(params) {
        return params.row?.fpod?.portName;
      },
    },
    {
      field: 'carrier',
      headerName: 'Carrier',
      flex: 0.3,
      valueGetter(params) {
        return params.row?.carrier?.name;
      },
    },
    {
      field: 'carrierBookingNumber',
      headerName: 'Carrier Bkg No',
      flex: 0.3,
    },
    {
      field: 'agent',
      headerName: 'Agent',
      flex: 0.3,
      valueGetter(params) {
        return params.row?.agent?.name;
      },
    },
    {
      field: 'purchaseOrderNumber',
      headerName: 'PO Number',
      flex: 0.3,
    },
    {
      field: 'incoterm',
      headerName: 'Incoterm',
      flex: 0.3,
    },

    {
      field: 'enquiryStatus',
      headerName: 'Status',
      flex: 0.3,
    },
    {
      field: 'pic',
      headerName: 'PIC',
      flex: 0.3,
    },
    {
      field: 'cargoDescription',
      headerName: 'Description',
      flex: 0.3,
    },
    {
      field: 'sites',
      headerName: 'Sites',
      flex: 0.2,
      renderCell: params => {
        return params.row.sites?.length > 0 && params.row.sites?.[0].site ? (
          <Tooltip title='Show Remarks'>
            <IconButton
              sx={{ color: theme.palette.secondary[400] }}
              onClick={() => handleOpenDialog(params.row.sites, 'Sites')}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Typography>NA</Typography>
        );
      },
    },
    {
      field: 'enquiryRemarks',
      headerName: 'Remarks',
      flex: 0.2,
      renderCell: params => {
        return params.row.enquiryRemarks?.[0]?.value ? (
          <Tooltip title='Show Remarks'>
            <IconButton
              id='show_remarks'
              sx={{ color: theme.palette.secondary[400] }}
              onClick={() => handleOpenDialog(params.row.enquiryRemarks, 'Remarks')}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Typography>NA</Typography>
        );
      },
    },
  ];

  if (hasUpdateEnquiryPermission || hasDeleteEnquiryPermission) {
    columns.push(
      generateActionsColumn<any>(
        hasUpdateEnquiryPermission,
        hasDeleteEnquiryPermission,
        handleClickOpenEdit,
        setOpenAuditDialogue,
        deleteEnquiry,
        openConfirmDialogue,
        setOpenConfirmDialogue,
        currentMasterDataId,
        setCurrentMasterDataId,
      ),
    );
  }

  const handleColumnVisibilityChange = (newModel: GridColumnVisibilityModel) => {
    dispatchAction(setEnquiriesColumnVisibilityModel(newModel));
  };

  const iconButtonStyling = {
    color: theme.palette.secondary[400],
    '&:hover': {
      color: theme.palette.secondary[500],
    },
  };

  return (
    <Box m='1.5rem 2.5rem'>
      <Box>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} gap={2} justifyContent={'center'} alignItems={'center'}>
            <Header title='Enquiries' />
            <SearchBar
              setSearchText={setSearchText}
              setSelectedField={setSelectedField}
              setPage={setPage}
              columns={columns}
            />
          </Box>
          <Box>
            {(hasCreateExportEnquiryPermission || hasCreateImportEnquiryPermission) && (
              <Tooltip title='Create Enquiries'>
                <IconButton sx={iconButtonStyling} onClick={handleClickOpenCreate}>
                  <AddOutlined />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title='Save Preferences'>
              <IconButton onClick={() => handleSaveUserPreferences(userEmail)} sx={iconButtonStyling}>
                <SaveOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title='Refresh'>
              <IconButton onClick={() => refetch()} sx={iconButtonStyling}>
                <RefreshOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title='Filter'>
              <IconButton onClick={() => setDrawerOpen(true)} sx={iconButtonStyling}>
                <FilterAltOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box>
        <CreateEnquiryDialogue
          useFormReference={currentForm}
          handleClose={handleClose}
          open={open}
          enquiryToUpdate={entityToUpdate}
          hasCreateExportEnquiryPermission={hasCreateExportEnquiryPermission}
          hasCreateImportEnquiryPermission={hasCreateImportEnquiryPermission}
          hasExportPermission={hasExportPermission}
          hasImportPermission={hasImportPermission}
          defaultEnquiryValues={defaultEnquiryValues}
        />
      </Box>

      <BookingsFilterSideBar
        type={FilterType.Enquiries}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        teams={teams}
      />
      <CustomDataGrid
        data={enquiry?.enquiries ?? []}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        columnVisibilityModel={enquiriesColumnVisibilityModel}
        handleColumnVisibilityChange={handleColumnVisibilityChange}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        totalRows={totalRows}
      />
      <Dialog open={showDialog} onClose={() => setShowDialog(false)} fullWidth maxWidth='lg'>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {dialogTitle} Details
        </DialogTitle>
        <DialogContent>
          <List>
            {dataToDisplay?.length > 0 &&
              dataToDisplay?.map((data: enquiryRemarks | TransportSite, index) => (
                <ListItem key={index}>
                  <ListItemIcon sx={{ minWidth: '25px' }}>
                    <CircleIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  {dialogTitle === 'Remarks'
                    ? 'value' in data && <ListItemText primary={data?.value} />
                    : 'site' in data && (
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6}>
                            <Typography sx={{ fontSize: '1.2rem' }}>{data?.site?.siteName}</Typography>
                            <Typography sx={{ fontSize: '0.9rem' }} color='text.secondary'>
                              {data?.site?.address}, {data?.site?.city}, {data?.site?.postcode}, {data?.site?.country}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                          >
                            <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                              {data?.containers} containers
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                </ListItem>
              ))}
          </List>{' '}
        </DialogContent>
        <GeneralDialogueActions noSubmission handleClose={() => setShowDialog(false)} />
      </Dialog>
      <AuditLogsDialogue
        open={openAuditDialogue}
        handleClose={() => setOpenAuditDialogue(false)}
        auditLogs={auditLogs}
      />
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={(_: any, reason: string) => handleSnackbarClose(reason, setSnackbarOpen)}
        severity={snackbarSeverity}
        message={snackbarSeverity === 'success' ? 'Preferences saved successfully' : 'Error saving user preference'}
      />
    </Box>
  );
};
export default Enquiries;
