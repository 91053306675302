import React, { useCallback, useState } from 'react';
import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon, useTheme } from '@mui/material';
import {
  AddOutlined,
  DeleteOutlined,
  FileCopyOutlined,
  CheckCircleOutlined,
  BlockOutlined,
  CallSplitOutlined,
  MergeOutlined,
  LockOpenOutlined,
} from '@mui/icons-material';
import { generatePath, useNavigate } from 'react-router-dom';
import { useUserPermissions } from 'utils/utils';
import { duplicateBooking, deleteBooking, completeBooking, cancelBooking, reopenBooking } from 'utils/serverFunctions';
import { BookingStatus, StepDetails } from 'models/booking.model';
import ConfirmationDialog from 'components/ConfirmationDialog';
import {
  useGetAuditsQuery,
  useGetBookingProfitQuery,
  useGetExpectedProfitQuery,
  useGetActualProfitQuery,
} from 'state/api';

export interface BookingSpeedDialProps {
  bookingId: string;
  bookingNumber: string;
  bookingStatus: BookingStatus;
  stepsCompleted: StepDetails[];
  refetch: Function;
  showSplitBooking: boolean;
  openSplitBookingDialogue: Function;
  openMergeBookingDialogue: Function;
}

const BookingSpeedDial: React.FC<BookingSpeedDialProps> = ({
  bookingId,
  bookingNumber,
  bookingStatus,
  stepsCompleted,
  refetch,
  showSplitBooking,
  openSplitBookingDialogue,
  openMergeBookingDialogue,
}) => {
  const theme = useTheme();
  const SpeedDialActionsStyles = {
    color: 'white',
    '&.MuiSpeedDialAction-fab': {
      backgroundColor: theme.palette.secondary[500],
      '&:hover': {
        backgroundColor: theme.palette.secondary[600],
      },
      '&:disabled': {
        color: '#90b0cc',
      },
    },
  };
  const SpeedDialStyles = {
    position: 'fixed',
    bottom: '1rem',
    right: '1rem',
  };

  const userPermissions = useUserPermissions();
  const navigate = useNavigate();

  const { data: bookingIncome } = useGetBookingProfitQuery(bookingId);
  const { refetch: auditLogsRefetch } = useGetAuditsQuery(['Booking', bookingId]);
  const { data: expectedProfit } = useGetExpectedProfitQuery(bookingId);
  const { data: actualProfit } = useGetActualProfitQuery(bookingId);

  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);

  const handleCreateBooking = () => {
    navigate(`/bookings/create`);
  };
  const handleDuplicateBooking = async () => {
    const newBookingId = await duplicateBooking(bookingId);
    navigate(
      generatePath(`/bookings/${newBookingId}`, {
        id: newBookingId,
      }),
    );
    window.location.reload();
  };
  const handleDeleteBooking = async () => {
    await deleteBooking(bookingId, bookingNumber);
    navigate(`/bookings`);
  };
  const handleBookingCompletion = async () => {
    await completeBooking(bookingId, bookingIncome);
    refetch();
    auditLogsRefetch();
  };
  const handleBookingCancellation = async () => {
    await cancelBooking(bookingId);
    refetch();
    auditLogsRefetch();
  };
  const handleBookingReopening = async () => {
    await reopenBooking(bookingId);
    refetch();
    auditLogsRefetch();
  };

  const [actionToConfirm, setActionToConfirm] = useState<(() => void) | null>(null);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [warningMessage, setWarningMessage] = useState('');

  const handleActionConfirmation = async () => {
    if (actionToConfirm) {
      actionToConfirm();
      setActionToConfirm(null);
    }
  };

  const createActionHandler = (actionFn: () => void, title: string, content: string, warningMessage?: string) => {
    setActionToConfirm(() => actionFn);
    setDialogTitle(title);
    setDialogContent(content);
    setWarningMessage(warningMessage || '');
  };

  const isStepsPending = stepsCompleted.length < 6;

  const handleProfitsThresholdCheck = (
    expectedProfit: number,
    actualProfit: number,
  ): {
    isOutOfThreshold: boolean;
    percentageDifference: string;
    deviationType: '+' | '-';
  } => {
    if (expectedProfit === 0) {
      return { isOutOfThreshold: false, percentageDifference: '0.00', deviationType: '+' };
    }

    const profitDifference = Math.abs(expectedProfit - actualProfit);
    const threshold = Math.abs(expectedProfit * 0.1);
    const isOutOfThreshold = profitDifference > threshold;
    const percentageDifference = ((profitDifference / expectedProfit) * 100).toFixed(2);
    const deviationType = actualProfit > expectedProfit ? '+' : '-';

    return { isOutOfThreshold, percentageDifference, deviationType };
  };

  const handleCompleteBookingClick = useCallback(() => {
    const { isOutOfThreshold, percentageDifference, deviationType } = handleProfitsThresholdCheck(
      expectedProfit || 0,
      actualProfit || 0,
    );

    const message = 'Are you sure you want to complete this booking?';

    const warningMessage = isOutOfThreshold
      ? `The actual profit is significantly different from the expected profit by ${deviationType}${percentageDifference}%.`
      : '';

    createActionHandler(handleBookingCompletion, 'Complete Booking', message, warningMessage);
  }, [expectedProfit, actualProfit, handleBookingCompletion]);

  return (
    <Box>
      <SpeedDial
        ariaLabel='Booking SpeedDial'
        sx={SpeedDialStyles}
        direction='up'
        icon={<SpeedDialIcon />}
        onClose={() => setOpenSpeedDial(false)}
        onOpen={() => setOpenSpeedDial(true)}
        open={openSpeedDial}
        FabProps={{
          sx: {
            height: '4rem',
            width: '4rem',
            bgcolor: theme.palette.secondary[500],
            '&:hover': {
              bgcolor: theme.palette.secondary[600],
            },
          },
        }}
      >
        <SpeedDialAction
          sx={SpeedDialActionsStyles}
          icon={<AddOutlined />}
          tooltipTitle={'Create Booking'}
          onClick={handleCreateBooking}
        />
        <SpeedDialAction
          sx={SpeedDialActionsStyles}
          icon={<FileCopyOutlined />}
          tooltipTitle={'Duplicate Booking'}
          onClick={() =>
            createActionHandler(
              handleDuplicateBooking,
              'Duplicate Booking',
              'Are you sure you want to duplicate this booking?',
            )
          }
        />
        {isStepsPending && showSplitBooking && (
          <SpeedDialAction
            sx={SpeedDialActionsStyles}
            icon={<CallSplitOutlined />}
            tooltipTitle={'Split Booking'}
            onClick={() => openSplitBookingDialogue()}
          />
        )}
        {isStepsPending && (
          <SpeedDialAction
            sx={SpeedDialActionsStyles}
            icon={<MergeOutlined />}
            tooltipTitle={'Merge Bookings'}
            onClick={() => openMergeBookingDialogue()}
          />
        )}
        {userPermissions.includes('delete:booking') && (
          <SpeedDialAction
            sx={SpeedDialActionsStyles}
            icon={<DeleteOutlined />}
            tooltipTitle={'Delete Booking'}
            onClick={() =>
              createActionHandler(
                handleDeleteBooking,
                'Delete Booking',
                'Are you sure you want to delete this booking?',
              )
            }
          />
        )}
        {bookingStatus === BookingStatus.Ongoing && (
          <SpeedDialAction
            sx={SpeedDialActionsStyles}
            icon={<BlockOutlined />}
            tooltipTitle={'Cancel Booking'}
            onClick={() =>
              createActionHandler(
                handleBookingCancellation,
                'Cancel Booking',
                'Are you sure you want to cancel this booking?',
              )
            }
          />
        )}
        {bookingStatus === BookingStatus.Ongoing && userPermissions.includes('update:booking-completion') && (
          <SpeedDialAction
            sx={SpeedDialActionsStyles}
            icon={<CheckCircleOutlined />}
            tooltipTitle={'Complete Booking'}
            onClick={handleCompleteBookingClick}
            FabProps={{ disabled: isStepsPending }}
          />
        )}
        {bookingStatus === BookingStatus.Completed && userPermissions.includes('update:booking-completion') && (
          <SpeedDialAction
            sx={SpeedDialActionsStyles}
            icon={<LockOpenOutlined />}
            tooltipTitle={'Reopen Booking'}
            onClick={() =>
              createActionHandler(
                handleBookingReopening,
                'Reopen Booking',
                'Are you sure you want to reopen this booking?',
              )
            }
          />
        )}
      </SpeedDial>
      <ConfirmationDialog
        open={!!actionToConfirm}
        handleClose={() => setActionToConfirm(null)}
        onConfirm={handleActionConfirmation}
        title={dialogTitle}
        content={dialogContent}
        warningMessage={warningMessage}
      />
    </Box>
  );
};

export default BookingSpeedDial;
