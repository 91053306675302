import React from 'react';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useTheme } from '@mui/system';
import Box from '@mui/material/Box';

type MonthlyReportCustomDataGridProps = {
  rows: any[];
  columns: GridColDef[];
};

const MonthlyReportCustomDataGrid: React.FC<MonthlyReportCustomDataGridProps> = ({ rows, columns }) => {
  const theme = useTheme();
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

  const sortedRows = React.useMemo(() => {
    if (sortModel.length === 0) return rows;

    const { field, sort } = sortModel[0];

    return [...rows].sort((a, b) => {
      // Keep YTD row at the bottom
      if (a.isYTD) return 1;
      if (b.isYTD) return -1;

      // Normal sorting for other rows
      if (sort === 'asc') return a[field] > b[field] ? 1 : -1;
      return a[field] < b[field] ? 1 : -1;
    });
  }, [rows, sortModel]);

  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        rows={sortedRows}
        columns={columns}
        disableRowSelectionOnClick
        sortingMode='server'
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        sx={{
          '& .MuiDataGrid-cell': {
            borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : '#292929',
          },
          '& .MuiDataGrid-columnHeader': {
            borderRight: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[900],
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900],
            borderTop: theme.palette.mode === 'dark' ? '1px solid #515151' : '1px solid #adadad',
          },
          '& .MuiTablePagination-root': {
            color: theme.palette.text.primary,
          },
          '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
            color: theme.palette.text.secondary,
          },
          '& .MuiTablePagination-select': {
            color: theme.palette.text.primary,
          },
          '& .MuiIconButton-root.MuiIconButton-sizeSmall': {
            color: theme.palette.text.primary,
            '&.Mui-disabled': {
              color: theme.palette.text.disabled,
            },
          },
          '& .MuiDataGrid-row': {
            '&:last-child': {
              fontWeight: 'bold',
            },
          },
        }}
      />
    </Box>
  );
};

export default MonthlyReportCustomDataGrid;
