import { Booking, BookingStatus, HaulageType, TeamType, TransportStatus } from 'models/booking.model';
import { CountryType } from 'models/index.model';

export const containerTypes: string[] = [
  '40 Dry High',
  '20 Dry Standard',
  '20 Open Top',
  '40 Dry Standard',
  '45 Dry High',
  '20 Tank',
  '40 Tank',
  '20 Reefer Standard',
  '40 Reefer High',
  '40 Reefer Standard',
  '40 Open Top',
  '40 Open Top High',
  '40 Flat Standard',
  '40 Flat High',
  '20 Flat',
];

export const incoterms = ['EXW', 'FCA', 'FAS', 'FOB', 'CPT', 'CIP', 'CFR', 'CIF', 'DAP', 'DPU', 'DDP', 'DDU'];

export const reudanAddress = `Office 102, 15 Main Drive, East Lane Business Park, Wembley, HA9 7NA
              Tel: +44 (0) 208 904 9616`;

export const currencies: string[] = ['USD', 'GBP', 'EUR'];

export const bases: string[] = ['CN', 'BL'];

export const vendorTypes: string[] = ['Carrier', 'Haulier', 'Agent']; // todo get from VendorType enum instead

export const invoiceTypes: Record<string, string> = {
  salesInvoices: 'Sales Invoice',
  purchaseInvoices: 'Purchase Invoice',
  creditNotes: 'Credit Note',
  debitNotes: 'Debit Note',
};

export const currencySymbols: Record<string, string> = {
  USD: '$',
  EUR: '€',
  GBP: '£',
};

export const documentTypes: string[] = [
  'Booking Confirmation',
  'VGM',
  'SI',
  'UCR',
  'Purchase Invoice',
  'BL',
  'BL Draft',
  'Other',
];

export const enquiryStatuses: string[] = ['InProgress', 'Cancelled', 'Completed'];

export const blStatuses: string[] = [
  'SI & VGM SUB',
  'DRAFT SENT',
  'AMENDMENT REQ',
  'AWA REV DRAFT',
  'REV DRAFT SENT',
  'INCORRECT DRAFT SENT',
  'BL APPROVED',
  'OBL SENT',
  'OBL WEB SENT',
  'SWB SENT',
  'TELEX RELEASE',
];

export const transportStatuses: TransportStatus[] = [
  TransportStatus.Pending,
  TransportStatus.Delayed,
  TransportStatus.Diverted,
  TransportStatus.Cancelled,
  TransportStatus.Rebooked,
  TransportStatus.Completed,
];

export const bookingTypes: string[] = ['Export', 'Import'];
export const bookingStatuses: BookingStatus[] = [
  BookingStatus.Ongoing,
  BookingStatus.Cancelled,
  BookingStatus.Merged,
  BookingStatus.Completed,
];
export const exportBookingTeams: TeamType[] = [TeamType.All, TeamType.Rockers, TeamType.Sharks];
export const importBookingTeams: TeamType[] = [TeamType.All, TeamType.Falcons];
export const blTypes: string[] = ['Seaway', 'Original'];
export const loadingTypes: string[] = ['SD', 'CY'];
export const doorFacingOptions: string[] = ['Cab', 'Rear'];
export const haulageTypes: HaulageType[] = [HaulageType.Merchant, HaulageType.Carrier, HaulageType.Combined];
export const freightModes: string[] = ['Sea', 'Air', 'Road', 'Rail'];
export const containerLoadTypes: string[] = ['FCL', 'LCL'];

export const DEFAULT_BOOKING: Booking = {
  bookingType: bookingTypes[0],
  freightMode: freightModes[0],
  bookingStatus: bookingStatuses[0],
  bookingTeam: TeamType.Rockers,
  mblStatus: blTypes[0],
  hblStatus: blTypes[0],
  containerType: containerTypes[0],
  containerLoadType: containerLoadTypes[0],
  loadingTypeExport: loadingTypes[0],
  loadingTypeImport: loadingTypes[0],
  doorFacing: doorFacingOptions[1],
  cargoValue: {
    currency: currencies[0],
  },
} as Booking;

export const BookingFilterLabels = [
  'Status',
  'Freight Mode',
  'Team',
  'Creation Start',
  'Creation End',
  'Consignors',
  'Consignee',
  'Carrier',
  'POL',
  'POD',
  'Container Type',
  'BL Status',
  'Sales Invoice Created',
  'Purchase Invoice Created',
  'Outstanding Additional Charges',
  'Booking Confirmation Sent',
  'Profits Matching',
  'All Steps Completed',
  'Person In Charge',
];
export const EnquiryFilterLabels = [
  'Freight Mode',
  'Team',
  'Type',
  'Enquiry Start',
  'Enquiry End',
  'Status',
  'Customer',
  'Carrier',
  'POL',
  'POD',
  'FPOD',
  'Container Type',
  'Person In Charge',
  'Incoterm',
];
export const FieldsToIncludeInSearchBar = [
  'bookingNumber',
  'carrierBookingNumber',
  'carrierQuotationReference',
  'purchaseOrderNumber',
  'releasePin',
  'cargoDescription',
  'HSCode',
  'incoterm',
  'pic',
  'name',
  'address',
  'creditPeriod',
  'creditLimit',
  'EORI',
  'role',
  'phoneNumber',
  'email',
  'siteName',
  'city',
  'postcode',
  'country',
  'portName',
  'portCode',
  'bank',
  'branch',
  'accountNumber',
  'currency',
  'provider',
  'clientId',
  'clientSecret',
  'enquiryNumber',
  'volume',
  'containerType',
  'performaInvoiceNumber',
  'approvedInvoiceNumber',
  'invoiceCurrency',
  'vendorInvoiceNumber',
  'receiptsAndPaymentsNumber',
  'advPaymentNumber',
  'remarks',
];

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries: readonly CountryType[] = [
  { id: 1, code: 'AD', label: 'Andorra', phone: '376' },
  {
    id: 2,
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
  },
  { id: 3, code: 'AF', label: 'Afghanistan', phone: '93' },
  {
    id: 4,
    code: 'AG',
    label: 'Antigua and Barbuda',
    phone: '1268',
  },
  { id: 5, code: 'AI', label: 'Anguilla', phone: '1264' },
  { id: 6, code: 'AL', label: 'Albania', phone: '355' },
  { id: 7, code: 'AM', label: 'Armenia', phone: '374' },
  { id: 8, code: 'AO', label: 'Angola', phone: '244' },
  { id: 9, code: 'AQ', label: 'Antarctica', phone: '672' },
  { id: 10, code: 'AR', label: 'Argentina', phone: '54' },
  { id: 11, code: 'AS', label: 'American Samoa', phone: '1684' },
  { id: 12, code: 'AT', label: 'Austria', phone: '43' },
  {
    id: 13,
    code: 'AU',
    label: 'Australia',
    phone: '61',
    suggested: true,
  },
  { id: 14, code: 'AW', label: 'Aruba', phone: '297' },
  { id: 15, code: 'AX', label: 'Alland Islands', phone: '358' },
  { id: 16, code: 'AZ', label: 'Azerbaijan', phone: '994' },
  {
    id: 17,
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387',
  },
  { id: 18, code: 'BB', label: 'Barbados', phone: '1246' },
  { id: 19, code: 'BD', label: 'Bangladesh', phone: '880' },
  { id: 20, code: 'BE', label: 'Belgium', phone: '32' },
  { id: 21, code: 'BF', label: 'Burkina Faso', phone: '226' },
  { id: 22, code: 'BG', label: 'Bulgaria', phone: '359' },
  { id: 23, code: 'BH', label: 'Bahrain', phone: '973' },
  { id: 24, code: 'BI', label: 'Burundi', phone: '257' },
  { id: 25, code: 'BJ', label: 'Benin', phone: '229' },
  { id: 26, code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { id: 27, code: 'BM', label: 'Bermuda', phone: '1441' },
  { id: 28, code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { id: 29, code: 'BO', label: 'Bolivia', phone: '591' },
  { id: 30, code: 'BR', label: 'Brazil', phone: '55' },
  { id: 31, code: 'BS', label: 'Bahamas', phone: '1242' },
  { id: 32, code: 'BT', label: 'Bhutan', phone: '975' },
  { id: 33, code: 'BV', label: 'Bouvet Island', phone: '47' },
  { id: 34, code: 'BW', label: 'Botswana', phone: '267' },
  { id: 35, code: 'BY', label: 'Belarus', phone: '375' },
  { id: 36, code: 'BZ', label: 'Belize', phone: '501' },
  {
    id: 37,
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true,
  },
  {
    id: 38,
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    phone: '61',
  },
  {
    id: 39,
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
    phone: '243',
  },
  {
    id: 40,
    code: 'CF',
    label: 'Central African Republic',
    phone: '236',
  },
  {
    id: 41,
    code: 'CG',
    label: 'Congo, Republic of the',
    phone: '242',
  },
  { id: 42, code: 'CH', label: 'Switzerland', phone: '41' },
  { id: 43, code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { id: 44, code: 'CK', label: 'Cook Islands', phone: '682' },
  { id: 45, code: 'CL', label: 'Chile', phone: '56' },
  { id: 46, code: 'CM', label: 'Cameroon', phone: '237' },
  { id: 47, code: 'CN', label: 'China', phone: '86' },
  { id: 48, code: 'CO', label: 'Colombia', phone: '57' },
  { id: 49, code: 'CR', label: 'Costa Rica', phone: '506' },
  { id: 50, code: 'CU', label: 'Cuba', phone: '53' },
  { id: 51, code: 'CV', label: 'Cape Verde', phone: '238' },
  { id: 52, code: 'CW', label: 'Curacao', phone: '599' },
  { id: 53, code: 'CX', label: 'Christmas Island', phone: '61' },
  { id: 54, code: 'CY', label: 'Cyprus', phone: '357' },
  { id: 55, code: 'CZ', label: 'Czech Republic', phone: '420' },
  {
    id: 56,
    code: 'DE',
    label: 'Germany',
    phone: '49',
    suggested: true,
  },
  { id: 57, code: 'DJ', label: 'Djibouti', phone: '253' },
  { id: 58, code: 'DK', label: 'Denmark', phone: '45' },
  { id: 59, code: 'DM', label: 'Dominica', phone: '1767' },
  {
    id: 60,
    code: 'DO',
    label: 'Dominican Republic',
    phone: '1809',
  },
  { id: 61, code: 'DZ', label: 'Algeria', phone: '213' },
  { id: 62, code: 'EC', label: 'Ecuador', phone: '593' },
  { id: 63, code: 'EE', label: 'Estonia', phone: '372' },
  { id: 64, code: 'EG', label: 'Egypt', phone: '20' },
  { id: 65, code: 'EH', label: 'Western Sahara', phone: '212' },
  { id: 66, code: 'ER', label: 'Eritrea', phone: '291' },
  { id: 67, code: 'ES', label: 'Spain', phone: '34' },
  { id: 68, code: 'ET', label: 'Ethiopia', phone: '251' },
  { id: 69, code: 'FI', label: 'Finland', phone: '358' },
  { id: 70, code: 'FJ', label: 'Fiji', phone: '679' },
  {
    id: 71,
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
    phone: '500',
  },
  {
    id: 72,
    code: 'FM',
    label: 'Micronesia, Federated States of',
    phone: '691',
  },
  { id: 73, code: 'FO', label: 'Faroe Islands', phone: '298' },
  {
    id: 74,
    code: 'FR',
    label: 'France',
    phone: '33',
    suggested: true,
  },
  { id: 75, code: 'GA', label: 'Gabon', phone: '241' },
  { id: 76, code: 'GB', label: 'United Kingdom', phone: '44' },
  { id: 77, code: 'GD', label: 'Grenada', phone: '1473' },
  { id: 78, code: 'GE', label: 'Georgia', phone: '995' },
  { id: 79, code: 'GF', label: 'French Guiana', phone: '594' },
  { id: 80, code: 'GG', label: 'Guernsey', phone: '44' },
  { id: 81, code: 'GH', label: 'Ghana', phone: '233' },
  { id: 82, code: 'GI', label: 'Gibraltar', phone: '350' },
  { id: 83, code: 'GL', label: 'Greenland', phone: '299' },
  { id: 84, code: 'GM', label: 'Gambia', phone: '220' },
  { id: 85, code: 'GN', label: 'Guinea', phone: '224' },
  { id: 86, code: 'GP', label: 'Guadeloupe', phone: '590' },
  { id: 87, code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { id: 88, code: 'GR', label: 'Greece', phone: '30' },
  {
    id: 89,
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
  },
  { id: 90, code: 'GT', label: 'Guatemala', phone: '502' },
  { id: 91, code: 'GU', label: 'Guam', phone: '1671' },
  { id: 92, code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { id: 93, code: 'GY', label: 'Guyana', phone: '592' },
  { id: 94, code: 'HK', label: 'Hong Kong', phone: '852' },
  {
    id: 95,
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    phone: '672',
  },
  { id: 96, code: 'HN', label: 'Honduras', phone: '504' },
  { id: 97, code: 'HR', label: 'Croatia', phone: '385' },
  { id: 98, code: 'HT', label: 'Haiti', phone: '509' },
  { id: 99, code: 'HU', label: 'Hungary', phone: '36' },
  { id: 100, code: 'ID', label: 'Indonesia', phone: '62' },
  { id: 101, code: 'IE', label: 'Ireland', phone: '353' },
  { id: 102, code: 'IL', label: 'Israel', phone: '972' },
  { id: 103, code: 'IM', label: 'Isle of Man', phone: '44' },
  { id: 104, code: 'IN', label: 'India', phone: '91' },
  {
    id: 105,
    code: 'IO',
    label: 'British Indian Ocean Territory',
    phone: '246',
  },
  { id: 106, code: 'IQ', label: 'Iraq', phone: '964' },
  {
    id: 107,
    code: 'IR',
    label: 'Iran, Islamic Republic of',
    phone: '98',
  },
  { id: 108, code: 'IS', label: 'Iceland', phone: '354' },
  { id: 109, code: 'IT', label: 'Italy', phone: '39' },
  { id: 110, code: 'JE', label: 'Jersey', phone: '44' },
  { id: 111, code: 'JM', label: 'Jamaica', phone: '1876' },
  { id: 112, code: 'JO', label: 'Jordan', phone: '962' },
  {
    id: 113,
    code: 'JP',
    label: 'Japan',
    phone: '81',
    suggested: true,
  },
  { id: 114, code: 'KE', label: 'Kenya', phone: '254' },
  { id: 115, code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { id: 116, code: 'KH', label: 'Cambodia', phone: '855' },
  { id: 117, code: 'KI', label: 'Kiribati', phone: '686' },
  { id: 118, code: 'KM', label: 'Comoros', phone: '269' },
  {
    id: 119,
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    phone: '1869',
  },
  {
    id: 120,
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
    phone: '850',
  },
  { id: 121, code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { id: 122, code: 'KW', label: 'Kuwait', phone: '965' },
  { id: 123, code: 'KY', label: 'Cayman Islands', phone: '1345' },
  { id: 124, code: 'KZ', label: 'Kazakhstan', phone: '7' },
  {
    id: 125,
    code: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '856',
  },
  { id: 126, code: 'LB', label: 'Lebanon', phone: '961' },
  { id: 127, code: 'LC', label: 'Saint Lucia', phone: '1758' },
  { id: 128, code: 'LI', label: 'Liechtenstein', phone: '423' },
  { id: 130, code: 'LK', label: 'Sri Lanka', phone: '94' },
  { id: 131, code: 'LR', label: 'Liberia', phone: '231' },
  { id: 132, code: 'LS', label: 'Lesotho', phone: '266' },
  { id: 133, code: 'LT', label: 'Lithuania', phone: '370' },
  { id: 134, code: 'LU', label: 'Luxembourg', phone: '352' },
  { id: 135, code: 'LV', label: 'Latvia', phone: '371' },
  { id: 136, code: 'LY', label: 'Libya', phone: '218' },
  { id: 137, code: 'MA', label: 'Morocco', phone: '212' },
  { id: 138, code: 'MC', label: 'Monaco', phone: '377' },
  {
    id: 139,
    code: 'MD',
    label: 'Moldova, Republic of',
    phone: '373',
  },
  { id: 140, code: 'ME', label: 'Montenegro', phone: '382' },
  {
    id: 141,
    code: 'MF',
    label: 'Saint Martin (French part)',
    phone: '590',
  },
  { id: 142, code: 'MG', label: 'Madagascar', phone: '261' },
  { id: 143, code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    id: 144,
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
  },
  { id: 145, code: 'ML', label: 'Mali', phone: '223' },
  { id: 146, code: 'MM', label: 'Myanmar', phone: '95' },
  { id: 147, code: 'MN', label: 'Mongolia', phone: '976' },
  { id: 148, code: 'MO', label: 'Macao', phone: '853' },
  {
    id: 149,
    code: 'MP',
    label: 'Northern Mariana Islands',
    phone: '1670',
  },
  { id: 150, code: 'MQ', label: 'Martinique', phone: '596' },
  { id: 151, code: 'MR', label: 'Mauritania', phone: '222' },
  { id: 152, code: 'MS', label: 'Montserrat', phone: '1664' },
  { id: 153, code: 'MT', label: 'Malta', phone: '356' },
  { id: 154, code: 'MU', label: 'Mauritius', phone: '230' },
  { id: 155, code: 'MV', label: 'Maldives', phone: '960' },
  { id: 156, code: 'MW', label: 'Malawi', phone: '265' },
  { id: 157, code: 'MX', label: 'Mexico', phone: '52' },
  { id: 158, code: 'MY', label: 'Malaysia', phone: '60' },
  { id: 159, code: 'MZ', label: 'Mozambique', phone: '258' },
  { id: 160, code: 'NA', label: 'Namibia', phone: '264' },
  { id: 170, code: 'NC', label: 'New Caledonia', phone: '687' },
  { id: 171, code: 'NE', label: 'Niger', phone: '227' },
  { id: 172, code: 'NF', label: 'Norfolk Island', phone: '672' },
  { id: 173, code: 'NG', label: 'Nigeria', phone: '234' },
  { id: 174, code: 'NI', label: 'Nicaragua', phone: '505' },
  { id: 175, code: 'NL', label: 'Netherlands', phone: '31' },
  { id: 176, code: 'NO', label: 'Norway', phone: '47' },
  { id: 177, code: 'NP', label: 'Nepal', phone: '977' },
  { id: 178, code: 'NR', label: 'Nauru', phone: '674' },
  { id: 179, code: 'NU', label: 'Niue', phone: '683' },
  { id: 180, code: 'NZ', label: 'New Zealand', phone: '64' },
  { id: 181, code: 'OM', label: 'Oman', phone: '968' },
  { id: 182, code: 'PA', label: 'Panama', phone: '507' },
  { id: 183, code: 'PE', label: 'Peru', phone: '51' },
  { id: 184, code: 'PF', label: 'French Polynesia', phone: '689' },
  { id: 185, code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { id: 186, code: 'PH', label: 'Philippines', phone: '63' },
  { id: 187, code: 'PK', label: 'Pakistan', phone: '92' },
  { id: 188, code: 'PL', label: 'Poland', phone: '48' },
  {
    id: 189,
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    phone: '508',
  },
  { id: 190, code: 'PN', label: 'Pitcairn', phone: '870' },
  { id: 191, code: 'PR', label: 'Puerto Rico', phone: '1' },
  {
    id: 192,
    code: 'PS',
    label: 'Palestine, State of',
    phone: '970',
  },
  { id: 193, code: 'PT', label: 'Portugal', phone: '351' },
  { id: 194, code: 'PW', label: 'Palau', phone: '680' },
  { id: 195, code: 'PY', label: 'Paraguay', phone: '595' },
  { id: 196, code: 'QA', label: 'Qatar', phone: '974' },
  { id: 197, code: 'RE', label: 'Reunion', phone: '262' },
  { id: 198, code: 'RO', label: 'Romania', phone: '40' },
  { id: 199, code: 'RS', label: 'Serbia', phone: '381' },
  { id: 200, code: 'RU', label: 'Russian Federation', phone: '7' },
  { id: 201, code: 'RW', label: 'Rwanda', phone: '250' },
  { id: 202, code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { id: 203, code: 'SB', label: 'Solomon Islands', phone: '677' },
  { id: 204, code: 'SC', label: 'Seychelles', phone: '248' },
  { id: 205, code: 'SD', label: 'Sudan', phone: '249' },
  { id: 206, code: 'SE', label: 'Sweden', phone: '46' },
  { id: 207, code: 'SG', label: 'Singapore', phone: '65' },
  { id: 208, code: 'SH', label: 'Saint Helena', phone: '290' },
  { id: 209, code: 'SI', label: 'Slovenia', phone: '386' },
  {
    id: 210,
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    phone: '47',
  },
  { id: 211, code: 'SK', label: 'Slovakia', phone: '421' },
  { id: 212, code: 'SL', label: 'Sierra Leone', phone: '232' },
  { id: 213, code: 'SM', label: 'San Marino', phone: '378' },
  { id: 215, code: 'SN', label: 'Senegal', phone: '221' },
  { id: 216, code: 'SO', label: 'Somalia', phone: '252' },
  { id: 217, code: 'SR', label: 'Suriname', phone: '597' },
  { id: 218, code: 'SS', label: 'South Sudan', phone: '211' },
  {
    id: 219,
    code: 'ST',
    label: 'Sao Tome and Principe',
    phone: '239',
  },
  { id: 220, code: 'SV', label: 'El Salvador', phone: '503' },
  {
    id: 221,
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    phone: '1721',
  },
  {
    id: 222,
    code: 'SY',
    label: 'Syrian Arab Republic',
    phone: '963',
  },
  { id: 223, code: 'SZ', label: 'Swaziland', phone: '268' },
  {
    id: 224,
    code: 'TC',
    label: 'Turks and Caicos Islands',
    phone: '1649',
  },
  { id: 225, code: 'TD', label: 'Chad', phone: '235' },
  {
    id: 226,
    code: 'TF',
    label: 'French Southern Territories',
    phone: '262',
  },
  { id: 227, code: 'TG', label: 'Togo', phone: '228' },
  { id: 228, code: 'TH', label: 'Thailand', phone: '66' },
  { id: 229, code: 'TJ', label: 'Tajikistan', phone: '992' },
  { id: 230, code: 'TK', label: 'Tokelau', phone: '690' },
  { id: 231, code: 'TL', label: 'Timor-Leste', phone: '670' },
  { id: 232, code: 'TM', label: 'Turkmenistan', phone: '993' },
  { id: 233, code: 'TN', label: 'Tunisia', phone: '216' },
  { id: 234, code: 'TO', label: 'Tonga', phone: '676' },
  { id: 235, code: 'TR', label: 'Turkey', phone: '90' },
  {
    id: 236,
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1868',
  },
  { id: 237, code: 'TV', label: 'Tuvalu', phone: '688' },
  {
    id: 238,
    code: 'TW',
    label: 'Taiwan',
    phone: '886',
  },
  {
    id: 239,
    code: 'TZ',
    label: 'United Republic of Tanzania',
    phone: '255',
  },
  { id: 240, code: 'UA', label: 'Ukraine', phone: '380' },
  { id: 241, code: 'UG', label: 'Uganda', phone: '256' },
  {
    id: 242,
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
  },
  { id: 243, code: 'UY', label: 'Uruguay', phone: '598' },
  { id: 244, code: 'UZ', label: 'Uzbekistan', phone: '998' },
  {
    id: 245,
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    phone: '379',
  },
  {
    id: 246,
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    phone: '1784',
  },
  { id: 247, code: 'VE', label: 'Venezuela', phone: '58' },
  {
    id: 248,
    code: 'VG',
    label: 'British Virgin Islands',
    phone: '1284',
  },
  {
    id: 249,
    code: 'VI',
    label: 'US Virgin Islands',
    phone: '1340',
  },
  { id: 250, code: 'VN', label: 'Vietnam', phone: '84' },
  { id: 251, code: 'VU', label: 'Vanuatu', phone: '678' },
  { id: 252, code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { id: 253, code: 'WS', label: 'Samoa', phone: '685' },
  { id: 254, code: 'XK', label: 'Kosovo', phone: '383' },
  { id: 255, code: 'YE', label: 'Yemen', phone: '967' },
  { id: 256, code: 'YT', label: 'Mayotte', phone: '262' },
  { id: 257, code: 'ZA', label: 'South Africa', phone: '27' },
  { id: 258, code: 'ZM', label: 'Zambia', phone: '260' },
  { id: 259, code: 'ZW', label: 'Zimbabwe', phone: '263' },
];

export const BankData = {
  countries: [
    {
      name: 'UK',
      banks: [
        'Barclays',
        'HSBC',
        'Lloyds Bank',
        'NatWest',
        'Santander UK',
        'Standard Chartered',
        'The Co-operative Bank',
        'TSB Bank',
        'Virgin Money UK',
        'Metro Bank',
        'Yorkshire Bank',
        'Clydesdale Bank',
      ],
    },
    {
      name: 'Argentina',
      banks: [
        'Allaria Ledesma',
        'Bancor',
        'Galicia',
        'Hipotecario',
        'ICBC',
        'Macro',
        'Mercadopago',
        'Provincia',
        'Saenz',
        'San Juan',
        'Santa Cruz',
        'Supervielle',
        'Wilobank',
      ],
    },
    {
      name: 'Australia',
      banks: [
        'ANZ',
        'Commonwealth Bank',
        'DNR Capital Pty',
        'Macquarie',
        'NAB',
        'Pepperstone',
        'Telstra Super Pty',
        'Westpac',
      ],
    },
    {
      name: 'Belgium',
      banks: ['ABN AMRO Private BE', "Argenta Banque d'Epargne", 'Candriam', 'Degroof Petercam'],
    },
    {
      name: 'Brazil',
      banks: [
        '3G Capital',
        'Banco do Brasil',
        'Bradesco',
        'Caixa Econômica Federal',
        'Itaú',
        'Previ',
        'Santander',
        'Sicoob',
        'Sicredi',
      ],
    },
    {
      name: 'Canada',
      banks: [
        'Bank of Montreal (BMO)',
        'CI Global Asset Management',
        'Desjardins Group',
        'Jarislowsky Fraser',
        'Laurentian Bank of Canada',
        'Mackenzie Investments Europe',
        'Manulife',
        'National Bank of Canada',
        'RBC',
        'Scotiabank',
        'Tangerine',
        'Toronto-Dominion Bank',
      ],
    },
    {
      name: 'Chile',
      banks: ['Banco de Chile', 'BancoEstado', 'BCI', 'BICE', 'Security', 'Santander'],
    },
    {
      name: 'Colombia',
      banks: ['AV Villas', 'Bancolombia', 'Banco de Bogotá', 'Banco Popular', 'Colpatria', 'Davivienda', 'Nequi'],
    },
    {
      name: 'France',
      banks: [
        'Allianz',
        'Amundi',
        'BNP Paribas Wealth Management',
        'Crédit Agricole',
        'LCL',
        'Natixis',
        'ODDO BHF',
        'Société Générale',
        'Transatlantique',
      ],
    },
    {
      name: 'Germany',
      banks: [
        'Bankhaus C. L. Seeliger',
        'Berenberg Bank',
        'Commerzbank',
        'DWS',
        'Merck Finck Privatbankiers',
        'SEB AG',
        'Weberbank Actiengesellschaft',
      ],
    },
    {
      name: 'India',
      banks: ['HDFC Bank', 'ICICI Bank', 'Kotak', 'Punjab National Bank', 'State Bank of India'],
    },
    {
      name: 'Italy',
      banks: ['Anima', 'Azimut', 'Banca Mediolanum', 'Intesa Sanpaolo', 'UniCredit'],
    },
    {
      name: 'Mexico',
      banks: [
        'Afirme',
        'Banco Azteca',
        'Banorte',
        'Banregio',
        'BBVA',
        'Citibanamex',
        'Inbursa',
        'Liverpool',
        'Monex',
        'Mifel',
        'Multiva',
        'Santander',
        'Ve por Más',
      ],
    },
    {
      name: 'Spain',
      banks: [
        'Abanca',
        'ActivoBank',
        'Arquia',
        'A&G',
        'BBVA',
        'CaixaBank',
        'Kutxabank',
        'Laboral Kutxa',
        'Sabadell',
        'Santander',
        'Triodos',
        'Unicaja',
      ],
    },
    {
      name: 'United States',
      banks: [
        'Abrams Capital Management',
        'Akre Capital Management',
        'Apple Bank for Savings',
        'Bank of America',
        'BlackRock',
        'Goldman Sachs',
        'JP Morgan',
        'KeyBank',
        'Morgan Stanley',
        'Northern Trust',
        'PNC',
        'State Farm',
        'TD Bank',
        'Wells Fargo',
      ],
    },
  ],
};
