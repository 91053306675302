import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Box, Tooltip, DialogTitle, IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { MonthlyHaulerStats } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { getAllMonthNames, getCurrentMonth, handleExcelDownload, truncateName } from 'utils/utils';
import { DownloadOutlined } from '@mui/icons-material';
import MonthlyReportCustomDataGrid from './MonthlyReportCustomDataGrid';

interface MonthlyHaulerStatsTableProps extends BaseDialogueProps {
  data: MonthlyHaulerStats[];
  bookingType: string;
  selectedYear: number;
  selectedMonth: number | null;
}

const MonthlyHaulerStatsTable: React.FC<MonthlyHaulerStatsTableProps> = ({
  data,
  bookingType,
  handleClose,
  open,
  selectedYear,
  selectedMonth,
}) => {
  const currentMonth = getCurrentMonth(selectedYear, selectedMonth);
  const allMonths = getAllMonthNames(currentMonth);
  const excelFileName = selectedMonth
    ? `Hauler-Monthly-${bookingType}-Report_${allMonths[selectedMonth - 1]}-${selectedYear.toString()}`
    : `Hauler-Monthly-${bookingType}-Report_${selectedYear.toString()}`;

  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  useEffect(() => {
    // Generate columns
    const generatedColumns: GridColDef[] = [
      {
        field: 'hauler',
        headerName: 'Hauler',
        flex: 2,
        renderCell: params => (
          <Tooltip title={params.value} placement='top'>
            <span>{truncateName(params.value as string)}</span>
          </Tooltip>
        ),
      },
    ];

    allMonths.forEach(month => {
      generatedColumns.push(
        {
          field: `${month}_completed`,
          headerName: `${month} (Completed)`,
          flex: 1,
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: `${month}_cancelled`,
          headerName: `${month} (Cancelled)`,
          flex: 1,
          align: 'center',
          headerAlign: 'center',
        },
      );
    });

    generatedColumns.push({
      field: 'totalLoadings',
      headerName: 'Total Loadings',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    });

    setColumns(generatedColumns);

    // Generate rows
    const generatedRows = data.map((hauler, index) => {
      const row: any = {
        id: index,
        hauler: hauler.haulerName,
        totalLoadings: hauler.totalLoadings,
      };

      allMonths.forEach(month => {
        const monthData = hauler.monthlyHaulerStats.find(m => m.month === month);
        row[`${month}_completed`] = monthData?.completedLoadings || 0;
        row[`${month}_cancelled`] = monthData?.canceledLoadings || 0;
      });

      return row;
    });

    // Calculate and add totals row
    const totalsRow: any = {
      id: 'ytd',
      hauler: 'YTD',
      totalLoadings: data.reduce((total, hauler) => total + hauler.totalLoadings, 0),
      isYTD: true,
    };

    allMonths.forEach(month => {
      const monthTotals = data.reduce(
        (acc, hauler) => {
          const monthData = hauler.monthlyHaulerStats.find(m => m.month === month);
          return {
            completed: acc.completed + (monthData?.completedLoadings || 0),
            cancelled: acc.cancelled + (monthData?.canceledLoadings || 0),
          };
        },
        { completed: 0, cancelled: 0 },
      );

      totalsRow[`${month}_completed`] = monthTotals.completed;
      totalsRow[`${month}_cancelled`] = monthTotals.cancelled;
    });

    generatedRows.push(totalsRow);
    setRows(generatedRows);
  }, [data]);

  // Prepare data for Excel export
  const getExcelData = () => {
    return rows.map(row => {
      const excelRow: { [key: string]: string | number } = {
        Hauler: row.hauler,
      };

      allMonths.forEach(month => {
        excelRow[`${month} (Completed)`] = row[`${month}_completed`];
        excelRow[`${month} (Cancelled)`] = row[`${month}_cancelled`];
      });

      excelRow['Total Loadings'] = row.totalLoadings;
      return excelRow;
    });
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth fullScreen maxWidth='xl'>
      <DialogTitle>
        <Box display='flex' alignItems='center' gap={1}>
          {bookingType} Monthly Hauler Report
          <Tooltip title='Download as Excel' placement='top'>
            <IconButton onClick={() => handleExcelDownload(getExcelData(), excelFileName)}>
              <DownloadOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent>
        <MonthlyReportCustomDataGrid rows={rows} columns={columns} />
        <GeneralDialogueActions noSubmission handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
};

export default MonthlyHaulerStatsTable;
