import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Box, Tooltip, DialogTitle, IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { MonthlyCommodityVolumeAndProfit } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { getAllMonthNames, getCurrentMonth, handleExcelDownload, truncateName } from 'utils/utils';
import { DownloadOutlined } from '@mui/icons-material';
import MonthlyReportCustomDataGrid from './MonthlyReportCustomDataGrid';

interface MonthlyCommodityVolumeAndProfitTableProps extends BaseDialogueProps {
  data: MonthlyCommodityVolumeAndProfit[];
  bookingType: string;
  profitType: string;
  selectedYear: number;
  selectedMonth: number | null;
}

const MonthlyCommodityVolumeAndProfitTable: React.FC<MonthlyCommodityVolumeAndProfitTableProps> = ({
  data,
  bookingType,
  profitType,
  handleClose,
  open,
  selectedYear,
  selectedMonth,
}) => {
  const currentMonth = getCurrentMonth(selectedYear, selectedMonth);
  const allMonths = getAllMonthNames(currentMonth);
  const excelFileName = selectedMonth
    ? `Commodity-Volume-And-${profitType}-Profit-${bookingType}-Monthly-Report_${allMonths[selectedMonth - 1]}-${selectedYear.toString()}`
    : `Commodity-Volume-And-${profitType}-Profit-${bookingType}-Monthly-Report_${selectedYear.toString()}`;

  const [transformedData, setTransformedData] = useState<any[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  useEffect(() => {
    // Generate columns
    const generatedColumns: GridColDef[] = [
      {
        field: 'commodity',
        headerName: 'Commodity',
        flex: 2,
        renderCell: params => (
          <Tooltip title={params.value} placement='top'>
            <span>{truncateName(params.value as string)}</span>
          </Tooltip>
        ),
      },
    ];

    // Add month columns
    allMonths.forEach(month => {
      generatedColumns.push(
        {
          field: `${month}_volume`,
          headerName: `${month} (Volume)`,
          flex: 1,
          align: 'center',
          headerAlign: 'center',
        },
        {
          field: `${month}_profit`,
          headerName: `${month} (Profit)`,
          flex: 1,
          align: 'center',
          headerAlign: 'center',
          valueFormatter: params => `£${params.value}`,
        },
      );
    });

    // Add total columns
    generatedColumns.push(
      {
        field: 'totalVolume',
        headerName: 'Total Volume',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'totalProfit',
        headerName: 'Total Profit',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: params => `£${params.value}`,
      },
    );

    setColumns(generatedColumns);

    // Transform data for DataGrid
    const s_transformedData = data.map((item, index) => {
      const row: any = {
        id: index,
        commodity: item.cargoDescription,
        totalVolume: item.totalVolume,
        totalProfit: item.totalProfit,
      };

      allMonths.forEach((month, monthIndex) => {
        row[`${month}_volume`] = item.monthlyCargoData[monthIndex].volume;
        row[`${month}_profit`] = item.monthlyCargoData[monthIndex].profit;
      });

      return row;
    });

    // Calculate and add totals row
    const totalsRow: {
      id: string;
      commodity: string;
      totalVolume: number;
      totalProfit: number;
      [key: string]: any;
    } = {
      id: 'ytd',
      commodity: 'YTD',
      totalVolume: data.reduce((total, cargoData) => total + cargoData.totalVolume, 0),
      totalProfit: data.reduce((total, cargoData) => total + cargoData.totalProfit, 0),
      isYTD: true,
    };

    allMonths.forEach((month, index) => {
      const monthTotals = data.reduce(
        (acc, customer) => {
          const monthData = customer.monthlyCargoData[index];
          return {
            volume: acc.volume + (monthData?.volume || 0),
            profit: acc.profit + (monthData?.profit || 0),
          };
        },
        { volume: 0, profit: 0 },
      );

      totalsRow[`${month}_volume`] = monthTotals.volume;
      totalsRow[`${month}_profit`] = monthTotals.profit;
    });

    setTransformedData([...s_transformedData, totalsRow]);
  }, [data]);

  // Prepare data for Excel export
  const getExcelData = () => {
    return transformedData.map(row => {
      const excelRow: any = {
        Commodity: row.commodity,
      };

      allMonths.forEach(month => {
        excelRow[`${month} (Volume)`] = row[`${month}_volume`];
        excelRow[`${month} (Profit)`] = `£${row[`${month}_profit`]}`;
      });

      excelRow['Total Volume'] = row.totalVolume;
      excelRow['Total Profit'] = `£${row.totalProfit}`;

      return excelRow;
    });
  };

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth fullScreen maxWidth='xl'>
        <DialogTitle>
          <Box display='flex' alignItems='center' gap={1}>
            {bookingType} Monthly Commodity Volume And {profitType} Profit Report
            <Tooltip title='Download as Excel' placement='top'>
              <IconButton onClick={() => handleExcelDownload(getExcelData(), excelFileName)}>
                <DownloadOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent>
          <MonthlyReportCustomDataGrid rows={transformedData} columns={columns} />
          <GeneralDialogueActions noSubmission handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MonthlyCommodityVolumeAndProfitTable;
