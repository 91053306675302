import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { sec } from 'auth/accessToken';
import { Booking, BookingStatus } from 'models/booking.model';

const apiBaseUrl = process.env.REACT_APP_BASE_URL;

// Funtion to get auth headers
const getAuthHeaders = async (): Promise<{ Authorization: string }> => {
  const accessToken = await sec.getAccessTokenSilently()();
  return { Authorization: `Bearer ${accessToken}` };
};

// Centralised API utility with flexibility for custom configuration
export const apiCall = async <T = any,>(
  endpoint: string,
  { method = 'GET', data, params }: Partial<AxiosRequestConfig> = {},
): Promise<AxiosResponse | undefined> => {
  try {
    const headers = await getAuthHeaders();
    const response = await axios({
      url: `${apiBaseUrl}${endpoint}`,
      method,
      headers,
      data,
      params,
    });
    return response;
  } catch (error) {
    console.error(`Error with API call [${method} ${endpoint}]:`, error);
  }
};

export const duplicateBooking = async (bookingId: string): Promise<string | undefined> => {
  const response = await apiCall<{ id: string }>(`/api/v1/bookings/${bookingId}`, { method: 'POST' });
  return response?.data?.id;
};

export const deleteBooking = async (bookingId: string, bookingNumber: string): Promise<void> => {
  await apiCall(`/api/v1/bookings/${bookingId}`, {
    method: 'DELETE',
    params: { bookingNumber },
  });
};

export const completeBooking = async (bookingId: string, bookingIncome: Booking['bookingIncome']): Promise<void> => {
  await apiCall(`/api/v1/bookings/${bookingId}`, {
    method: 'PATCH',
    data: {
      bookingStatus: BookingStatus.Completed,
      bookingIncome,
    },
  });
};

export const reopenBooking = async (bookingId: string): Promise<void> => {
  await apiCall(`/api/v1/bookings/${bookingId}`, {
    method: 'PATCH',
    data: {
      bookingStatus: BookingStatus.Ongoing,
    },
  });
};

export const cancelBooking = async (bookingId: string): Promise<void> => {
  await apiCall(`/api/v1/bookings/${bookingId}`, {
    method: 'PATCH',
    data: {
      bookingStatus: 'Cancelled',
    },
  });
};

export const saveUserPreferences = async (userEmail: string, data: any, type: string) => {
  try {
    const { bookingsColumnVisibilityModel, bookingsFilters, enquiriesFilters, enquiriesColumnVisibilityModel } = data;
    const updatePreferenceResponse = await apiCall(`/api/v1/userPreferences/${userEmail}`, {
      method: 'PATCH',
      data:
        type === 'bookings'
          ? {
              bookingsColumnVisibilityModel,
              bookingsFilters,
            }
          : {
              enquiriesFilters,
              enquiriesColumnVisibilityModel,
            },
    });

    if (updatePreferenceResponse?.data?.message === 'User preference does not exist') {
      const postPreferenceResponse = await apiCall(`/api/v1/userPreferences`, {
        method: 'POST',
        data:
          type === 'bookings'
            ? {
                email: userEmail,
                bookingsColumnVisibilityModel,
                bookingsFilters,
              }
            : {
                email: userEmail,
                enquiriesFilters,
                enquiriesColumnVisibilityModel,
              },
      });

      if (postPreferenceResponse?.status === 201) {
        return 'success';
      }
      return 'error';
    }

    if (updatePreferenceResponse?.status === 200) {
      return 'success';
    }
    return 'error';
  } catch (error) {
    console.error('Error updating user preferences:', error);
  }
};
