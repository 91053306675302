import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Svg, Rect, G, Line } from '@react-pdf/renderer';
import { AdditionalCharge, Booking, DepotSite, TranshipmentDetail, TransportSchedule } from 'models/booking.model';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikRegular from '../../assets/fonts/Rubik-Regular.ttf';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikMedium from '../../assets/fonts/Rubik-Medium.ttf';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikSemiBold from '../../assets/fonts/Rubik-SemiBold.ttf';
// @ts-expect-error: TODO: Fix typing issue
import FontRubikBold from '../../assets/fonts/Rubik-Bold.ttf';
import dayjs from 'dayjs';
import { currencySymbols } from 'dataAssets/constants';
import { extractInvoiceType, formatDeadline, totalInvoiceValue } from 'utils/utils';
import { BLHistoryTableProps } from 'components/BLHistoryTable/BLHistoryTable';
import { ProfitDisplayProps } from 'components/ProfitDisplay/ProfitDisplay';

Font.register({
  family: 'Rubik',
  fonts: [
    { src: FontRubikRegular },
    { src: FontRubikMedium, fontWeight: 'medium' },
    { src: FontRubikSemiBold, fontWeight: 'semibold' },
    { src: FontRubikBold, fontWeight: 'bold' },
  ],
});

const styles: { [key: string]: any } = StyleSheet.create({
  body: {
    paddingTop: 40,
    paddingBottom: 25,
    paddingHorizontal: 15,
    fontFamily: 'Rubik',
    fontSize: 7,
    position: 'relative',
  },
  sectionHeading: {
    fontSize: 7,
    marginVertical: 6,
  },
  watermark: {
    position: 'absolute',
    transform: 'translate(-100%, 100%)',
    opacity: 0.2,
    fontSize: 30,
    fontWeight: 'bold',
    color: 'gray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '800px',
  },
  watermarkText: {
    transform: 'rotate(-45deg)',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  fixed: {
    bottom: -15,
    left: 0,
    right: 0,
    paddingHorizontal: 15,
    position: 'absolute',
  },
  topFixed: {
    top: 10,
    left: 0,
    right: 0,
    paddingHorizontal: 15,
    position: 'absolute',
  },
  page: {
    position: 'relative',
    minHeight: '300px',
    overflow: 'hidden',
  },
  tableContainer: {
    position: 'absolute',
    top: 603,
    left: 0,
    right: 0,
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
  },
  column: {
    flex: 1,
    borderRight: '1px solid black',
    textAlign: 'center',
    fontSize: 8,
    paddingVertical: 4,
    paddingHorizontal: 2,
  },
  title: {
    fontSize: 7,
    marginVertical: 2,
    textAlign: 'center',
    paddingLeft: 3,
  },
  cell: {
    textTransform: 'uppercase',
    paddingVertical: 5,
    paddingHorizontal: 3,
    textAlign: 'center',
    marginBottom: 2,
  },
  cargoDetailsContainer: {
    width: '100%',
  },
  clauseContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
  },
  clauseColumn: {
    width: '50%',
    paddingHorizontal: 10,
    paddingVertical: 2,
  },
  clauseText: {
    marginBottom: 4,
  },
  pageNumbers: {
    position: 'absolute',
    right: 10,
    bottom: 0,
    textAlign: 'center',
    fontSize: 8,
  },
  table: {
    width: '100%',
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 0,
  },
  tableHeader: {
    backgroundColor: '#f0f0f0',
  },
  tableHeaderCell: {
    padding: 5,
    fontWeight: 'bold',
  },
  tableCell: {
    padding: 5,
  },
  dividerContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
  },
  dividerLine: {
    flex: 1,
    height: 1,
    backgroundColor: '#e0e0e0',
  },
  dividerText: {
    paddingHorizontal: 10,
    fontSize: 8,
  },
  col1: { width: '40%' },
  col2: { width: '20%' },
  col3: { width: '20%' },
  col4: { width: '20%' },
  col5: { width: '20%' },
  col6: { width: '30%' },
  col7: { width: '30%' },
  col8: { width: '10%' },
  emptyState: {
    padding: 10,
    textAlign: 'center',
    color: '#999',
    width: '100%',
  },
  container: {
    margin: '10 0',
    padding: '0 10',
  },
  profitRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  positiveProfit: {
    color: 'green',
  },
  negativeProfit: {
    color: 'red',
  },
  normalText: {
    fontSize: 10,
  },
  arrow: {
    marginLeft: 3,
    fontSize: 10,
  },
  warningText: {
    color: '#f59e0b', // amber-400 equivalent
    fontSize: 10,
    textAlign: 'center',
  },
  errorText: {
    color: 'red',
    fontSize: 10,
    textAlign: 'center',
  },
  labelText: {
    fontSize: 12,
    marginBottom: 4,
  },
  weakProfitLabel: {
    color: '#f59e0b', // amber-400 equivalent
  },
  transportScheduleHeading: {
    fontSize: 16,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  transportSectionHeading: {
    fontSize: 14,
    marginTop: 15,
    marginBottom: 5,
    fontWeight: 'bold',
  },
  depotSeparator: {
    borderBottomWidth: 1,
    borderBottomColor: '#cccccc',
    marginTop: 20,
    marginBottom: 10,
    position: 'relative',
    height: 20,
  },
  depotSeparatorText: {
    position: 'absolute',
    top: 5,
    left: '40%',
    right: '40%',
    backgroundColor: '#ffffff',
    textAlign: 'center',
    paddingHorizontal: 10,
    fontWeight: 'bold',
  },
  transportTable: {
    display: 'flex',
    width: 'auto',
  },
  transportTableRow: {
    flexDirection: 'row',
  },
  transportTableHeader: {
    backgroundColor: '#f0f0f0',
    padding: 5,
    fontWeight: 'bold',
  },
  transportTableCell: {
    padding: 5,
  },
  // Table column width styles with directly relatable names
  siteColumnWidth: { width: '15%' },
  dateColumnWidth: { width: '12%' },
  timeColumnWidth: { width: '10%' },
  referenceColumnWidth: { width: '12%' },
  containerNumberColumnWidth: { width: '15%' },
  sealNumberColumnWidth: { width: '10%' },
  weightColumnWidth: { width: '10%' },
  haulerColumnWidth: { width: '15%' },
  remarksColumnWidth: { width: '15%' },
  statusColumnWidth: { width: '10%' },

  depotPointWrapper: {
    marginVertical: 5,
    paddingVertical: 5,
  },
  depotPointLabel: {
    fontSize: 8,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#444444',
  },
  depotPointAddress: {
    fontSize: 7,
    marginBottom: 5,
  },
  deadlineTypeCell: {
    width: '25%',
  },
  deadlineDateCell: {
    width: '25%',
  },
  deadlineTimeCell: {
    width: '25%',
  },
  statusCell: {
    width: '25%',
  },
  financialRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 5,
    width: '100%',
    borderBottom: '1px solid black',
  },
  divider: {
    height: '100%',
    width: 1,
    backgroundColor: '#000',
    marginHorizontal: 2,
  },
});

const cyColumnWidths: { [key: string]: any } = StyleSheet.create({
  containerNumberColumn: { width: '20%' },
  sealNumberColumn: { width: '15%' },
  weightColumn: { width: '10%' },
  haulerColumn: { width: '20%' },
  remarksColumn: { width: '20%' },
  statusColumn: { width: '15%' },
});

export interface BookingOverviewDocumentProps {
  booking: Booking | undefined;
  expectedProfit: any;
  currentBookingIncome: Booking['bookingIncome'];
  includeAccountingDetails: Boolean;
}

interface LabeledValueProps {
  label: string;
  value?: string | number;
  styles?: { [key: string]: any };
}

interface DetailsHeaderProps {
  value: string;
}

interface LabeledValueContainerProps {
  children: React.ReactNode;
  style?: any;
  gap?: number;
  columns?: number; // Optional if you want to support column-based grids
}

interface AccountingTableProps {
  booking: Booking | undefined;
  expectedProfit: number;
  currentBookingIncome: Booking['bookingIncome'];
}

// Define props for DividerWithText component
interface DividerProps {
  children: React.ReactNode;
}

interface TransportScheduleTableProps {
  transportScheduleArray: TransportSchedule[];
  loadingTypeExport: 'SD' | 'CY';
  pickupDepot?: DepotSite[];
  dropoffDepot?: DepotSite[];
}

interface DeadlineGridProps {
  booking?: Booking;
}

type DeadlineType = 'GateIn' | 'SI' | 'VGM' | 'UCR';

interface FinancialSummaryProps {
  booking: Booking;
  currentBookingIncome?: {
    revenue?: number;
    cost?: number;
    profit?: number;
  };
  currencySymbol?: string;
}

interface TranshipmentDetailsTableProps {
  transhipmentDetails: TranshipmentDetail[];
}

interface ExceededLimit {
  key: string;
  value: string;
}

interface TruncatedListProps {
  exceededLimitsValues: ExceededLimit[];
}

const checkAndTruncate = (val: string, limit: number): string => {
  if (val && val.length > limit) {
    return `${val.substring(0, limit)}...`;
  }
  return val;
};

const LabeledValue: React.FC<LabeledValueProps> = ({ label, value, styles = {} }) => {
  return (
    <View style={{ ...styles, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
      <Text style={{ fontWeight: 'bold', letterSpacing: 0.5 }}>{label}: </Text>
      <Text
        style={{
          textTransform: 'uppercase',
          fontSize: 7.5,
          letterSpacing: 0.3,
        }}
      >
        {value ? checkAndTruncate(value.toString(), 20) : '  -  '}
      </Text>
    </View>
  );
};

const DetailsHeader: React.FC<DetailsHeaderProps> = ({ value }) => {
  return (
    <View
      style={{
        marginTop: 5,
      }}
    >
      <Text
        style={{
          textTransform: 'uppercase',
          fontSize: 8.5,
          marginVertical: 5,
          fontWeight: 'bold',
        }}
      >
        {value}
      </Text>
    </View>
  );
};

const LabeledValueContainer: React.FC<LabeledValueContainerProps> = ({ children, style, gap = 8, columns = 3 }) => {
  const childArray = React.Children.toArray(children);

  return (
    <View
      style={[
        {
          marginTop: 10,
          marginBottom: 2,
          flexDirection: 'row',
          flexWrap: 'wrap',
        },
        style,
      ]}
    >
      {childArray.map((child, index) => {
        const isLastColumn = (index + 1) % columns === 0;

        return (
          <View
            key={index}
            style={{
              width: `${100 / columns}%`,
              paddingRight: isLastColumn ? 0 : gap / 2,
              paddingLeft: index % columns === 0 ? 0 : gap / 2,
              marginBottom: gap,
            }}
          >
            {child}
          </View>
        );
      })}
    </View>
  );
};

const DividerWithText: React.FC<DividerProps> = ({ children }) => (
  <View style={styles.dividerContainer}>
    <View style={styles.dividerLine} />
    <Text style={styles.dividerText}>{children}</Text>
    <View style={styles.dividerLine} />
  </View>
);

const HorizontalDivider = ({
  color = '#000000',
  width = '100%',
  height = 1,
  marginTop = 10,
  marginBottom = 10,
  style = {},
}) => (
  <View
    style={[
      styles.divider,
      {
        backgroundColor: color,
        width: width,
        height: height,
        marginTop: marginTop,
        marginBottom: marginBottom,
      },
      style,
    ]}
  />
);

const EmptyState: React.FC<{ message: string }> = ({ message }) => (
  <View style={styles.tableRow}>
    <Text style={styles.emptyState}>{message}</Text>
  </View>
);

const AccountingTable: React.FC<AccountingTableProps> = ({ booking, expectedProfit, currentBookingIncome }) => {
  const hasBuyRates = booking?.buyRates && booking.buyRates.length > 0;
  const hasSellRates = booking?.sellRates && booking.sellRates.length > 0;
  const hasAdditionalBuyRates = booking?.additionalBuyRates && booking.additionalBuyRates.length > 0;
  const hasAdditionalSellRates = booking?.additionalSellRates && booking.additionalSellRates.length > 0;
  const hasSalesInvoices = booking?.salesInvoices && booking.salesInvoices.length > 0;
  const hasCreditNotes = booking?.creditNotes && booking.creditNotes.length > 0;
  const hasPurchaseInvoices = booking?.purchaseInvoices && booking.purchaseInvoices.length > 0;
  const hasDebitNotes = booking?.debitNotes && booking.debitNotes.length > 0;

  const hasRegularRates = hasBuyRates || hasSellRates;
  const hasAdditionalRates = hasAdditionalBuyRates || hasAdditionalSellRates;
  const hasInvoices = hasSalesInvoices || hasCreditNotes || hasPurchaseInvoices || hasDebitNotes;

  return (
    <>
      <DetailsHeader value='quotation details' />
      <View style={styles.table}>
        {/* Table Header */}
        <View style={[styles.tableRow, styles.tableHeader]}>
          <Text style={[styles.tableHeaderCell, styles.col1]}>Charge Name</Text>
          <Text style={[styles.tableHeaderCell, styles.col2]}>Currency</Text>
          <Text style={[styles.tableHeaderCell, styles.col3]}>Rate</Text>
          <Text style={[styles.tableHeaderCell, styles.col4]}>Base</Text>
        </View>

        {hasRegularRates ? (
          /* Table Body */
          (['Buy Rates', 'Sell Rates'] as const).map((ratesType, ratesIndex) => {
            const rates = ratesType === 'Buy Rates' ? booking?.buyRates : booking?.sellRates;
            const hasRates = rates && rates.length > 0;

            return (
              <View key={ratesIndex}>
                {/* DividerWithText Row */}
                <View style={styles.tableRow}>
                  <View style={{ width: '100%' }}>
                    <DividerWithText>{ratesType}</DividerWithText>
                  </View>
                </View>

                {hasRates ? (
                  /* Rate Rows */
                  rates?.map((charge, index) => (
                    <View style={styles.tableRow} key={index}>
                      <Text style={[styles.tableCell, styles.col1]}>
                        {`${
                          typeof charge.chargeName === 'string'
                            ? charge.chargeName
                            : charge.chargeName?.chargeName || 'Unknown Charge Name'
                        }${charge.additionalDetails ? ` - ${charge.additionalDetails}` : ''}`}
                      </Text>
                      <Text style={[styles.tableCell, styles.col2]}>{charge.currency}</Text>
                      <Text style={[styles.tableCell, styles.col3]}>{charge.rate}</Text>
                      <Text style={[styles.tableCell, styles.col4]}>{charge.base}</Text>
                    </View>
                  ))
                ) : (
                  <EmptyState message={`No ${ratesType.toLowerCase()} available`} />
                )}
              </View>
            );
          })
        ) : (
          <EmptyState message='No rates available' />
        )}
      </View>

      <DetailsHeader value='additional charges details' />
      <View style={styles.table}>
        {/* Table Header */}
        <View style={[styles.tableRow, styles.tableHeader]}>
          <Text style={[styles.tableHeaderCell, styles.col1]}>Charge Name</Text>
          <Text style={[styles.tableHeaderCell, styles.col2]}>Currency</Text>
          <Text style={[styles.tableHeaderCell, styles.col3]}>Rate</Text>
          <Text style={[styles.tableHeaderCell, styles.col4]}>Base</Text>
          <Text style={[styles.tableHeaderCell, styles.col5]}>Raised</Text>
        </View>

        {hasAdditionalRates ? (
          /* Table Body */
          (['Additional Buy Rates', 'Additional Sell Rates'] as const).map((ratesType, ratesIndex) => {
            const rates =
              ratesType === 'Additional Buy Rates' ? booking?.additionalBuyRates : booking?.additionalSellRates;
            const hasRates = rates && rates.length > 0;

            return (
              <View key={ratesIndex}>
                {/* DividerWithText Row */}
                <View style={styles.tableRow}>
                  <View style={{ width: '100%' }}>
                    <DividerWithText>{ratesType}</DividerWithText>
                  </View>
                </View>

                {hasRates ? (
                  /* Rate Rows */
                  rates?.map((charge, index) => {
                    const additionalCharge = charge as AdditionalCharge;
                    return (
                      <View style={styles.tableRow} key={index}>
                        <Text style={[styles.tableCell, styles.col1]}>
                          {`${
                            typeof additionalCharge.chargeName === 'string'
                              ? additionalCharge.chargeName
                              : additionalCharge.chargeName?.chargeName || 'Unknown Charge Name'
                          }${additionalCharge.additionalDetails ? ` - ${additionalCharge.additionalDetails}` : ''}`}
                        </Text>
                        <Text style={[styles.tableCell, styles.col2]}>{additionalCharge.currency}</Text>
                        <Text style={[styles.tableCell, styles.col3]}>{additionalCharge.rate}</Text>
                        <Text style={[styles.tableCell, styles.col4]}>{additionalCharge.base}</Text>
                        <Text style={[styles.tableCell, styles.col5]}>{additionalCharge.raised ? 'Yes' : 'No'}</Text>
                      </View>
                    );
                  })
                ) : (
                  <EmptyState message={`No ${ratesType.toLowerCase()} available`} />
                )}
              </View>
            );
          })
        ) : (
          <EmptyState message='No additional charges available' />
        )}
      </View>

      <ProfitElement expectedProfit={expectedProfit} booking={booking} />

      <DetailsHeader value='invoice details' />
      <View style={styles.table}>
        {/* Table Header */}
        <View style={[styles.tableRow, styles.tableHeader]}>
          <Text style={[styles.tableHeaderCell, styles.col1]}>Invoice No</Text>
          <Text style={{ ...styles.tableHeaderCell, ...styles.col3, textAlign: 'center' }}>Invoice Party</Text>
          <Text style={{ ...styles.tableHeaderCell, ...styles.col3, textAlign: 'center' }}>PI No</Text>
          <Text style={{ ...styles.tableHeaderCell, ...styles.col3, textAlign: 'center' }}>Creation Date</Text>
          <Text style={{ ...styles.tableHeaderCell, ...styles.col3, textAlign: 'center' }}>Invoice Date</Text>
          <Text style={{ ...styles.tableHeaderCell, ...styles.col3, textAlign: 'center' }}>Due Date</Text>
          <Text style={{ ...styles.tableHeaderCell, ...styles.col3, textAlign: 'center' }}>Dispute</Text>
          <Text style={{ ...styles.tableHeaderCell, ...styles.col3, textAlign: 'center' }}>Approved</Text>
          <Text style={{ ...styles.tableHeaderCell, ...styles.col8, textAlign: 'center' }}>Settled</Text>
          <Text style={{ ...styles.tableHeaderCell, ...styles.col1, textAlign: 'center' }}>Total</Text>
        </View>

        {hasInvoices ? (
          /* Table Body */
          <>
            {(['Credit Notes', 'Purchase Invoice', 'Sales Invoice', 'Debit Notes'] as const).map(
              (invoiceType, invoiceIndex) => {
                const invoices =
                  invoiceType === 'Sales Invoice'
                    ? booking?.salesInvoices
                    : invoiceType === 'Credit Notes'
                      ? booking?.creditNotes
                      : invoiceType === 'Purchase Invoice'
                        ? booking?.purchaseInvoices
                        : booking?.debitNotes;

                const hasInvoices = invoices && invoices.length > 0;

                return (
                  <View key={invoiceIndex}>
                    {/* DividerWithText Row */}
                    <View style={styles.tableRow}>
                      <View style={{ width: '100%' }}>
                        <DividerWithText>{invoiceType}</DividerWithText>
                      </View>
                    </View>

                    {hasInvoices ? (
                      /* Invoice Rows */
                      invoices?.map((invoice, index) => (
                        <View style={styles.tableRow} key={index}>
                          <Text style={[styles.tableCell, styles.col1]}>
                            {invoice.approvedInvoiceNumber ?? invoice.performaInvoiceNumber ?? 'N/A'}
                          </Text>
                          <Text style={{ ...styles.tableCell, ...styles.col3, textAlign: 'center' }}>
                            {invoice.vendorSnapshot?.name ?? invoice.customerSnapshot?.name ?? 'N/A'}
                          </Text>
                          <Text style={{ ...styles.tableCell, ...styles.col3, textAlign: 'center' }}>
                            {invoice.vendorInvoiceNumber ?? 'N/A'}
                          </Text>
                          <Text style={{ ...styles.tableCell, ...styles.col3, textAlign: 'center' }}>
                            {dayjs(invoice.invoiceDate).format('DD/MM/YY')}
                          </Text>
                          <Text style={{ ...styles.tableCell, ...styles.col3, textAlign: 'center' }}>
                            {extractInvoiceType(invoice.performaInvoiceNumber) === 'PI'
                              ? dayjs(invoice.vendorInvoiceDate).format('DD/MM/YY')
                              : invoice.approvalDate
                                ? dayjs(invoice.approvalDate).format('DD/MM/YY')
                                : 'TBD'}
                          </Text>
                          <Text style={{ ...styles.tableCell, ...styles.col3, textAlign: 'center' }}>
                            {['DN', 'CN'].some(invoiceType => invoice.performaInvoiceNumber.includes(invoiceType))
                              ? 'N/A'
                              : invoice.vendorInvoiceDate
                                ? dayjs(invoice.vendorInvoiceDate)
                                    .add(invoice.vendorSnapshot?.creditPeriod ?? 0, 'day')
                                    .format('DD/MM/YY')
                                : invoice.approvalDate
                                  ? dayjs(invoice.approvalDate)
                                      .add(
                                        invoice.customerSnapshot?.creditPeriod ??
                                          invoice.vendorSnapshot?.creditPeriod ??
                                          0,
                                        'day',
                                      )
                                      .format('DD/MM/YY')
                                  : 'TBD'}
                          </Text>
                          <Text style={{ ...styles.tableCell, ...styles.col3, textAlign: 'center' }}>
                            {invoice.disputeDetails?.underDispute ? 'Yes' : 'No'}
                          </Text>
                          <Text style={{ ...styles.tableCell, ...styles.col3, textAlign: 'center' }}>
                            {invoice.approvalPending ? 'No' : 'Yes'}
                          </Text>
                          <Text style={{ ...styles.tableCell, ...styles.col8, textAlign: 'center' }}>
                            {invoice.invoiceOutstanding ? 'No' : 'Yes'}
                          </Text>
                          <Text style={{ ...styles.tableCell, ...styles.col1, textAlign: 'center' }}>
                            {`${currencySymbols[invoice.invoiceCurrency] || invoice.invoiceCurrency} ${totalInvoiceValue(
                              invoice.charges,
                              invoice.numberOfContainers,
                            )}`}
                          </Text>
                        </View>
                      ))
                    ) : (
                      <EmptyState message={`No ${invoiceType.toLowerCase()} available`} />
                    )}
                  </View>
                );
              },
            )}
            <FinancialSummary
              booking={booking}
              currentBookingIncome={currentBookingIncome}
              currencySymbol={currencySymbols[booking?.bookingCurrency]}
            />
          </>
        ) : (
          <EmptyState message='No invoices available' />
        )}
      </View>
    </>
  );
};

const BLHistoryTable: React.FC<BLHistoryTableProps> = ({ blHistoryArray, blRemarks }) => {
  const hasBLHistory = blHistoryArray && blHistoryArray.length > 0;

  return (
    <>
      <DetailsHeader value='bl history' />
      <View style={styles.table}>
        {/* Table Header */}
        <View style={[styles.tableRow, styles.tableHeader]}>
          <Text style={[styles.tableHeaderCell, styles.col1]}>Status</Text>
          <Text style={[styles.tableHeaderCell, styles.col2]}>Status Date</Text>
          <Text style={[styles.tableHeaderCell, styles.col3]}>Follow Up</Text>
          <Text style={[styles.tableHeaderCell, styles.col4]}>Follow Up Date</Text>
        </View>

        {hasBLHistory ? (
          blHistoryArray?.map((blStatus, index) => (
            <View style={styles.tableRow} key={index}>
              <Text style={[styles.tableCell, styles.col1]}>{blStatus?.status ?? 'N/A'}</Text>
              <Text style={[styles.tableCell, styles.col2]}>
                {blStatus.statusDate ? dayjs(blStatus.statusDate).format('DD/MM/YYYY') : 'N/A'}
              </Text>
              <Text style={[styles.tableCell, styles.col3]}>{blStatus.followUp ?? 'N/A'}</Text>
              <Text style={[styles.tableCell, styles.col4]}>
                {blStatus.followUpDate ? dayjs(blStatus.followUpDate).format('DD/MM/YYYY') : 'N/A'}
              </Text>
            </View>
          ))
        ) : (
          <EmptyState message='No bl history available' />
        )}

        <LabeledValue label='Remarks' value={blRemarks} />
      </View>
    </>
  );
};

export const ProfitDisplay: React.FC<ProfitDisplayProps> = ({
  expectedProfit,
  bookingCurrency,
  numberOfContainers,
}) => {
  const profitStyle =
    expectedProfit !== undefined
      ? expectedProfit > 0
        ? expectedProfit / numberOfContainers < 25
          ? styles.warningText
          : styles.positiveProfit
        : styles.negativeProfit
      : {};

  return (
    <View style={styles.container}>
      <View style={styles.profitRow}>
        <Text style={[styles.normalText, profitStyle]}>
          {`Expected Profit: ${
            expectedProfit !== undefined
              ? `${currencySymbols[bookingCurrency]}${expectedProfit.toFixed(2)}`
              : 'Calculating...'
          }`}
        </Text>
        <Text style={[styles.normalText, profitStyle]}>
          {` ( ${currencySymbols[bookingCurrency]}${
            expectedProfit !== undefined ? (expectedProfit / numberOfContainers).toFixed(2) : 'Calculating...'
          } / CN )`}
        </Text>
      </View>
    </View>
  );
};

// Profit Element component
export const ProfitElement: React.FC<Omit<AccountingTableProps, 'currentBookingIncome'>> = ({
  expectedProfit,
  booking,
}) => {
  const profitPerContainer = booking ? expectedProfit / booking.numberOfContainers : 0;
  const weakProfit = profitPerContainer > 0 && profitPerContainer < 25;
  const isNegativeProfit = profitPerContainer < 0;

  return (
    <View>
      <Text style={[styles.labelText, weakProfit ? styles.weakProfitLabel : {}]}>{weakProfit}</Text>

      {booking && booking.bookingCurrency && booking.numberOfContainers && (
        <ProfitDisplay
          expectedProfit={expectedProfit}
          bookingCurrency={booking.bookingCurrency}
          numberOfContainers={booking.numberOfContainers}
        />
      )}

      {isNegativeProfit && <Text style={styles.errorText}>Expected Profit Critically Low</Text>}

      {!isNegativeProfit && weakProfit && <Text style={styles.warningText}>Expected Profit Too Low</Text>}
    </View>
  );
};

const TransportScheduleTable: React.FC<TransportScheduleTableProps> = ({
  transportScheduleArray,
  loadingTypeExport,
  pickupDepot = [],
  dropoffDepot = [],
}) => {
  // Get appropriate column styles based on loadingTypeExport
  const getColumnWidth = (columnType: string) => {
    const baseColumnName = `${columnType}ColumnWidth`;
    if (loadingTypeExport === 'CY') {
      return {
        ...styles[baseColumnName],
        ...(cyColumnWidths[baseColumnName] || {}),
      };
    }
    return styles[baseColumnName];
  };

  return (
    <>
      <DetailsHeader value='Transport Schedule' />;
      <View style={styles.transportTable}>
        {/* Table Header */}
        <View style={styles.transportTableRow}>
          {loadingTypeExport === 'SD' && (
            <>
              <View style={{ ...styles.transportTableHeader, ...styles.siteColumnWidth }}>
                <Text>Site</Text>
              </View>
              <View style={{ ...styles.transportTableHeader, ...styles.dateColumnWidth }}>
                <Text>Date</Text>
              </View>
              <View style={{ ...styles.transportTableHeader, ...styles.timeColumnWidth }}>
                <Text>Time</Text>
              </View>
              <View style={{ ...styles.transportTableHeader, ...styles.referenceColumnWidth }}>
                <Text>Reference</Text>
              </View>
            </>
          )}
          <View style={{ ...styles.transportTableHeader, ...getColumnWidth('containerNumber') }}>
            <Text>Container Number</Text>
          </View>
          <View style={{ ...styles.transportTableHeader, ...getColumnWidth('sealNumber') }}>
            <Text>Seal Number</Text>
          </View>
          <View style={{ ...styles.transportTableHeader, ...getColumnWidth('weight') }}>
            <Text>Weight (kg)</Text>
          </View>
          <View style={{ ...styles.transportTableHeader, ...getColumnWidth('hauler') }}>
            <Text>Hauler</Text>
          </View>
          <View style={{ ...styles.transportTableHeader, ...getColumnWidth('remarks') }}>
            <Text>Remarks</Text>
          </View>
          <View style={{ ...styles.transportTableHeader, ...getColumnWidth('status') }}>
            <Text>Status</Text>
          </View>
        </View>

        {/* Table Rows */}
        {transportScheduleArray.map((schedule, index) => (
          <View key={index} style={styles.transportTableRow}>
            {loadingTypeExport === 'SD' && (
              <>
                <View style={{ ...styles.transportTableCell, ...styles.siteColumnWidth }}>
                  <Text>{schedule.site || 'N/A'}</Text>
                </View>
                <View style={{ ...styles.transportTableCell, ...styles.dateColumnWidth }}>
                  <Text>{schedule.date ? dayjs(schedule.date).format('DD-MM-YY') : 'N/A'}</Text>
                </View>
                <View style={{ ...styles.transportTableCell, ...styles.timeColumnWidth }}>
                  <Text>{schedule.time ? dayjs(schedule.time).format('HH:mm') : 'N/A'}</Text>
                </View>
                <View style={{ ...styles.transportTableCell, ...styles.referenceColumnWidth }}>
                  <Text>{schedule.reference || 'N/A'}</Text>
                </View>
              </>
            )}
            <View style={{ ...styles.transportTableCell, ...getColumnWidth('containerNumber') }}>
              <Text>{schedule.containerNumber || 'N/A'}</Text>
            </View>
            <View style={{ ...styles.transportTableCell, ...getColumnWidth('sealNumber') }}>
              <Text>{schedule.sealNumber || 'N/A'}</Text>
            </View>
            <View style={{ ...styles.transportTableCell, ...getColumnWidth('weight') }}>
              <Text>{schedule.weight || 'N/A'}</Text>
            </View>
            <View style={{ ...styles.transportTableCell, ...getColumnWidth('hauler') }}>
              <Text>{schedule.hauler || 'N/A'}</Text>
            </View>
            <View style={{ ...styles.transportTableCell, ...getColumnWidth('remarks') }}>
              <Text>{schedule.remarks || 'N/A'}</Text>
            </View>
            <View style={{ ...styles.transportTableCell, ...getColumnWidth('status') }}>
              <Text>{schedule.status || 'N/A'}</Text>
            </View>
          </View>
        ))}
      </View>
      {/* Depot Points Section */}
      <DetailsHeader value='Depot Points' />;{/* Pickup Depot Points */}
      {pickupDepot && pickupDepot.length > 0 ? (
        pickupDepot.map((depot, index) => (
          <View key={`pickup-${index}`} style={styles.depotPointWrapper}>
            <Text style={styles.depotPointLabel}>{`Pickup Depot Point ${index + 1} :`}</Text>
            <Text style={styles.depotPointAddress}>
              {!depot || !depot.site
                ? `Site information is missing`
                : `${depot.site.siteName || 'N/A'}, ${depot.site.address || 'N/A'}, ${
                    depot.site.city || 'N/A'
                  }, ${depot.site.postcode || 'N/A'} : ${
                    depot.containers || 'N/A'
                  } CNs -> Reference: ${depot.reference || 'N/A'}`}
            </Text>
          </View>
        ))
      ) : (
        <EmptyState message='No pickup depot points available' />
      )}
      {/* Dropoff Depot Points */}
      {dropoffDepot && dropoffDepot.length > 0 ? (
        dropoffDepot.map((depot, index) => (
          <View key={`dropoff-${index}`} style={styles.depotPointWrapper}>
            <Text style={styles.depotPointLabel}>{`Dropoff Depot Point ${index + 1} :`}</Text>
            <Text style={styles.depotPointAddress}>
              {!depot || !depot.site
                ? `Site information is missing`
                : `${depot.site.siteName || 'N/A'}, ${depot.site.address || 'N/A'}, ${
                    depot.site.city || 'N/A'
                  }, ${depot.site.postcode || 'N/A'} : ${
                    depot.containers || 'N/A'
                  } CNs -> Reference: ${depot.reference || 'N/A'}`}
            </Text>
          </View>
        ))
      ) : (
        <EmptyState message='No dropoff depot points available' />
      )}
    </>
  );
};

const extractDeadlineTime = (deadlineDate: string): string => {
  return dayjs(deadlineDate).format('HH:mm');
};

const DeadlineGrid: React.FC<DeadlineGridProps> = ({ booking }) => {
  const deadlineTypes: DeadlineType[] = ['GateIn', 'SI', 'VGM', 'UCR'];

  const validDeadlines = deadlineTypes.filter(deadline => booking?.deadlines[deadline] !== 'N/A');

  return (
    <>
      <DetailsHeader value='deadlines' />;
      <View style={styles.table}>
        {/* Table Header */}
        <View style={[styles.tableRow, styles.tableHeader]}>
          <View style={[styles.tableCell, styles.deadlineTypeCell]}>
            <Text style={{ ...styles.headerText, fontWeight: 'bold' }}>Deadline Type</Text>
          </View>
          <View style={[styles.tableCell, styles.deadlineDateCell]}>
            <Text style={{ ...styles.headerText, fontWeight: 'bold' }}>Date</Text>
          </View>
          <View style={[styles.tableCell, styles.deadlineTimeCell]}>
            <Text style={{ ...styles.headerText, fontWeight: 'bold' }}>Time</Text>
          </View>
          <View style={[styles.tableCell, styles.statusCell]}>
            <Text style={{ ...styles.headerText, fontWeight: 'bold' }}>Status</Text>
          </View>
        </View>

        {/* Table Rows */}
        {validDeadlines.length > 0 ? (
          validDeadlines.map(deadline => (
            <View key={deadline} style={styles.tableRow}>
              <View style={[styles.tableCell, styles.deadlineTypeCell]}>
                <Text style={styles.cellText}>{deadline}</Text>
              </View>
              <View style={[styles.tableCell, styles.deadlineDateCell]}>
                <Text style={styles.cellText}>{dayjs(booking?.deadlines[deadline]).format('DD-MM-YY')}</Text>
              </View>
              <View style={[styles.tableCell, styles.deadlineTimeCell]}>
                <Text style={styles.cellText}>
                  {booking?.deadlines[deadline] ? extractDeadlineTime(booking.deadlines[deadline]) : 'N/A'}
                </Text>
              </View>
              <View style={[styles.tableCell, styles.statusCell]}>
                <Text style={styles.cellText}>
                  {dayjs(booking?.deadlines[deadline]).isAfter(dayjs()) ? 'Upcoming' : 'Passed'}
                </Text>
              </View>
            </View>
          ))
        ) : (
          <EmptyState message='Deadlines data not available' />
        )}
      </View>
    </>
  );
};

const FinancialSummary: React.FC<FinancialSummaryProps> = ({ booking, currentBookingIncome, currencySymbol = '£' }) => {
  const profit = booking.bookingIncome?.profit ?? currentBookingIncome?.profit ?? 0;
  const numberOfContainers = booking.numberOfContainers ?? 1;
  const profitPerContainer = profit / numberOfContainers;

  const isProfitCritical = profit < 0;
  const isProfitLow = profit > 0 && profitPerContainer < 25;

  const getProfitStyle = () => {
    if (isProfitCritical) return styles.negativeProfit;
    if (isProfitLow) return styles.warningText;
    return styles.positiveProfit;
  };

  return (
    <View style={{ borderTop: '1px solid black' }}>
      <View style={styles.financialRow}>
        <LabeledValue
          label='Revenue'
          value={`${currencySymbol}${booking.bookingIncome?.revenue ?? currentBookingIncome?.revenue?.toFixed(2) ?? 0}`}
          styles={{ marginVertical: 3 }}
        />
        <View style={styles.divider} />
        <LabeledValue
          label='Cost'
          value={`${currencySymbol}${booking.bookingIncome?.cost ?? currentBookingIncome?.cost?.toFixed(2) ?? 0}`}
          styles={{ marginVertical: 3 }}
        />
      </View>

      {profit !== 0 && (
        <View style={{ marginTop: 4 }}>
          <View style={{ ...styles.profitRow, marginBottom: 4 }}>
            <Text
              style={[styles.normalText, getProfitStyle()]}
            >{`Actual Profit: ${currencySymbol}${profit}( ${currencySymbol}${profitPerContainer.toFixed(2)} / CN )`}</Text>
          </View>

          {isProfitCritical && <Text style={styles.errorText}>Actual Profit Critically Low</Text>}

          {isProfitLow && !isProfitCritical && <Text style={styles.warningText}>Actual Profit Too Low</Text>}
        </View>
      )}
    </View>
  );
};

const TranshipmentDetailsTable: React.FC<TranshipmentDetailsTableProps> = ({ transhipmentDetails }) => {
  const hasTranshipmentDetails = transhipmentDetails && transhipmentDetails.length > 0;
  const sortedDetails = [...transhipmentDetails].sort((a, b) => (a.sequenceNumber ?? 0) - (b.sequenceNumber ?? 0));

  return (
    <>
      <DetailsHeader value='transhipment details' />
      <View style={styles.table}>
        {/* Table Header */}
        <View style={[styles.tableRow, styles.tableHeader]}>
          <Text style={[styles.tableHeaderCell, styles.col7]}>ETD - ETA</Text>
          <Text style={[styles.tableHeaderCell, styles.col4]}>Vessel Name</Text>
          <Text style={[styles.tableHeaderCell, styles.col4]}>Voyage Number</Text>
          <Text style={[styles.tableHeaderCell, styles.col4]}>Vessel Country</Text>
          <Text style={[styles.tableHeaderCell, styles.col4]}>Port of Loading</Text>
          <Text style={[styles.tableHeaderCell, styles.col4]}>Port of Discharge</Text>
          <Text style={[styles.tableHeaderCell, styles.col4]}>Carrier</Text>
          <Text style={[styles.tableHeaderCell, styles.col4]}>Vessel Type</Text>
        </View>

        {hasTranshipmentDetails ? (
          sortedDetails?.map((step, index) => (
            <View style={styles.tableRow} key={index}>
              <Text style={[styles.tableCell, styles.col7]}>
                {formatDeadline(step.etd || 'N/A')} - {formatDeadline(step.eta || 'N/A')}
              </Text>
              <Text style={[styles.tableCell, styles.col4]}>{step.vesselVoyage.vesselName || 'N/A'}</Text>
              <Text style={[styles.tableCell, styles.col4]}>{step.vesselVoyage.voyageNumber || 'N/A'}</Text>
              <Text style={[styles.tableCell, styles.col4]}>{step.vesselVoyage.vesselCountry || 'N/A'}</Text>
              <Text style={[styles.tableCell, styles.col4]}>{step.transhipmentPortOfLoading?.portName || 'N/A'}</Text>
              <Text style={[styles.tableCell, styles.col4]}>{step.transhipmentPortOfDischarge?.portName || 'N/A'}</Text>
              <Text style={[styles.tableCell, styles.col4]}>{step.carrier?.name || 'N/A'}</Text>
              <Text style={[styles.tableCell, styles.col4]}>{step.vesselType || 'N/A'}</Text>
            </View>
          ))
        ) : (
          <EmptyState message='No transhipment details available' />
        )}
      </View>
    </>
  );
};

const TruncatedList: React.FC<TruncatedListProps> = ({ exceededLimitsValues }) => {
  return (
    <View style={{ width: '100%' }} break>
      <View style={{ borderBottom: '1px solid black' }}>
        <Text style={{ textAlign: 'center', fontSize: 10, fontWeight: 'bold', marginVertical: 4 }}>ANNEXURE</Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          borderBottomWidth: 1,
          borderColor: '#000',
        }}
      >
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            fontWeight: 'bold',
            marginVertical: 1,
          }}
        >
          Key
        </Text>
        <View
          style={{
            borderLeftWidth: 1,
            borderColor: '#000',
            marginHorizontal: 8,
          }}
        />
        <Text
          style={{
            flex: 1,
            textAlign: 'center',
            fontWeight: 'bold',
            marginVertical: 1,
          }}
        >
          Value
        </Text>
      </View>

      {exceededLimitsValues.map(exceededLimit => (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
          key={exceededLimit.key}
        >
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              marginVertical: 4,
            }}
          >
            {exceededLimit.key}
          </Text>
          <View
            style={{
              borderLeftWidth: 1,
              borderColor: '#000',
              marginHorizontal: 8,
            }}
          />
          <Text
            style={{
              flex: 1,
              textAlign: 'justify',
              marginVertical: 4,
            }}
          >
            {exceededLimit.value}
          </Text>
        </View>
      ))}
    </View>
  );
};

const ProfitLossBarGraph: React.FC<FinancialSummaryProps> = ({
  booking,
  currentBookingIncome,
  currencySymbol = '£',
}) => {
  const safeNumber = (value: number | string) => {
    const num = Number(value);
    return isNaN(num) ? 0 : num;
  };

  const data = [
    {
      category: 'Revenue',
      value: safeNumber(booking.bookingIncome?.revenue ?? currentBookingIncome?.revenue?.toFixed(2) ?? 0),
    },
    { category: 'Cost', value: safeNumber(booking.bookingIncome?.cost ?? currentBookingIncome?.cost?.toFixed(2) ?? 0) },
    { category: 'Profit', value: safeNumber(booking.bookingIncome?.profit ?? currentBookingIncome?.profit ?? 0) },
  ];

  const CHART_WIDTH = 600;
  const CHART_HEIGHT = 250;
  const PADDING = 50;
  const GRAPH_HEIGHT = CHART_HEIGHT - PADDING * 2;
  const GRAPH_WIDTH = CHART_WIDTH - PADDING * 2;
  const BAR_PADDING = 10;

  const maxValue = Math.max(0.01, ...data.map(d => d.value));

  const barWidth = GRAPH_WIDTH / data.length - BAR_PADDING;

  const bars = data.map((item, index) => {
    const barHeight = (item.value / maxValue) * GRAPH_HEIGHT;
    const x = PADDING + index * (barWidth + BAR_PADDING);
    const y = CHART_HEIGHT - PADDING - barHeight;
    const height = barHeight || 1;

    return {
      x,
      y,
      width: barWidth,
      height,
      value: item.value,
      category: item.category,
      isNegative: item.value < 0,
    };
  });

  const yAxis = Array.from({ length: 6 }).map((_, i) => {
    const value = maxValue * (i / 5);
    const y = CHART_HEIGHT - PADDING - (value / maxValue) * GRAPH_HEIGHT;

    const safeY = isNaN(y) ? CHART_HEIGHT - PADDING : y;
    return { safeY, text: `${currencySymbol} ${Math.round(value)}` };
  });

  if (!data.some(item => item.value !== 0)) {
    return (
      <View break>
        <View style={{ marginBottom: 20 }}>
          <DetailsHeader value='Profit / Loss Statement' />
          <Text style={{ color: '#666' }}>No data available. Values will be updated when available.</Text>
        </View>
      </View>
    );
  }

  return (
    <View break>
      <View style={{ marginBottom: 20 }}>
        <DetailsHeader value='Profit / Loss Statement' />
        <Text style={{ color: '#666' }}>
          These are temporary values based on current exchange rates. Final values will be updated according to exchange
          rates on the day of booking completion.
        </Text>
      </View>

      <View style={{ height: CHART_HEIGHT, width: CHART_WIDTH, marginBottom: 20 }}>
        <Svg height={CHART_HEIGHT} width={CHART_WIDTH}>
          {/* X and Y axes */}
          <Line
            x1={PADDING}
            y1={CHART_HEIGHT - PADDING}
            x2={CHART_WIDTH - PADDING}
            y2={CHART_HEIGHT - PADDING}
            stroke='#999'
            strokeWidth={1}
          />
          <Line x1={PADDING} y1={PADDING} x2={PADDING} y2={CHART_HEIGHT - PADDING} stroke='#999' strokeWidth={1} />

          {/* Y-axis grid lines and labels */}
          {yAxis.map((tick, i) => (
            <G key={`y-tick-${i}`}>
              <Line
                x1={PADDING}
                y1={tick.safeY}
                x2={CHART_WIDTH - PADDING}
                y2={tick.safeY}
                stroke='#eee'
                strokeWidth={1}
                strokeDasharray='4,4'
              />
              <Text x={PADDING - 8} y={tick.safeY + 2} style={{ fontSize: 8, textAnchor: 'end' }}>
                {tick.text}
              </Text>
            </G>
          ))}

          {/* Bars */}
          {bars.map((bar, i) => (
            <G key={`bar-${i}`}>
              <Rect
                x={bar.x}
                y={bar.y}
                width={bar.width}
                height={bar.height}
                fill={bar.isNegative ? '#DB4437' : '#4285F4'}
              />

              {/* Bar value label */}
              <Text
                x={bar.x + bar.width / 2}
                y={bar.isNegative ? bar.y + bar.height - 8 : bar.y - 8}
                style={{ fontSize: 10, textAnchor: 'middle' }}
              >
                {`${currencySymbol} ${bar.value}`}
              </Text>

              {/* X-axis label */}
              <Text
                x={bar.x + bar.width / 2}
                y={CHART_HEIGHT - PADDING + 15}
                style={{ fontSize: 10, textAnchor: 'middle' }}
              >
                {bar.category}
              </Text>
            </G>
          ))}
        </Svg>
      </View>
    </View>
  );
};

const MainDetails: React.FC<DeadlineGridProps> = ({ booking }) => {
  return (
    <LabeledValueContainer>
      <LabeledValue label='Booking Number' value={booking?.bookingNumber} />
      <LabeledValue label='Booking Status' value={booking?.bookingStatus} />
      <LabeledValue label='Booking Team' value={booking?.bookingTeam} />
      <LabeledValue label='Freight Mode' value={booking?.freightMode} />
      <LabeledValue label='Booking Type' value={booking?.bookingType} />
      <LabeledValue label='Booking Currency' value={booking?.bookingCurrency} />
      {booking?.pic && <LabeledValue label='PIC' value={booking?.pic} />}
    </LabeledValueContainer>
  );
};

const ShipmentDetails: React.FC<DeadlineGridProps> = ({ booking }) => {
  return (
    <>
      <DetailsHeader value='shipment details' />
      <LabeledValueContainer>
        <LabeledValue label='Consignor' value={booking?.consignor?.name} />
        <LabeledValue label='Consignee' value={booking?.consignee?.name} />
        <LabeledValue label='ETD' value={dayjs(booking?.etd).format('DD MMM YYYY')} />
        <LabeledValue label='ETA' value={dayjs(booking?.eta).format('DD MMM YYYY')} />
        <LabeledValue label='POL' value={booking?.portOfLoading?.portName} />
        <LabeledValue label='POD' value={booking?.portOfDestination?.portName} />
        <LabeledValue label='Vessel Name' value={booking?.vesselVoyage.vesselName} />
        <LabeledValue label='Voyage Number' value={booking?.vesselVoyage?.voyageNumber} />
        <LabeledValue label='FPOD' value={booking?.placeOfDelivery?.portName} />
        <LabeledValue label='Carrier Quotation No' value={booking?.carrierQuotationReference} />
        <LabeledValue label='Carrier Booking No' value={booking?.carrierBookingNumber} />
        <LabeledValue label='MBL No' value={booking?.mblNumber} />
        {booking?.bookingType === 'Import' && <LabeledValue label='HBL No' value={booking?.hblNumber} />}
        <LabeledValue label='Agent' value={booking?.agent?.name} />
        <LabeledValue label='Cargo Description' value={booking?.cargoDescription} />
        <LabeledValue label='No. of Containers' value={`${booking?.numberOfContainers} X ${booking?.containerType}`} />
      </LabeledValueContainer>
    </>
  );
};

const ContainerDetails: React.FC<DeadlineGridProps> = ({ booking }) => {
  return (
    <>
      <DetailsHeader value='container details' />
      <LabeledValueContainer>
        <LabeledValue label='Container Load Type' value={booking?.containerLoadType} />
        <LabeledValue label='Purchase Order No' value={booking?.purchaseOrderNumber} />
        {booking?.bookingType === 'Import' && <LabeledValue label='Release Pin:' value={booking?.releasePin} />}
        <LabeledValue label='Packages' value={booking?.packages} />
        <LabeledValue label='Approximate Weight' value={booking?.approximateWeight} />
        <LabeledValue label='Export Loading Type' value={booking?.loadingTypeExport} />
        <LabeledValue label='Import Loading Type' value={booking?.loadingTypeImport} />
        <LabeledValue label='Door Facing' value={booking?.doorFacing} />
      </LabeledValueContainer>
    </>
  );
};

const AdditionalDetails: React.FC<DeadlineGridProps> = ({ booking }) => {
  return (
    <>
      <DetailsHeader value='additional details' />
      <LabeledValueContainer>
        <LabeledValue label='HS Code' value={booking?.HSCode} />
        <LabeledValue
          label='Cargo Value'
          value={booking?.cargoValue?.value && `${booking?.cargoValue?.currency} ${booking?.cargoValue?.value}`}
        />
        <LabeledValue label='Notify Party' value={booking?.notifyParty?.name} />
        <LabeledValue label='MBL Status' value={booking?.mblStatus} />
        <LabeledValue label='HBL Status' value={booking?.hblStatus} />
        <LabeledValue label='Quotation Approval' value={booking?.quotationApproval ? 'Approved' : 'Pending'} />
        <LabeledValue label='Actual ETD' value={booking?.actualEtd && dayjs(booking.actualEtd).format('DD MMM YYYY')} />
        <LabeledValue
          label='Actual ETA (POD)'
          value={booking?.actualEtaPOD && dayjs(booking.actualEtaPOD).format('DD MMM YYYY')}
        />
        {booking?.enquiryRef && <LabeledValue label='Enquiry Ref' value={booking?.enquiryRef?.enquiryNumber} />}
        {booking?.incoterm && <LabeledValue label='Incoterm' value={booking?.incoterm} />}
      </LabeledValueContainer>
    </>
  );
};

const DangerousGoodsDetails: React.FC<DeadlineGridProps> = ({ booking }) => {
  return (
    <>
      <DetailsHeader value='dangerous goods details' />
      <LabeledValueContainer>
        <LabeledValue label='UN Number' value={booking?.dangerousGoods?.unNumber} />
        <LabeledValue label='Class' value={booking?.dangerousGoods?.class} />
        <LabeledValue label='Packing Group' value={booking?.dangerousGoods?.packingGroup} />
        <LabeledValue label='Flash Point' value={booking?.dangerousGoods?.flashPoint} />
        <LabeledValue label='EMS' value={booking?.dangerousGoods?.ems} />
        <LabeledValue label='Marine Pollutant' value={booking?.dangerousGoods?.marinePollutant} />
        <LabeledValue label='Subsidiary Risk' value={booking?.dangerousGoods?.subsidiaryRisk} />
        <LabeledValue label='Special Provision' value={booking?.dangerousGoods?.specialProvision} />
        <LabeledValue label='State' value={booking?.dangerousGoods?.state} />
        <LabeledValue label='Hazard Code' value={booking?.dangerousGoods?.hazardCode} />
        <LabeledValue label='Emergency Contact' value={booking?.dangerousGoods?.emergencyContact} />
        <LabeledValue label='Emergency Contact Number' value={booking?.dangerousGoods?.emergencyContactNumber} />
      </LabeledValueContainer>
    </>
  );
};

const CustomDetails: React.FC<DeadlineGridProps> = ({ booking }) => {
  return (
    <>
      <DetailsHeader value='custom details' />
      <LabeledValueContainer>
        {booking?.customDetails?.map((detail, index) => (
          <LabeledValue key={index} label={detail.key} value={detail.value} />
        ))}
      </LabeledValueContainer>
    </>
  );
};

const BookingOverviewDocument: React.FC<BookingOverviewDocumentProps> = ({
  booking,
  expectedProfit,
  currentBookingIncome,
  includeAccountingDetails,
}) => {
  const [exceededLimitsValues, setExceededLimitsValues] = useState<ExceededLimit[]>([]);

  useEffect(() => {
    const checkForExceededLimits = (val: string | undefined, key: string, limit = 20) => {
      if (val && val.length > limit) {
        setExceededLimitsValues(prev => [...prev, { key, value: val }]);
      }
      return;
    };

    if (booking) {
      checkForExceededLimits(booking?.bookingTeam, 'Booking Team');
      {
        booking?.pic && checkForExceededLimits(booking?.pic, 'PIC');
      }
      checkForExceededLimits(booking?.consignor?.name, 'Consignor');
      checkForExceededLimits(booking?.consignee?.name, 'Consignee');
      checkForExceededLimits(booking?.portOfLoading?.portName, 'POL');
      checkForExceededLimits(booking?.portOfDestination?.portName, 'POD');
      checkForExceededLimits(booking?.vesselVoyage.vesselName, 'Vessel Name');
      checkForExceededLimits(booking?.vesselVoyage?.voyageNumber, 'Voyage Number');
      checkForExceededLimits(booking?.placeOfDelivery?.portName, 'FPOD');
      checkForExceededLimits(booking?.carrierQuotationReference, 'Carrier Quotation No');
      checkForExceededLimits(booking?.carrierBookingNumber, 'Carrier Booking No');
      checkForExceededLimits(booking?.mblNumber, 'MBL No');
      {
        booking?.bookingType === 'Import' && checkForExceededLimits(booking?.hblNumber, 'HBL No');
      }
      checkForExceededLimits(booking?.agent?.name, 'Agent');
      checkForExceededLimits(booking?.cargoDescription, 'Cargo Description');
      checkForExceededLimits(`${booking?.numberOfContainers} X ${booking?.containerType}`, 'No. of Containers');
      checkForExceededLimits(booking?.purchaseOrderNumber, 'Purchase Order No');
      checkForExceededLimits(booking?.HSCode, 'HS Code');
      {
        booking?.cargoValue?.value &&
          checkForExceededLimits(`${booking?.cargoValue?.currency} ${booking?.cargoValue?.value}`, 'Cargo Value');
      }
      checkForExceededLimits(booking?.notifyParty?.name, 'Notify Party');
      if (booking?.dangerousGoods?.isDangerous) {
        checkForExceededLimits(booking?.dangerousGoods?.unNumber, 'UN Number');
        checkForExceededLimits(booking?.dangerousGoods?.flashPoint, 'Flash Point');
        checkForExceededLimits(booking?.dangerousGoods?.ems, 'EMS');
        checkForExceededLimits(booking?.dangerousGoods?.hazardCode, 'Hazard Code');
        checkForExceededLimits(booking?.dangerousGoods?.emergencyContact, 'Emergency Contact');
        checkForExceededLimits(booking?.dangerousGoods?.emergencyContactNumber, 'Emergency Contact Number');
      }
    }
  }, [booking]);

  return (
    <Document>
      <Page size='A4' style={styles.body} wrap>
        <View
          style={{
            ...styles.topFixed,
          }}
          fixed
        >
          <Text
            style={{
              fontSize: 20,
              fontWeight: 'medium',
              textAlign: 'center',
              width: '100%',
              borderBottom: '1px solid black',
            }}
          >
            BOOKING REPORT
          </Text>
        </View>
        <View>
          <MainDetails booking={booking} />
          <ShipmentDetails booking={booking} />
          <ContainerDetails booking={booking} />
          <AdditionalDetails booking={booking} />
          {booking?.dangerousGoods?.isDangerous && <DangerousGoodsDetails booking={booking} />}
          {booking?.customDetails && booking.customDetails.length > 0 && <CustomDetails booking={booking} />}
          <BLHistoryTable blHistoryArray={booking?.blStatusHistory} blRemarks={booking?.blRemarks} />
          <TranshipmentDetailsTable transhipmentDetails={booking?.transhipmentDetails || []} />
          <HorizontalDivider />
          <TransportScheduleTable
            transportScheduleArray={booking?.transportSchedule || []}
            loadingTypeExport={booking?.loadingTypeExport ?? 'SD'}
            pickupDepot={booking?.pickupDepot}
            dropoffDepot={booking?.dropoffDepot}
          />
          <View>{booking && <DeadlineGrid booking={booking} />}</View>
          <HorizontalDivider />
          <View>
            {includeAccountingDetails && (
              <AccountingTable
                booking={booking}
                expectedProfit={expectedProfit}
                currentBookingIncome={currentBookingIncome}
              />
            )}
          </View>
          {booking && includeAccountingDetails && (
            <ProfitLossBarGraph
              booking={booking}
              currentBookingIncome={currentBookingIncome}
              currencySymbol={currencySymbols[booking.bookingCurrency]}
            />
          )}
          <HorizontalDivider />
          {exceededLimitsValues.length > 0 && <TruncatedList exceededLimitsValues={exceededLimitsValues} />}
          <View style={styles.fixed} fixed>
            <Text
              style={styles.pageNumbers}
              render={({ pageNumber, totalPages }) => `Page  ${pageNumber} / ${totalPages}`}
              fixed
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default BookingOverviewDocument;
