import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Button,
  Paper,
  Divider,
  useTheme,
} from '@mui/material';
import { TranshipmentDetail } from 'models/booking.model';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { formatDeadline } from 'utils/utils';

interface TranshipmentDetailsDialogProps extends BaseDialogueProps {
  transhipmentDetails: TranshipmentDetail[];
}

const TranshipmentDetailsDialog: React.FC<TranshipmentDetailsDialogProps> = ({
  handleClose,
  open,
  transhipmentDetails,
}) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const sortedDetails = [...transhipmentDetails].sort((a, b) => (a.sequenceNumber ?? 0) - (b.sequenceNumber ?? 0));

  const handleNext = () => {
    setActiveStep(prevStep => Math.min(prevStep + 1, transhipmentDetails.length - 1));
  };

  const handleBack = () => {
    setActiveStep(prevStep => Math.max(prevStep - 1, 0));
  };
  return (
    <Dialog open={open} onClose={() => handleClose()} maxWidth='sm' fullWidth>
      <DialogTitle>Transhipment Details</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} orientation='vertical'>
          {sortedDetails.map((step, index) => (
            <Step key={index}>
              <StepLabel>
                <Typography variant='h6'>
                  {formatDeadline(step.etd || 'N/A')} - {formatDeadline(step.eta || 'N/A')}{' '}
                </Typography>
              </StepLabel>
              <StepContent>
                <Paper variant='outlined' sx={{ p: 2, mb: 2 }}>
                  <Typography>
                    <strong>Vessel Name:</strong> {step.vesselVoyage.vesselName || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Voyage Number:</strong> {step.vesselVoyage.voyageNumber || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Vessel Country:</strong> {step.vesselVoyage.vesselCountry || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Port of Loading:</strong> {step.transhipmentPortOfLoading?.portName || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Port of Discharge:</strong> {step.transhipmentPortOfDischarge?.portName || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Carrier:</strong> {step.carrier?.name || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Vessel Type:</strong> {step.vesselType || 'N/A'}
                  </Typography>
                </Paper>
                <Divider />
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1, bgcolor: theme.palette.secondary[400], color: theme.palette.common.white }}
                  >
                    Back
                  </Button>
                  <Button
                    variant='contained'
                    onClick={handleNext}
                    sx={{ mt: 1, bgcolor: theme.palette.secondary[500] }}
                    disabled={activeStep === transhipmentDetails.length - 1}
                  >
                    Next
                  </Button>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <GeneralDialogueActions handleClose={handleClose} noSubmission />
      </DialogContent>
    </Dialog>
  );
};

export default TranshipmentDetailsDialog;
