import {
  EditOutlined,
  FileDownloadOutlined,
  FilterAltOutlined,
  InfoOutlined,
  RecentActorsOutlined,
  RefreshOutlined,
} from '@mui/icons-material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { PDFViewer } from '@react-pdf/renderer';
import { sec } from 'auth/accessToken';
import axios from 'axios';
import AuditLogsDialogue from 'components/AuditLogsDialogue';
import CustomDataGrid from 'components/CustomDataGrid';
import DownloadPopup from 'components/DownloadPopup';
import InvoiceConfirmation from 'components/InvoiceConfirmation';
import InvoicesFilterSideBar from 'components/InvoicesFilterSideBar';
import InvoiceViewer from 'components/InvoiceViewer';
import SearchBar from 'components/SearchBar';
import UpdateInvoiceDialogue from 'components/UpdateInvoiceDialogue';
import { currencySymbols } from 'dataAssets/constants';
import dayjs from 'dayjs';
import { Booking, Invoice } from 'models/booking.model';
import { Customer, Vendor } from 'models/index.model';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetAccountingsQuery, useGetAuditsQuery, useLazyGetAccountingsQuery } from 'state/api';
import { apiCall } from 'utils/serverFunctions';
import { formatInvoices, setBookingTeams, totalInvoiceValue, useUserPermissions } from 'utils/utils';

interface InvoiceComponentProps {
  type: string;
}
const InvoicesComponent: React.FC<InvoiceComponentProps> = ({ type }) => {
  const theme = useTheme();
  const userPermissions = useUserPermissions();
  const hasExportPermission = userPermissions.includes('read:bookings-export');
  const hasImportPermission = userPermissions.includes('read:bookings-import');
  const teams = setBookingTeams(hasExportPermission, hasImportPermission);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [invoiceViewerOpen, setInvoiceViewerOpen] = useState(false);
  const [bookingData, setBookingData] = useState<Booking>({} as Booking);
  const [invoiceData, setInvoiceData] = useState<Invoice>({} as Invoice);
  const [updateInvoiceDialogueOpen, setUpdateInvoiceDialogueOpen] = useState(false);
  const [openAuditLogs, setOpenAuditLogs] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedField, setSelectedField] = useState<string>('');
  const [downloadDialogOpen, setDownloadDialogOpen] = useState<boolean>(false);
  const [fetchData, lastResult] = useLazyGetAccountingsQuery();

  const openUpdateInvoiceDialogue = async (bookingNumber: string, invoice: Invoice) => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();

      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/bookings/search/${bookingNumber}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setBookingData(response.data);
      setInvoiceData(invoice);
      setUpdateInvoiceDialogueOpen(true);
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdateInvoiceDialogueClose = () => {
    setUpdateInvoiceDialogueOpen(false);
    refetch();
  };

  const openInvoiceViewer = async (bookingNumber: string, invoice: Invoice) => {
    try {
      const response = await apiCall(`/api/v1/bookings/search/${bookingNumber}`, {
        method: 'GET',
      });
      setBookingData(response?.data[0]);
      setInvoiceData(invoice);
      setInvoiceViewerOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleInvoiceViewerClose = () => {
    setInvoiceViewerOpen(false);
  };

  const { parties, pendingApproval, invoiceOutstanding, underDispute, startDate, endDate, dateType, bookingTeam } =
    useSelector((state: any) => state.global.accountingsFilters);

  const {
    data: invoices,
    isLoading,
    refetch,
    isFetching,
  } = useGetAccountingsQuery([
    type,
    {
      parties: parties?.map((party: Customer | Vendor) => party._id).join(',') ?? '',
      approvalPending: pendingApproval,
      invoiceOutstanding,
      underDispute,
      dateRange: [startDate, endDate],
      dateType,
      bookingTeam,
      page: (page + 1).toString(),
      limit: pageSize.toString(),
      searchText,
      selectedField,
    },
  ]);

  React.useEffect(() => {
    setTotalRows(invoices?.totalInvoices ?? 0);
  }, [invoices?.totalInvoices]);

  const { data: invoicesAuditLogs } = useGetAuditsQuery(['Invoice', invoiceData?._id]);

  const columns: GridColDef[] = [
    {
      field: 'invoiceNumber',
      headerName: 'Invoice Number',
      flex: 0.4,
      valueGetter: params => {
        return params.row.approvedInvoiceNumber ?? params.row.performaInvoiceNumber;
      },
    },
    {
      field: 'bookingNumber',
      headerName: 'Booking Number',
      flex: 0.3,
    },
    {
      field: 'invoiceParty',
      headerName: 'Invoice Party',
      flex: 0.5,
      valueGetter: params => {
        return params.row.vendor?.name ?? params.row.customer?.name ?? 'N/A';
      },
    },
    {
      field: 'vendorInvoiceNumber',
      headerName: 'PI Number',
      flex: 0.3,
    },
    {
      field: 'invoiceValue',
      headerName: 'Invoice Value',
      flex: 0.3,
      valueGetter: params => {
        return `${currencySymbols[params.row.invoiceCurrency]} ${totalInvoiceValue(
          params.row.charges,
          params.row.numberOfContainers,
        )}`;
      },
    },
    {
      field: 'outstandingAmount',
      headerName: 'Outstanding Amount',
      flex: 0.3,
      valueGetter: params => {
        return params.row.outstandingAmount
          ? `${currencySymbols[params.row.invoiceCurrency]} ${params.row.outstandingAmount}`
          : params.row.outstandingAmount === 0
            ? params.row.outstandingAmount
            : `${currencySymbols[params.row.invoiceCurrency]} ${totalInvoiceValue(
                params.row.charges,
                params.row.numberOfContainers,
              )}`;
      },
    },
    {
      field: 'disputeDate',
      headerName: 'Dispute Date',
      flex: 0.3,
      valueGetter: params => {
        return dayjs(params.row.disputeDetails.disputeDate).format('DD/MM/YYYY');
      },
    },
    {
      field: 'disputeRemarks',
      headerName: 'Dispute Remarks',
      flex: 0.5,
      valueGetter: params => {
        return params.row.disputeDetails.disputeRemarks;
      },
    },
    {
      field: 'disputeFollowUp',
      headerName: 'Follow Up',
      flex: 0.5,
      valueGetter: params => {
        return params.row.disputeDetails.disputeFollowUp;
      },
    },
    {
      field: 'invoiceSent',
      headerName: 'Sent',
      flex: 0.2,
      valueGetter: params => {
        return params.row.invoiceSent ? 'Yes' : 'No';
      },
    },
    {
      field: 'invoiceDate',
      headerName: 'Creation Date',
      flex: 0.4,
      valueGetter: params => {
        return dayjs(params.row.invoiceDate).format('DD/MM/YYYY');
      },
    },
    {
      field: 'approvalDate',
      headerName: 'Approval Date',
      flex: 0.4,
      valueGetter: params => {
        return dayjs(params.row.approvalDate).format('DD/MM/YYYY');
      },
    },
    {
      field: 'Actions',
      flex: 0.4,
      renderCell: (cellValues: any) => {
        return (
          <Box display={'flex'} justifyContent={'space-evenly'}>
            <Box>
              <Tooltip title='View Invoice'>
                <IconButton
                  sx={{ color: theme.palette.secondary[400] }}
                  onClick={() => openInvoiceViewer(cellValues.row.bookingNumber, cellValues.row)}
                >
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              {cellValues.row.approvalPending && (
                <>
                  <Tooltip title='Edit Invoice'>
                    <IconButton
                      sx={{ color: theme.palette.secondary[400] }}
                      onClick={() => openUpdateInvoiceDialogue(cellValues.row.bookingNumber, cellValues.row)}
                    >
                      <EditOutlined />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Box>
            <Box>
              <Tooltip title='View Audit Logs'>
                <IconButton
                  sx={{ color: theme.palette.secondary[400] }}
                  onClick={() => {
                    setInvoiceData(cellValues.row);
                    setOpenAuditLogs(true);
                  }}
                >
                  <RecentActorsOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        );
      },
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    invoiceNumber: true,
    bookingNumber: true,
    invoiceParty: true,
    invoiceValue: true,
    invoiceOutstanding: true,
    disputeRemarks: false,
    disputeDate: false,
    disputeFollowUp: false,
    invoiceDate: true,
    approvalDate: true,
    dueDate: true,
    vendorInvoiceNumber: false,
    actions: true,
  });

  useEffect(() => {
    if (type === 'purchaseInvoices') {
      setColumnVisibilityModel(prevModel => ({
        ...prevModel,
        vendorInvoiceNumber: true,
      }));
    } else {
      setColumnVisibilityModel(prevModel => ({
        ...prevModel,
        vendorInvoiceNumber: false,
      }));
    }
  }, [type]);

  useEffect(() => {
    setColumnVisibilityModel(prevModel => ({
      ...prevModel,
      approvalDate: !pendingApproval,
      underDispute: pendingApproval,
      invoiceSent: !pendingApproval,
    }));
  }, [pendingApproval]);

  useEffect(() => {
    setColumnVisibilityModel(prevModel => ({
      ...prevModel,
      disputeDate: underDispute,
      disputeRemarks: underDispute,
      disputeFollowUp: underDispute,
    }));
  }, [underDispute]);

  const handleColumnVisibilityChange = (newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <Box mt='1rem'>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        {/* <Header title={invoiceType} subtitle='' /> */}
        <SearchBar
          setSearchText={setSearchText}
          setSelectedField={setSelectedField}
          setPage={setPage}
          columns={[
            ...columns,
            ...(pendingApproval
              ? [
                  {
                    field: 'performaInvoiceNumber',
                    headerName: 'Performa Invoice No.',
                  },
                ]
              : [
                  {
                    field: 'approvedInvoiceNumber',
                    headerName: 'Approved Invoice No.',
                  },
                ]),
          ]}
        />
        <Box>
          <Tooltip title='Refresh'>
            <IconButton onClick={() => refetch()}>
              <RefreshOutlined />
            </IconButton>
          </Tooltip>
          {!pendingApproval && type === 'salesInvoices' && (
            <Tooltip title='Export to Tally format'>
              <IconButton onClick={() => setDownloadDialogOpen(true)}>
                <FileDownloadOutlined />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title='Filter'>
            <IconButton onClick={() => setDrawerOpen(true)}>
              <FilterAltOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <CustomDataGrid
        data={invoices?.invoices ?? []}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        columnVisibilityModel={columnVisibilityModel}
        handleColumnVisibilityChange={handleColumnVisibilityChange}
        sortModel={[
          {
            field: 'invoiceNumber',
            sort: 'asc',
          },
        ]}
        height='75vh'
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        totalRows={totalRows}
      />
      <InvoiceViewer
        open={invoiceViewerOpen}
        handleClose={handleInvoiceViewerClose}
        invoice={invoiceData}
        invoiceType={type}
        pdfView={
          <PDFViewer className='h-full w-full'>
            <InvoiceConfirmation invoice={invoiceData} booking={bookingData} invoiceType={type} />
          </PDFViewer>
        }
      />
      <UpdateInvoiceDialogue
        open={updateInvoiceDialogueOpen}
        handleClose={handleUpdateInvoiceDialogueClose}
        invoice={invoiceData}
        invoiceType={type}
      />
      <AuditLogsDialogue
        open={openAuditLogs}
        handleClose={() => setOpenAuditLogs(false)}
        auditLogs={invoicesAuditLogs}
      />
      <InvoicesFilterSideBar open={drawerOpen} onClose={() => setDrawerOpen(false)} teams={teams} accountsType={type} />
      <DownloadPopup
        open={downloadDialogOpen}
        onClose={() => setDownloadDialogOpen(false)}
        columnVisibility={columnVisibilityModel}
        dataFormatterfn={formatInvoices}
        totalRecords={totalRows}
        itemsPerPage={pageSize}
        excelFileName='Invoices'
        lazyQuery={[fetchData, lastResult]}
        dataKey='invoices'
        filters={[
          type,
          {
            parties: parties?.map((party: Customer | Vendor) => party._id).join(',') ?? '',
            approvalPending: pendingApproval,
            invoiceOutstanding,
            underDispute,
            dateRange: [startDate, endDate],
            dateType,
            bookingTeam,
            searchText,
            selectedField,
          },
        ]}
      />
    </Box>
  );
};

export default InvoicesComponent;
