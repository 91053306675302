import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { CustomerVolumeProfitByMonth } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import AnalyticsCardHeader from 'components/AnalyticsCardHeader';

interface CustomerMonthlyStatsProps extends BaseDialogueProps {
  customerData: CustomerVolumeProfitByMonth;
  customerVolumeBookingType: string;
}

const CustomerMonthlyStats: React.FC<CustomerMonthlyStatsProps> = ({
  customerData,
  customerVolumeBookingType,
  handleClose,
  open,
}) => {
  const theme = useTheme();

  const data = customerData?.monthlyVolumesProfits?.map(entry => ({
    month: entry.month,
    volume: entry.volume,
    profit: entry.profit,
  }));

  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth='xl'>
        <DialogTitle>{customerData.customerName}</DialogTitle>
        <DialogContent>
          <Box display={'flex'}>
            <Box flex={0.5}>
              <AnalyticsCardHeader title='Total Monthly Volumes' />
              <ResponsiveContainer width='100%' height={300}>
                <BarChart width={500} height={180} data={data}>
                  <defs>
                    <linearGradient id='colorGradientExport' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={theme.palette.primary[300]} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={theme.palette.primary[300]} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorGradientImport' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={theme.palette.secondary[300]} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={theme.palette.secondary[300]} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid vertical={false} stroke={theme.palette.grey[800]} />
                  <XAxis dataKey='month' axisLine={false} tickLine={false} style={{ fontSize: '10px' }} />
                  <YAxis axisLine={false} tickLine={false} style={{ fontSize: '10px' }} />
                  <Bar dataKey='volume' fill={`url(#colorGradientExport)`} barSize={35} />
                  <Tooltip
                    cursor={false}
                    contentStyle={{
                      backgroundColor: theme.palette.background.alt,
                    }}
                    labelStyle={{ color: theme.palette.text.primary }}
                    itemStyle={{ color: theme.palette.text.primary }}
                    formatter={(value, name, props) => {
                      const { volume } = props.payload;
                      return [`Volume: ${volume}`];
                    }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
            <Box flex={0.5}>
              <AnalyticsCardHeader title='Total Monthly Profits' />
              <ResponsiveContainer width='100%' height={300}>
                <BarChart width={500} height={180} data={data}>
                  <defs>
                    <linearGradient id='colorGradientExport' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={theme.palette.primary[300]} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={theme.palette.primary[300]} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorGradientImport' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={theme.palette.secondary[300]} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={theme.palette.secondary[300]} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid vertical={false} stroke={theme.palette.grey[800]} />
                  <XAxis dataKey='month' axisLine={false} tickLine={false} style={{ fontSize: '10px' }} />
                  <YAxis axisLine={false} tickLine={false} style={{ fontSize: '10px' }}>
                    <Label value='£' position='insideLeft' style={{ textAnchor: 'middle' }} />
                  </YAxis>
                  <Bar dataKey='profit' fill={`url(#colorGradientImport)`} barSize={35} />
                  <Tooltip
                    cursor={false}
                    contentStyle={{
                      backgroundColor: theme.palette.background.alt,
                    }}
                    labelStyle={{ color: theme.palette.text.primary }}
                    itemStyle={{ color: theme.palette.text.primary }}
                    formatter={(value, name, props) => {
                      const { profit } = props.payload;
                      return [`Profit: £${profit}`];
                    }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Box>
          <Box display={'flex'}>
            <Box flex={0.3}>
              <AnalyticsCardHeader title='Top Carriers' />
              <ResponsiveContainer width='100%' height={300}>
                <BarChart
                  layout='vertical'
                  data={customerData.topCarriers}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient id='colorVolume' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={theme.palette.primary[300]} stopOpacity={0.5} />
                      <stop offset='95%' stopColor={theme.palette.primary[300]} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid vertical={false} stroke={theme.palette.grey[800]} />
                  <XAxis axisLine={false} tickLine={false} style={{ fontSize: '10px' }} type='number' />
                  <YAxis
                    dataKey='carrierName'
                    type='category'
                    axisLine={false}
                    tickLine={false}
                    style={{ fontSize: '10px' }}
                  />
                  <Tooltip
                    cursor={false}
                    contentStyle={{
                      backgroundColor: theme.palette.background.alt,
                    }}
                    labelStyle={{ color: theme.palette.text.primary }}
                    itemStyle={{ color: theme.palette.text.primary }}
                    formatter={(value, name, props) => {
                      const { volume } = props.payload;
                      return [`Volume: ${volume}`];
                    }}
                  />
                  <Legend
                    wrapperStyle={{
                      fontSize: '10px',
                    }}
                  />
                  <Bar dataKey='volume' fill='url(#colorVolume)' name='Volume (CN)' barSize={20}>
                    <LabelList
                      dataKey='volume'
                      position='top'
                      formatter={(value: any) => `Volume: ${value}`}
                      style={{
                        fontSize: '9px',
                        fill:
                          theme.palette.mode === 'dark'
                            ? `${theme.palette.secondary[100]}`
                            : `${theme.palette.secondary[600]}`,
                      }}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
            <Box flex={0.4}>
              <AnalyticsCardHeader title='Top Sites' />
              <ResponsiveContainer width='100%' height={300}>
                <BarChart
                  width={500}
                  height={180}
                  data={customerData.topTransportSites}
                  margin={{
                    top: 10,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient id='colorGradientExport' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={theme.palette.primary[300]} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={theme.palette.primary[300]} stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id='colorGradientImport' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={theme.palette.secondary[300]} stopOpacity={0.8} />
                      <stop offset='95%' stopColor={theme.palette.secondary[300]} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid vertical={false} stroke={theme.palette.grey[800]} />
                  <XAxis dataKey='siteName' axisLine={false} tickLine={false} style={{ fontSize: '10px' }} />
                  <YAxis axisLine={false} tickLine={false} style={{ fontSize: '10px' }} />
                  <Bar
                    dataKey='volume'
                    fill={`url(#${
                      customerVolumeBookingType === 'Export' ? 'colorGradientExport' : 'colorGradientImport'
                    })`}
                    barSize={35}
                  />
                  <Tooltip
                    cursor={false}
                    contentStyle={{
                      backgroundColor: theme.palette.background.alt,
                    }}
                    labelStyle={{ color: theme.palette.text.primary }}
                    itemStyle={{ color: theme.palette.text.primary }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
            <Box flex={0.3}>
              <AnalyticsCardHeader title='Top Ports' />
              <ResponsiveContainer width='100%' height={300}>
                <BarChart
                  layout='vertical'
                  data={customerData.topPorts}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient id='colorVolume' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={theme.palette.primary[300]} stopOpacity={0.5} />
                      <stop offset='95%' stopColor={theme.palette.primary[300]} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid vertical={false} stroke={theme.palette.grey[800]} />
                  <XAxis axisLine={false} tickLine={false} style={{ fontSize: '10px' }} type='number' />
                  <YAxis
                    dataKey='portCode'
                    type='category'
                    axisLine={false}
                    tickLine={false}
                    style={{ fontSize: '10px' }}
                  />
                  <Tooltip
                    cursor={false}
                    contentStyle={{
                      backgroundColor: theme.palette.background.alt,
                    }}
                    labelStyle={{ color: theme.palette.text.primary }}
                    itemStyle={{ color: theme.palette.text.primary }}
                    formatter={(value, name, props) => {
                      const { volume } = props.payload;
                      return [`Volume: ${volume}`];
                    }}
                  />
                  <Legend
                    wrapperStyle={{
                      fontSize: '10px',
                    }}
                  />
                  <Bar dataKey='volume' fill='url(#colorVolume)' name='Volume (CN)' barSize={20}>
                    <LabelList
                      dataKey='volume'
                      position='top'
                      formatter={(value: any) => `Volume: ${value}`}
                      style={{
                        fontSize: '9px',
                        fill:
                          theme.palette.mode === 'dark'
                            ? `${theme.palette.secondary[100]}`
                            : `${theme.palette.secondary[600]}`,
                      }}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Box>
          <GeneralDialogueActions noSubmission handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CustomerMonthlyStats;
