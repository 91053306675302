import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';

export interface ConfirmationDialogProps extends BaseDialogueProps {
  onConfirm: Function;
  title: string;
  content: JSX.Element | string;
  warningMessage?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  handleClose,
  onConfirm,
  title,
  content,
  warningMessage,
}) => {
  return (
    <Dialog fullWidth maxWidth={'md'} open={open} onClose={() => handleClose()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content}
        {warningMessage && <Typography color={'orange'}>{warningMessage}</Typography>}
      </DialogContent>
      <GeneralDialogueActions onClick={onConfirm} handleClose={handleClose} submitText={'Confirm'} />
    </Dialog>
  );
};

export default ConfirmationDialog;
