import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { Booking } from 'models/booking.model';
import BookingSummaryDocument from 'components/BookingSummaryDocument';

export interface BookingOverviewViewerProps extends BaseDialogueProps {
  booking: Booking | undefined;
  expectedProfit: any;
  currentBookingIncome: Booking['bookingIncome'];
  includeAccountingDetails: Boolean;
}

const BookingOverviewViewer: React.FC<BookingOverviewViewerProps> = ({
  handleClose,
  open,
  booking,
  expectedProfit,
  currentBookingIncome,
  includeAccountingDetails,
}) => {
  return (
    <Box>
      <Dialog open={open} onClose={() => handleClose()} fullScreen>
        <DialogTitle
          sx={{
            fontSize: '1.6rem',
          }}
        >
          {`Booking Number: ${booking?.bookingNumber}`}
        </DialogTitle>
        <DialogContent>
          <PDFViewer style={{ width: '100%', height: '100%' }}>
            <BookingSummaryDocument
              booking={booking}
              expectedProfit={expectedProfit}
              currentBookingIncome={currentBookingIncome}
              includeAccountingDetails={includeAccountingDetails}
            />
          </PDFViewer>
        </DialogContent>
        <GeneralDialogueActions handleClose={handleClose} noSubmission />
      </Dialog>
    </Box>
  );
};

export default BookingOverviewViewer;
