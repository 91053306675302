import { CarRental } from '@mui/icons-material';
import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { BookingStatus, EnquiryStatus, TeamType } from 'models/booking.model';

const initialState = {
  mode: 'dark',
  userEmail: '',
  permissions: [],
  bookingsColumnVisibilityModel: {
    bookingNumber: true,
    consignor: true,
    consignee: false,
    notifyParty: false,
    carrier: true,
    carrierBookingNumber: true,
    carrierQuotationReference: false,
    purchaseOrderNumber: true,
    mblNumber: false,
    hblNumber: false,
    blRemarks: true,
    blStatus: true,
    blStatusDate: true,
    followUp: true,
    followUpDate: true,
    agent: false,
    haulers: false,
    sites: true,
    portOfLoading: true,
    portOfDestination: true,
    placeOfDelivery: false,
    etd: false,
    eta: false,
    etaPOL: false,
    actualEtd: false,
    actualEtaPOD: false,
    actualEtaPOL: false,
    quotationApproval: false,
    bookingConfirmationSent: false,
    numberOfContainers: true,
    packages: false,
    approximateWeight: false,
    containerType: false,
    containerLoadType: false,
    doorFacing: false,
    bookingType: false,
    releasePin: false,
    loadingTypeExport: false,
    loadingTypeImport: false,
    cargoDescription: false,
    HSCode: false,
    cargoValue: false,
    vesselVoyage: false,
    dateCreated: true,
    bookingTeam: false,
    actions: true,
    incoterm: true,
    pic: false,
    actualProfit: false,
    expectedProfit: false,
    freightMode: false,
  } as GridColumnVisibilityModel,
  enquiriesColumnVisibilityModel: {
    enquiryNumber: true,
    dateCreated: true,
    customer: true,
    volume: false,
    containerType: true,
    containerLoadType: false,
    portOfLoading: true,
    portOfDestination: true,
    fpod: true,
    carrier: true,
    carrierBookingNumber: true,
    purchaseOrderNumber: true,
    agent: false,
    enquiryStatus: true,
    pic: true,
    cargoDescription: false,
    enquiryRemarks: false,
    sites: true,
    incoterm: true,
    freightMode: false,
  } as GridColumnVisibilityModel,
  bookingsFilters: {
    dateRange: 30,
    startDate: dayjs().subtract(30, 'days').toISOString(),
    endDate: dayjs().toISOString(),
    bookingFreightMode: 'All',
    bookingStatus: BookingStatus.Ongoing,
    bookingTeam: TeamType.All,
    consignor: undefined,
    consignee: undefined,
    carrier: undefined,
    portOfLoading: undefined,
    portOfDestination: undefined,
    bookingEtdDateRangeAfter: undefined,
    bookingEtaDateRangeBefore: undefined,
    containerType: undefined,
    salesInvoiceCreated: undefined,
    purchaseInvoiceCreated: undefined,
    bookingConfirmationSent: undefined,
    profitsMatching: undefined,
    allStepsCompleted: undefined,
    blStatus: undefined,
    outstandingAdditionalCharges: undefined,
    pic: undefined,
  },
  enquiriesFilters: {
    enquiryDateRange: 30,
    enquiryStartDate: dayjs().subtract(30, 'days').toISOString(),
    enquiryEndDate: dayjs().toISOString(),
    enquiryFreightMode: 'All',
    enquiryBookingTeam: TeamType.All,
    customer: undefined,
    volume: undefined,
    enquiryContainerType: undefined,
    transportSite: undefined,
    transportSchedule: undefined,
    enquiryPortOfLoading: undefined,
    enquiryPortOfDestination: undefined,
    fpod: undefined,
    enquiryCarrier: undefined,
    enquiryStatus: EnquiryStatus.InProgress,
    enquiryPic: undefined,
    buyRates: undefined,
    sellRates: undefined,
    cargoDescription: undefined,
    incoterm: undefined,
  },
  invoicesFilters: {
    invoiceType: 'salesInvoices',
    pendingApproval: true,
    invoiceOutstanding: true,
    underDispute: false,
    startDate: dayjs().subtract(30, 'day').toISOString(),
    endDate: dayjs().toISOString(),
    dateType: 'invoiceDate',
    bookingTeam: TeamType.All,
  },
  accountingsFilters: {
    accountsType: undefined,
    parties: undefined,
    bankAccounts: undefined,
    pendingApproval: true,
    invoiceOutstanding: true,
    underDispute: false,
    startDate: dayjs().subtract(30, 'day').toISOString(),
    endDate: dayjs().toISOString(),
    dateType: 'invoiceDate',
    bookingTeam: TeamType.All,
  },
  loadingsFilters: {
    startDate: dayjs().toISOString(),
    endDate: dayjs().toISOString(),
    bookingTeam: TeamType.All,
  },
  deadlinesFilters: {
    startDate: dayjs().toISOString(),
    endDate: dayjs().toISOString(),
    bookingTeam: TeamType.All,
  },
  enquiryDataToBook: {
    _id: undefined,
    freightMode: undefined,
    dateCreated: undefined,
    customer: undefined,
    volume: undefined,
    containerType: undefined,
    containerLoadType: undefined,
    portOfLoading: undefined,
    portOfDestination: undefined,
    fpod: undefined,
    carrier: undefined,
    carrierBookingNumber: undefined,
    purchaseOrderNumber: undefined,
    agent: undefined,
    enquiryStatus: undefined,
    pic: undefined,
    buyRates: undefined,
    sellRates: undefined,
    sites: undefined,
    enquiryRemarks: undefined,
    cargoDescription: undefined,
    incoterm: undefined,
  },
  snackbarSettings: {
    snackbarOpen: false,
    snackbarSeverity: 'success',
    message: '',
  },
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setBookingsColumnVisibilityModel: (state, action) => {
      state.bookingsColumnVisibilityModel = action.payload;
    },
    setEnquiriesColumnVisibilityModel: (state, action) => {
      state.enquiriesColumnVisibilityModel = action.payload;
    },
    setBookingsFilters: (state, action) => {
      state.bookingsFilters = { ...state.bookingsFilters, ...action.payload };
    },
    setEnquiriesFilters: (state, action) => {
      state.enquiriesFilters = { ...state.enquiriesFilters, ...action.payload };
    },
    setEnquiryDataToBook: (state, action) => {
      state.enquiryDataToBook = action.payload;
    },
    clearBookingsFilters: state => {
      state.bookingsFilters = initialState.bookingsFilters;
    },
    clearEnquiriesFilters: state => {
      state.enquiriesFilters = initialState.enquiriesFilters;
    },
    setInvoicesFilters: (state, action) => {
      state.invoicesFilters = { ...state.invoicesFilters, ...action.payload };
    },
    setAccountingsFilters: (state, action) => {
      state.accountingsFilters = { ...state.accountingsFilters, ...action.payload };
    },
    clearAccountingsFilters: state => {
      state.accountingsFilters = initialState.accountingsFilters;
    },
    setLoadingsFilters: (state, action) => {
      state.loadingsFilters = { ...state.loadingsFilters, ...action.payload };
    },
    setDeadlinesFilters: (state, action) => {
      state.deadlinesFilters = { ...state.deadlinesFilters, ...action.payload };
    },
    setSnackbarSettings: (state, action) => {
      state.snackbarSettings = action.payload;
    },
  },
});

export const {
  setMode,
  setUserEmail,
  setPermissions,
  setBookingsColumnVisibilityModel,
  setEnquiriesColumnVisibilityModel,
  setBookingsFilters,
  setEnquiriesFilters,
  clearBookingsFilters,
  clearEnquiriesFilters,
  setInvoicesFilters,
  setLoadingsFilters,
  setDeadlinesFilters,
  setEnquiryDataToBook,
  setSnackbarSettings,
  setAccountingsFilters,
  clearAccountingsFilters,
} = globalSlice.actions;

export default globalSlice.reducer;
