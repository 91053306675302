import React from 'react';
import { Dialog, DialogContent, Box, Tooltip, DialogTitle, IconButton } from '@mui/material';
import { GridColDef, GridAlignment } from '@mui/x-data-grid';
import { ContainerTypeData } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { getAllMonthNames, getCurrentMonth, handleExcelDownload, truncateName } from 'utils/utils';
import { DownloadOutlined } from '@mui/icons-material';
import MonthlyReportCustomDataGrid from './MonthlyReportCustomDataGrid';

interface ContainerTypeMonthlyReportTableProps extends BaseDialogueProps {
  data: ContainerTypeData[];
  bookingType: string;
  selectedYear: number;
  selectedMonth: number | null;
}

const ContainerTypeMonthlyReportTable: React.FC<ContainerTypeMonthlyReportTableProps> = ({
  data,
  bookingType,
  handleClose,
  open,
  selectedYear,
  selectedMonth,
}) => {
  const currentMonth = getCurrentMonth(selectedYear, selectedMonth);
  const allMonths = getAllMonthNames(currentMonth);
  const excelFileName = selectedMonth
    ? `Container-Type-Monthly-${bookingType}-Report_${allMonths[selectedMonth - 1]}-${selectedYear.toString()}`
    : `Container-Type-Monthly-${bookingType}-Report_${selectedYear.toString()}`;

  // Transform data for DataGrid
  const rows = [
    ...data.map((item, index) => {
      const row: any = {
        id: index,
        containerType: item.containerType,
        totalVolume: item.totalVolume,
      };

      // Add monthly volumes
      item.monthlyContainerBookings.forEach((booking, monthIndex) => {
        row[`${allMonths[monthIndex]}_volume`] = booking.volume;
      });

      return row;
    }),
  ];

  // Add YTD row
  const ytdRow: any = {
    id: 'ytd',
    containerType: 'YTD',
    totalVolume: data.reduce((total, containerType) => total + containerType.totalVolume, 0),
    isYTD: true,
  };

  // Calculate YTD volumes for each month
  allMonths.forEach((month, index) => {
    ytdRow[`${month}_volume`] = data.reduce((total, containerType) => {
      const monthData = containerType.monthlyContainerBookings.find(m => m.month === month);
      return total + (monthData?.volume || 0);
    }, 0);
  });

  rows.push(ytdRow);

  const prepareExcelData = () => {
    const excelData = data.map(item => {
      const volumeColumns: { [key: string]: number } = {};
      item.monthlyContainerBookings.forEach((booking, index) => {
        volumeColumns[`${allMonths[index]} (Volume)`] = booking.volume;
      });

      return {
        'Container Type': item.containerType,
        ...volumeColumns,
        'Total Volume': item.totalVolume,
      };
    });

    // Add YTD row in the same format
    const ytdExcelRow: { [key: string]: string | number } = {
      'Container Type': 'YTD',
    };

    allMonths.forEach((month, index) => {
      ytdExcelRow[`${month} (Volume)`] = rows[rows.length - 1][`${month}_volume`];
    });
    ytdExcelRow['Total Volume'] = rows[rows.length - 1].totalVolume;

    return [...excelData, ytdExcelRow];
  };

  // Define columns for DataGrid
  const columns: GridColDef[] = [
    {
      field: 'containerType',
      headerName: 'Container Type',
      flex: 2,
      renderCell: params => (
        <Tooltip title={params.value} placement='top'>
          <span>{truncateName(params.value as string)}</span>
        </Tooltip>
      ),
    },
    ...allMonths.map(month => ({
      field: `${month}_volume`,
      headerName: `${month} (Volume)`,
      flex: 1,
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
      type: 'number',
    })),
    {
      field: 'totalVolume',
      headerName: 'Total Volume',
      flex: 1,
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
      type: 'number',
    },
  ];

  const handleDialogClose = (_event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth fullScreen maxWidth='xl'>
      <DialogTitle>
        <Box display='flex' alignItems='center' gap={1}>
          {bookingType} Monthly Container Type Report{' '}
          <Tooltip title='Download as Excel' placement='top'>
            <IconButton onClick={() => handleExcelDownload(prepareExcelData(), excelFileName)}>
              <DownloadOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent>
        <MonthlyReportCustomDataGrid rows={rows} columns={columns} />
        <GeneralDialogueActions noSubmission handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
};

export default ContainerTypeMonthlyReportTable;
