import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Box, Tooltip, DialogTitle, IconButton } from '@mui/material';
import { GridColDef, GridValueFormatterParams, GridAlignment } from '@mui/x-data-grid';
import { CustomerVolumeProfitByMonth } from 'models/analytics';
import { BaseDialogueProps } from 'models/index.model';
import GeneralDialogueActions from 'components/GeneralDialogueActions';
import { getAllMonthNames, getCurrentMonth, handleExcelDownload, truncateName } from 'utils/utils';
import { DownloadOutlined } from '@mui/icons-material';
import MonthlyReportCustomDataGrid from './MonthlyReportCustomDataGrid';

interface CustomerVolumeAndProfitMonthlyReportTableProps extends BaseDialogueProps {
  data: CustomerVolumeProfitByMonth[];
  profitType: string;
  selectedYear: number;
  selectedMonth: number | null;
}

const CustomerVolumeAndProfitMonthlyReportTable: React.FC<CustomerVolumeAndProfitMonthlyReportTableProps> = ({
  data,
  profitType,
  handleClose,
  open,
  selectedYear,
  selectedMonth,
}) => {
  const currentMonth = getCurrentMonth(selectedYear, selectedMonth);
  const allMonths = getAllMonthNames(currentMonth);
  const excelFileName = selectedMonth
    ? `Customer-Volume-And-${profitType}-Profit-Monthly-Report_${allMonths[selectedMonth - 1]}-${selectedYear.toString()}`
    : `Customer-Volume-And-${profitType}-Profit-Monthly-Report_${selectedYear.toString()}`;
  const [transformedData, setTransformedData] = useState<any[]>([]);

  // Generate column definitions
  const columns: GridColDef[] = [
    {
      field: 'customerName',
      headerName: 'Customer Name',
      flex: 2,
      type: 'string',
      renderCell: params => (
        <Tooltip title={params.value} placement='top'>
          <span>{truncateName(params.value as string)}</span>
        </Tooltip>
      ),
    },
    ...allMonths.flatMap(month => [
      {
        field: `${month}_volume`,
        headerName: `${month} (Volume)`,
        flex: 1,
        type: 'number',
        align: 'center' as GridAlignment,
        headerAlign: 'center' as GridAlignment,
      },
      {
        field: `${month}_profit`,
        headerName: `${month} (Profit)`,
        flex: 1,
        type: 'number',
        align: 'center' as GridAlignment,
        headerAlign: 'center' as GridAlignment,
        valueFormatter: (params: GridValueFormatterParams) => `£${params.value}`,
      },
    ]),
    {
      field: 'totalVolume',
      headerName: 'Total Volume',
      flex: 1,
      type: 'number',
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'totalProfit',
      headerName: 'Total Profit',
      flex: 1,
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      valueFormatter: (params: GridValueFormatterParams) => `£${params.value}`,
    },
  ];

  useEffect(() => {
    // Transform data for grid and Excel export
    const gridData = data.map((item, index) => {
      const monthlyData: { [key: string]: number } = {};

      allMonths.forEach(month => {
        const monthData = item.monthlyVolumesProfits.find(m => m.month === month);
        monthlyData[`${month}_volume`] = monthData?.volume || 0;
        monthlyData[`${month}_profit`] = monthData?.profit || 0;
      });

      return {
        id: index,
        customerName: item.customerName,
        ...monthlyData,
        totalVolume: item.totalVolume,
        totalProfit: item.totalProfit,
      };
    });

    // Calculate totals for YTD row
    const ytdRow = {
      id: 'ytd',
      customerName: 'YTD',
      ...allMonths.reduce(
        (acc, month) => ({
          ...acc,
          [`${month}_volume`]: data.reduce((sum, customer) => {
            const monthData = customer.monthlyVolumesProfits.find(m => m.month === month);
            return sum + (monthData?.volume || 0);
          }, 0),
          [`${month}_profit`]: data.reduce((sum, customer) => {
            const monthData = customer.monthlyVolumesProfits.find(m => m.month === month);
            return sum + (monthData?.profit || 0);
          }, 0),
        }),
        {},
      ),
      totalVolume: data.reduce((sum, customer) => sum + customer.totalVolume, 0),
      totalProfit: data.reduce((sum, customer) => sum + customer.totalProfit, 0),
      isYTD: true,
    };

    setTransformedData([...gridData, ytdRow]);
  }, [data]);

  // Transform grid data to Excel format
  const getExcelData = () => {
    return transformedData.map(row => {
      const excelRow: { [key: string]: string | number } = {
        'Customer Name': row.customerName,
      };

      allMonths.forEach(month => {
        excelRow[`${month} (Volume)`] = row[`${month}_volume`];
        excelRow[`${month} (Profit)`] = `£${row[`${month}_profit`]}`;
      });

      excelRow['Total Volume'] = row.totalVolume;
      excelRow['Total Profit'] = `£${row.totalProfit}`;

      return excelRow;
    });
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth fullScreen maxWidth='xl'>
      <DialogTitle>
        <Box display='flex' alignItems='center' gap={1}>
          {profitType} Monthly Gross Profit Report
          <Tooltip title='Download as Excel' placement='top'>
            <IconButton onClick={() => handleExcelDownload(getExcelData(), excelFileName)}>
              <DownloadOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent>
        <MonthlyReportCustomDataGrid rows={transformedData} columns={columns} />
        <GeneralDialogueActions noSubmission handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
};

export default CustomerVolumeAndProfitMonthlyReportTable;
