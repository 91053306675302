import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
  TextField,
  MenuItem,
  ClickAwayListener,
  IconButton,
  Divider,
  Popper,
  useMediaQuery,
  AlertColor,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  Star,
  Folder,
  People,
  Menu as MenuIcon,
  BookOutlined,
  CalendarMonthOutlined,
  Groups2Outlined,
  ContactsOutlined,
  LocalShippingOutlined,
  WarehouseOutlined,
  WaterOutlined,
  ConnectWithoutContact,
  AnalyticsOutlined,
  RequestQuoteOutlined,
  SearchOutlined,
  AccessTimeOutlined,
  ShieldOutlined,
  AccountBalanceOutlined,
  HandshakeOutlined,
} from '@mui/icons-material';
import { useNavigate, generatePath } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
// @ts-expect-error: TODO: Fix typing issue
import logo from '../../assets/reudan_cropped.png';
import {
  setBookingsFilters,
  setBookingsColumnVisibilityModel,
  setPermissions,
  setUserEmail,
  setEnquiriesColumnVisibilityModel,
  setEnquiriesFilters,
  setSnackbarSettings,
  setMode,
} from '../../state/';
import { sec } from 'auth/accessToken';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import AccountMenu from 'components/AccountMenu';
import { useUserPermissions } from 'utils/utils';
import AlertSnackbar from 'components/AlertSnackbar';

export interface DecodedJwtPayload extends JwtPayload {
  permissions: string[];
}

const navItems = [
  {
    section: 'Management',
    sectionIcon: <Folder />,
    items: [
      { text: 'Enquiries', icon: <ConnectWithoutContact /> },
      { text: 'Bookings', icon: <BookOutlined /> },
    ],
  },
  {
    section: 'Analytics',
    sectionIcon: <AnalyticsOutlined />,
    permission: 'read:analytics',
  },
  {
    section: 'Tracking',
    sectionIcon: <AccessTimeOutlined />,
    items: [
      { text: 'Deadlines', icon: <CalendarMonthOutlined /> },
      { text: 'Haulage', icon: <LocalShippingOutlined /> },
    ],
  },
  {
    section: 'Accounting',
    sectionIcon: <RequestQuoteOutlined />,
  },
  {
    section: 'Resources',
    sectionIcon: <People />,
    items: [
      { text: 'Customers', icon: <Groups2Outlined /> },
      { text: 'Contacts', icon: <ContactsOutlined /> },
      { text: 'Vendors', icon: <HandshakeOutlined /> },
      { text: 'Sites', icon: <WarehouseOutlined /> },
      { text: 'Ports', icon: <WaterOutlined /> },
      { text: 'Accounts', icon: <AccountBalanceOutlined /> },
    ],
  },
  {
    section: 'Oauth',
    sectionIcon: <ShieldOutlined />,
    permission: 'read:access_token',
  },
];

export interface SidebarProps {
  drawerWidth: string;
}

const Sidebar: React.FC<SidebarProps> = ({ drawerWidth }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const userPermissions = useUserPermissions();
  const mode = theme.palette.mode;
  const isSm = useMediaQuery(theme.breakpoints.down(1500));
  const isLg = useMediaQuery(theme.breakpoints.up(1944));
  const [snackbarSettings, setSnackbarSettings] = useState<{
    snackbarOpen: boolean;
    snackbarSeverity: AlertColor;
    message: string;
  }>({
    snackbarOpen: false,
    snackbarSeverity: 'error',
    message: '',
  });
  const [active, setActive] = useState('');
  const [collapsedSections, setCollapsedSections] = useState<string[]>(navItems.map(item => item.section));
  const [search, setSearch] = useState('');
  const [bookings, setBookings] = useState([]);
  const [popperOpen, setPopperOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleCollapseToggle = (section: string) => {
    setCollapsedSections(prev => (prev.includes(section) ? prev.filter(sec => sec !== section) : [...prev, section]));
  };

  const handleNavigation = (path: string) => {
    setActive(path.toLowerCase());
    navigate(`/${path.toLowerCase()}`);
  };

  const handleSearchButtonClick = async () => {
    try {
      const accessToken = await sec.getAccessTokenSilently()();
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/bookings/search/${search}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response?.data?.length === 1) {
        navigate(generatePath('/bookings/:id', { id: response.data?.[0]?._id }));
        window.location.reload();
        setSearch('');
      } else if (response?.data?.length > 1) {
        setBookings(response.data);
        setPopperOpen(true);
      } else {
        setSnackbarSettings({ message: 'No Match Found', snackbarSeverity: 'error', snackbarOpen: true });
      }
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSearchSelect = (booking: any) => {
    navigate(generatePath('/bookings/:id', { id: booking._id }));
    window.location.reload();
    setSearch('');
    setPopperOpen(false);
  };

  const handleClickAway = () => {
    setPopperOpen(false);
    setSearch('');
  };

  useEffect(() => {
    const getUserPreferences = async (userEmail: string) => {
      try {
        const accessToken = await sec.getAccessTokenSilently()();
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/userPreferences/${userEmail}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        dispatch(setBookingsColumnVisibilityModel(response.data.bookingsColumnVisibilityModel));
        dispatch(setEnquiriesColumnVisibilityModel(response.data.enquiriesColumnVisibilityModel));
        dispatch(setBookingsFilters(response.data.bookingsFilters));
        dispatch(setEnquiriesFilters(response.data.enquiriesFilters));
        dispatch(setMode(response.data.mode));
        document.documentElement.setAttribute('data-theme', response.data.mode);
      } catch (error) {
        console.error('Error fetching preferences:', error);
      }
    };

    if (isAuthenticated) {
      const fetchToken = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          const decodedToken = jwtDecode<DecodedJwtPayload>(accessToken);
          dispatch(setPermissions(decodedToken.permissions));
          dispatch(setUserEmail(user?.email || ''));
          getUserPreferences(user?.email || '');
        } catch (error) {
          console.error('Error fetching token:', error);
        }
      };
      fetchToken();
    }
  }, [dispatch, isAuthenticated, getAccessTokenSilently, user]);

  const handleSnackbarClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarSettings({ snackbarOpen: false, snackbarSeverity: 'error', message: '' });
  };

  return (
    <Box component='nav'>
      <Drawer
        variant='permanent'
        anchor='left'
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            backgroundColor: mode === 'dark' ? '#1f1f21' : '#d5d5d5',
            color: mode === 'dark' ? '#F6F6F6' : 'black',
            display: 'flex',
            flexDirection: 'column',
            border: 'none',
          },
        }}
      >
        {/* Logo Section */}
        <Box
          p='1.5rem'
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{ cursor: 'pointer' }}
          onClick={() => handleNavigation('homepage')}
        >
          <img src={logo} alt='Logo' width='70%' />
        </Box>
        {isAuthenticated && (
          <Box p='0.5rem 1rem' sx={{ width: '100%' }}>
            <TextField
              placeholder='Search'
              autoComplete='off'
              value={search}
              onChange={handleSearchChange}
              variant='outlined'
              inputRef={inputRef}
              sx={{
                width: isSm ? '10rem' : isLg ? '14rem' : '12rem',
                backgroundColor: mode === 'dark' ? '#323741ff' : theme.palette.grey[400],
                borderRadius: '10px',
                padding: '0.2rem',
                '& .MuiOutlinedInput-root': {
                  height: '2.5rem',
                  padding: '0',
                  fontSize: '0.9rem',
                  '& fieldset': {
                    border: 'none',
                  },
                },
                '& .MuiInputAdornment-root': {
                  marginRight: '0.5rem',
                },
              }}
              InputProps={{
                // startAdornment: (
                //   <Box
                //     sx={{
                //       backgroundColor: '#E5E7EB',
                //       padding: '0.2rem 0.5rem',
                //       borderRadius: '5px',
                //       fontSize: '0.8rem',
                //       color: '#6B7280',
                //       fontWeight: 'bold',
                //     }}
                //   >
                //     /
                //   </Box>
                // ),
                endAdornment: (
                  <IconButton disableRipple onClick={handleSearchButtonClick} sx={{ pl: 0 }}>
                    <SearchOutlined sx={{ color: theme.palette.mode === 'dark' ? '#9CA3AF' : '#6b6b6b' }} />
                  </IconButton>
                ),
              }}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter' && search !== '') {
                  handleSearchButtonClick();
                }
              }}
            />
            <Popper
              open={popperOpen}
              anchorEl={inputRef.current}
              placement='bottom'
              sx={{
                zIndex: theme.zIndex.drawer + 1,
                width: '16rem',
                left: '16px !important',
              }}
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box>
                  {bookings.length === 0 ? (
                    <MenuItem
                      disabled
                      sx={{ bgcolor: theme.palette.background.alt, fontSize: '0.8rem', width: '14rem' }}
                    >
                      No options
                    </MenuItem>
                  ) : (
                    bookings.map((booking: any) => (
                      <MenuItem
                        key={booking._id}
                        onClick={() => handleSearchSelect(booking)}
                        sx={{
                          '&:hover': {
                            backgroundColor: theme.palette.mode === 'dark' ? '#313132' : theme.palette.grey[400],
                            color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                          },
                          bgcolor: theme.palette.background.alt,
                          fontSize: '0.8rem',
                          width: '14rem',
                        }}
                      >
                        {booking.bookingNumber}
                      </MenuItem>
                    ))
                  )}
                </Box>
              </ClickAwayListener>
            </Popper>
          </Box>
        )}

        {/* Navigation Section */}
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {isAuthenticated && !popperOpen && (
            <List>
              {navItems.map(section =>
                section.permission && !userPermissions?.includes(section.permission) ? null : (
                  <Box key={section.section}>
                    {/* Section Header */}
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          // If the section has items, handle collapsing
                          if (section.items) {
                            handleCollapseToggle(section.section);
                          } else {
                            // If no items, handle navigation directly
                            handleNavigation(section.section);
                          }
                        }}
                        sx={{
                          pl: 3,
                          '&:hover': {
                            backgroundColor:
                              theme.palette.mode === 'dark'
                                ? theme.palette.secondary[600]
                                : theme.palette.secondary[400],
                            color: 'white',
                            '& .MuiListItemIcon-root, & .MuiListItemText-root': {
                              color: 'white',
                            },
                            '& .MuiSvgIcon-root': {
                              color: 'white',
                            },
                          },
                          py: 0.3,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: '36px',
                          }}
                        >
                          {section.sectionIcon}
                        </ListItemIcon>
                        <ListItemText primary={<Typography>{section.section}</Typography>} />
                        {section.items ? (
                          collapsedSections.includes(section.section) ? (
                            <ExpandMore />
                          ) : (
                            <ExpandLess />
                          )
                        ) : null}
                      </ListItemButton>
                    </ListItem>

                    {/* Collapsible Items for sections with items */}
                    {section.items && (
                      <Collapse in={!collapsedSections.includes(section.section)} timeout='auto' unmountOnExit>
                        <List component='div' disablePadding>
                          {section.items.map(item => (
                            <Tooltip key={item.text} title={item.text} placement='right' arrow>
                              <ListItem disablePadding>
                                <ListItemButton
                                  onClick={() => handleNavigation(item.text)}
                                  sx={{
                                    pl: 5,
                                    '&:hover': {
                                      backgroundColor:
                                        theme.palette.mode === 'dark'
                                          ? theme.palette.secondary[600]
                                          : theme.palette.secondary[400],
                                      color: 'white',
                                      '& .MuiListItemIcon-root, & .MuiListItemText-root': {
                                        color: 'white',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        color: 'white',
                                      },
                                    },
                                    py: 0.3,
                                  }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      minWidth: '40px',
                                    }}
                                  >
                                    {item.icon}
                                  </ListItemIcon>
                                  <ListItemText primary={item.text} />
                                </ListItemButton>
                              </ListItem>
                            </Tooltip>
                          ))}
                        </List>
                      </Collapse>
                    )}
                  </Box>
                ),
              )}
            </List>
          )}
        </Box>

        {/* Account Menu */}
        <Divider />
        <Box sx={{ mb: '1rem' }} width={'100%'}>
          <AccountMenu user={user} />
        </Box>
        {/* <Box p='1rem'>
          <Typography
            variant='body2'
            sx={{
              color: theme.palette.secondary[200],
              textAlign: 'center',
              cursor: 'pointer',
              '&:hover': {
                color: theme.palette.primary[100],
              },
            }}
            onClick={() => navigate('/privacy-policy')}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant='body2'
            sx={{
              color: theme.palette.secondary[200],
              textAlign: 'center',
              cursor: 'pointer',
              mt: '0.5rem',
              '&:hover': {
                color: theme.palette.primary[100],
              },
            }}
            onClick={() => navigate('/terms-and-conditions')}
          >
            Terms & Conditions
          </Typography>
        </Box> */}
      </Drawer>
      <AlertSnackbar
        open={snackbarSettings.snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={snackbarSettings.snackbarSeverity}
        message={snackbarSettings.message}
      />
    </Box>
  );
};

export default Sidebar;
